/* You can add global styles to this file, and also import other style files */

@import "~@ng-select/ng-select/themes/default.theme.css";

html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-family: "Poppins";
}

::ng-deep .ng-dropdown-panel.ng-select-bottom {
  text-align: left !important;
}
.custom-lh {
  line-height: 1.3;
}

/*-----------------------------------------------------------
    Theme Name: listagram
    Theme URI: http://demo.lion-coders.com/listagram/
    Description: Directory Listing HTML Template
    Author: LionCoders
    Author URI: https://themeforest.net/user/lioncoders
    Version: 1.0
-------------------------------------------------------------*/

/*-----------------------------------------------------------
    1  General Styles
    2  Typography
    3  Buttons & Links
    4  Fixed Header
    5  Menu
    6  Hero Area
    7  Breadcrumb
    8  Catagory Box
    9  Work Proces
    10 Popular category
    11 Discount Coupon
    12 Trending places
    13 Testimonial Section
    14 Call to Action
    15 Partner section
    16 Scroll-to-top
    17 FAQ Page
    18 Invoice
    18 App Section
    19 Footer section
    20 Pricing Table
    21 404 PAGE
    22 Blog
    23 Filter Listing page
    24 Single Listing Page
    25 User Login Page
    25 Add Listing Page
    25 Booking information
    25 Booking Success
    26 User Profile
    27 About Page
    28 Contact page
    29 Color Switcher
    30 Preloader
    31 Map marker
    32 Responsive
-------------------------------------------------------------*/

/*-----------------------------------------------------------
            General Styles
-------------------------------------------------------------*/

dl,
dt,
dd,
ol,
ul,
li {
  margin: 0;
  padding: 0;
}

a {
  color: #141414;
  text-decoration: none;
  position: relative;
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  font-weight: 400;
}

a:focus,
a:hover {
  color: #777;
  text-decoration: none;
}

img {
  max-width: 100%;
  height: auto;
}
input:focus {
  outline: none !important;
  box-shadow: none !important;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: #141414;
  font-family: "Poppins", sans-serif;
  margin-top: 0;
  margin-bottom: 15px;
  letter-spacing: 0;
  line-height: 1.6;
}

.form-control {
  border-radius: 0;
}

.form-control:focus {
  border-color: #777;
  outline: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.btn {
  white-space: normal !important;
}
.header_btn {
  border: 2px solid #f76900;
  background: #f76900;
  border-radius: 15px;
  padding: 8px 22px;
  color: white !important;
}

.login_btn {
  border: 2px solid #ffe9d8;
  border-radius: 15px;
  padding: 8px 22px;
  color: #f76900 !important;
  background: #f7690012;
}
.call_btn {
  border: 2px solid #ffe9d8;
  border-radius: 50%;
  padding: 8px 22px;
  color: #f76900 !important;
  background: #f7690012;
  font-size: 18px;
}
.no-pad-left {
  padding-left: 0 !important;
}

.no-pad-right {
  padding-right: 0 !important;
}

.no-pad-lr {
  padding-right: 0 !important;
  padding-left: 0 !important;
}

.no-pad-top {
  padding-top: 0 !important;
}

.no-pad-bot {
  padding-bottom: 0 !important;
}

.pad-bot-15 {
  padding-bottom: 15px !important;
}

.pad-bot-60 {
  padding-bottom: 60px !important;
}

.pad-bot-110 {
  padding-bottom: 110px !important;
}

.pad-bot-120 {
  padding-bottom: 10px !important;
}

.pad-bot-130 {
  padding-bottom: 130px !important;
}

.no-pad-tb {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.pad-0 {
  padding: 0 !important;
}

.pad-top-10 {
  padding-top: 10px !important;
}

.pad-top-20 {
  padding-top: 20px !important;
}

.pad-top-30 {
  padding-top: 30px !important;
}

.pad-top-40 {
  padding-top: 40px !important;
}

.pad-top-50 {
  padding-top: 50px !important;
}

.pad-top-60 {
  padding-top: 60px !important;
}

.pad-top-70 {
  padding-top: 70px !important;
}

.pad-top-80 {
  padding-top: 80px !important;
}

.pad-top-90 {
  padding-top: 90px !important;
}

.pad-top-100 {
  padding-top: 100px !important;
}

.pad-top-110 {
  padding-top: 110px !important;
}

.pad-top-120 {
  padding-top: 120px !important;
}

.pad-top-130 {
  padding-top: 130px !important;
}

.pad-top-140 {
  padding-top: 140px !important;
}

.pad-top-150 {
  padding-top: 150px !important;
}

.pad-bot-10 {
  padding-bottom: 10px !important;
}

.pad-bot-20 {
  padding-bottom: 20px !important;
}

.pad-bot-30 {
  padding-bottom: 30px !important;
}

.pad-bot-40 {
  padding-bottom: 40px !important;
}

.pad-bot-50 {
  padding-bottom: 50px !important;
}

.pad-bot-60 {
  padding-bottom: 60px !important;
}

.pad-bot-70 {
  padding-bottom: 70px !important;
}

.pad-bot-80 {
  padding-bottom: 80px !important;
}

.pad-bot-90 {
  padding-bottom: 90px !important;
}

.pad-bot-100 {
  padding-bottom: 100px !important;
}

.pad-tb-10 {
  padding-top: 10px !important;
  padding-bottom: 10px !important;
}

.pad-tb-20 {
  padding-top: 20px !important;
  padding-bottom: 20px !important;
}

.pad-tb-30 {
  padding-top: 30px !important;
  padding-bottom: 30px !important;
}

.pad-tb-40 {
  padding-top: 40px !important;
  padding-bottom: 40px !important;
}

.pad-tb-50 {
  padding-top: 50px !important;
  padding-bottom: 50px !important;
}

.pad-tb-60 {
  padding-top: 60px !important;
  padding-bottom: 60px !important;
}

.pad-tb-70 {
  padding-top: 70px !important;
  padding-bottom: 70px !important;
}

.pad-tb-80 {
  padding-top: 80px !important;
  padding-bottom: 80px !important;
}

.pad-tb-90 {
  padding-top: 90px !important;
  padding-bottom: 90px !important;
}

.pad-tb-100 {
  padding-top: 100px !important;
  padding-bottom: 100px !important;
}

.pad-tb-110 {
  padding-top: 110px !important;
  padding-bottom: 110px !important;
}

.pad-tb-120 {
  padding-top: 120px !important;
  padding-bottom: 120px !important;
}

.pad-left-15 {
  padding-left: 15px !important;
}

.pad-right-15 {
  padding-right: 15px !important;
}

.pad-right-45 {
  padding-right: 45px !important;
}

.mar-0 {
  margin: 0 !important;
}

.mar-left-0 {
  margin-left: 0 !important;
}

.pad-lr-15 {
  padding-left: 15px !important;
  padding-right: 15px !important;
}

.pad-lr-30 {
  padding-left: 30px !important;
  padding-right: 30px !important;
}

.mar-right-0 {
  margin-right: 0 !important;
}

.mar-right-5 {
  margin-right: 5px !important;
}

.mar-right-15 {
  margin-right: 15px !important;
}

.mar-top-0 {
  margin-top: 0 !important;
}

.mar-top-5 {
  margin-top: 5px !important;
}

.mar-top-10 {
  margin-top: 10px !important;
}

.mar-top-15 {
  margin-top: 15px !important;
}

.mar-top-20 {
  margin-top: 20px !important;
}

.mar-top-25 {
  margin-top: 25px !important;
}

.mar-top-30 {
  margin-top: 30px !important;
}

.mar-top-35 {
  margin-top: 35px !important;
}

.mar-top-40 {
  margin-top: 40px !important;
}

.mar-top-45 {
  margin-top: 45px !important;
}

.mar-top-50 {
  margin-top: 50px !important;
}

.mar-top-55 {
  margin-top: 55px !important;
}

.mar-top-60 {
  margin-top: 60px !important;
}

.mar-top-65 {
  margin-top: 65px !important;
}

.mar-top-70 {
  margin-top: 70px !important;
}

.mar-top-75 {
  margin-top: 75px !important;
}

.mar-top-80 {
  margin-top: 80px !important;
}

.mar-top-85 {
  margin-top: 85px !important;
}

.mar-top-90 {
  margin-top: 95px !important;
}

.mar-top-95 {
  margin-top: 95px !important;
}

.mar-top-100 {
  margin-top: 100px !important;
}

.mar-top-110 {
  margin-top: 110px !important;
}

.mar-top-120 {
  margin-top: 120px !important;
}

.mar-top-125 {
  margin-top: 125px !important;
}

.mar-top-130 {
  margin-top: 130px !important;
}

.mar-top-150 {
  margin-top: 150px !important;
}

.mar-bot-0 {
  margin-bottom: 0 !important;
}

.mar-bot-5 {
  margin-bottom: 5px !important;
}

.mar-bot-10 {
  margin-bottom: 10px !important;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.8;
  margin-bottom: 0;
  color: #777;
  word-wrap: break-word;
}

.mar-bot-15 {
  margin-bottom: 15px !important;
}

.mar-bot-20 {
  margin-bottom: 20px !important;
}

.mar-bot-25 {
  margin-bottom: 25px !important;
}

.mar-bot-30 {
  margin-bottom: 30px !important;
}

.mar-bot-35 {
  margin-bottom: 35px !important;
}

.mar-bot-40 {
  margin-bottom: 40px !important;
}

.mar-bot-45 {
  margin-bottom: 45px !important;
}

.mar-bot-50 {
  margin-bottom: 50px !important;
}

.mar-bot-55 {
  margin-bottom: 55px !important;
}

.mar-bot-60 {
  margin-bottom: 60px !important;
}

.mar-bot-65 {
  margin-bottom: 65px !important;
}

.mar-bot-70 {
  margin-bottom: 70px !important;
}

.mar-bot-75 {
  margin-bottom: 75px !important;
}

.mar-bot-80 {
  margin-bottom: 80px !important;
}

.mar-bot-85 {
  margin-bottom: 85px !important;
}

.mar-bot-90 {
  margin-bottom: 90px !important;
}

.mar-bot-95 {
  margin-bottom: 95px !important;
}

.mar-bot-100 {
  margin-bottom: 100px !important;
}

.mar-bot-145 {
  margin-bottom: 145px !important;
}

.mar-tb-10 {
  margin-top: 10px !important;
  margin-bottom: 10px !important;
}

.mar-tb-15 {
  margin-top: 15px !important;
  margin-bottom: 15px !important;
}

.mar-tb-20 {
  margin-top: 20px !important;
  margin-bottom: 20px !important;
}

.mar-tb-30 {
  margin-top: 30px !important;
  margin-bottom: 30px !important;
}

.mar-tb-40 {
  margin-top: 40px !important;
  margin-bottom: 40px !important;
}

.mar-tb-50 {
  margin-top: 50px !important;
  margin-bottom: 50px !important;
}

.mar-tb-60 {
  margin-top: 60px !important;
  margin-bottom: 60px !important;
}

.mar-tb-70 {
  margin-top: 70px !important;
  margin-bottom: 70px !important;
}

.mar-tb-80 {
  margin-top: 80px !important;
  margin-bottom: 80px !important;
}

.uppercase {
  text-transform: uppercase;
}

.capitalize {
  text-transform: capitalize;
}

.d-inline {
  display: inline !important;
}

/* .d-block {
  display: block !important;
  width: 100%;
} */

.d-tc {
  display: table-cell !important;
  vertical-align: middle;
}

.white-text {
  color: #fff !important;
}

.dark-text {
  color: #141414;
}

.gray-bg {
  background-color: #fafafa;
}

.dark-bg {
  background-color: #202020;
}

.text-grey {
  color: #999;
}

.no-border {
  border: none !important;
}

.section-padding {
  padding: 40px 0;
}

section.coupon {
  padding-top: 30px;
}

.mat-checkbox-layout {
  white-space: normal !important;
}

/* bottom mobile bar */
.mobile_btm_bar {
  background: #f76900;
  position: fixed;
  bottom: 0;
  width: 100%;
  z-index: 999;
  display: none;
}
.mobile_btm_bar ul li {
  text-align: center;
}
.mobile_btm_bar ul li a {
  color: #fff;
  text-align: center;
  font-size: 11px;
  font-weight: 500;
  line-height: normal;
}

.svgIconBottom {
  height: 20px;
  width: 20px;
  margin-inline: auto;
  display: flex;
}
/* 
.amountClass{
  width: 50%;
} */

/* .container-fluid {
  padding: 0 30px;
} */

/* -------------Overlay----------- */

.overlay {
  background-color: rgb(0, 0, 0);
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  height: 100%;
  width: 100%;
}

.overlay.green {
  background-color: #0cbcb7;
}

.op-1 {
  opacity: 0.1;
}

.op-2 {
  opacity: 0.2;
}

.op-3 {
  opacity: 0.3;
}

.op-4 {
  opacity: 0.4;
}

.op-5 {
  opacity: 0.5;
}

.op-6 {
  opacity: 0.6;
}

.op-7 {
  opacity: 0.7;
}

.op-8 {
  opacity: 0.8;
}

.op-9 {
  opacity: 0.9;
}

/*-----------------------------------------------------------
                    Typography
-------------------------------------------------------------*/

body {
  color: #777;
  font-family: "Poppins", sans-serif;
  font-size: 14px;
  font-weight: 300;
  overflow-x: hidden !important;
}

h1 {
  font-size: 36px;
}

h2 {
  font-size: 26px;
}

h3 {
  font-size: 24px;
}

h4 {
  font-size: 20px;
}

h5 {
  font-size: 18px;
}

h6 {
  font-size: 16px;
}

.sm-heading,
.xsm-heading {
  color: #141414;
  display: block;
  font-size: 14px;
  font-weight: 700;
  letter-spacing: 0.075em;
  text-transform: uppercase;
}

.xsm-heading {
  font-size: 12px;
}

.light {
  font-weight: 300;
}

.regular {
  font-weight: 400;
}

.semibold {
  font-weight: 600;
}

.bold {
  font-weight: 700;
}

.black {
  font-weight: 900;
}

p {
  font-size: 16px;
  font-weight: 400;
  line-height: 1.5;
  margin-bottom: 0;
}

li {
  font-size: 13px;
  font-weight: 400;
  line-height: 30px;
  list-style: none !important;
}

ul.list-inline li {
  display: inline-block;
}

.main_bg_color {
  background: #f76900;
}
.main_color {
  color: #f76900;
}

.btn_hover {
  position: relative;
  border: 2px solid #fff;
  padding: 8px 18px;
  color: #fff;
  border-radius: 4px;
  overflow: hidden;
  transition: all 500ms;
  background: #f76900;
  font-weight: 600;
  z-index: 9;
}
.btn_hover::before {
  position: absolute;
  content: "";
  right: 0;
  top: 0;
  width: 0;
  height: 100%;
  background: #ffffff;
  transition: all 500ms;
  z-index: -1;
}
.btn_hover:hover {
  border-color: #f76900;
  color: #f76900;
}
.btn_hover:hover:before {
  width: 100%;
}
.section-title {
  font-size: 2rem;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  position: relative;
  display: inline-block;
}

/* .section-title.v1::before {
  position: absolute;
  top: 60%;
  left: 50%;
  content: url("assets/images/bg/green.png");
  transform: translateX(-50%);
} */

.section-title.v1 {
  color: #333;
  line-height: 1.2;
  position: relative;
  font-weight: 500;
}

.section-title.v2 {
  color: #fff;
}

/*-----------------------------------------------------------
                Buttons & Links
-------------------------------------------------------------*/

.link-hov.style1 {
  color: #fff;
  transition: 0.5s;
  position: relative;
  font-size: 14px;
  font-weight: 400;
}

.link-hov.style1:before {
  position: absolute;
  top: 105%;
  left: 0;
  width: 0%;
  height: 1px;
  content: "";
  background: #fff;
  visibility: hidden;
  opacity: 0;
  transition: 0.4s;
}

.link-hov.style1:hover {
  color: #fff;
}

.link-hov.style1:hover:before {
  visibility: visible;
  width: 100%;
  opacity: 1;
}

.link-hov.style1.text-white:before {
  background: #fff;
}

.link-hov.style1.text-white:hover:before {
  visibility: visible;
  width: 100%;
  opacity: 1;
}

.link-hov.style2 {
  color: #555;
  transition: 0.5s;
  position: relative;
  font-size: 14px;
  font-weight: 400;
}

.link-hov.style2:before {
  position: absolute;
  top: 100%;
  left: 0;
  width: 0%;
  height: 1px;
  content: "";
  background: #555;
  visibility: hidden;
  opacity: 0;
  width: 0;
  transition: 0.4s;
}

.link-hov.style2:hover {
  color: #000;
}

.link-hov.style2:hover:before {
  visibility: visible;
  opacity: 1;
  width: 100%;
}

.link-hov.style3 {
  color: #777;
  transition: 0.5s;
  position: relative;
  font-size: 15px;
}

.link-hov.style3:before {
  position: absolute;
  top: 100%;
  left: 0;
  width: 0%;
  height: 2px;
  content: "";
  background: #fff;
  visibility: hidden;
  opacity: 0;
  transition: 0.4s;
}

.link-hov.style3:hover {
  color: #141414;
}

.link-hov.style3:hover:before {
  visibility: visible;
  width: 100%;
  opacity: 1;
}

.round-bg {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  display: inline-block;
  text-align: center;
}

.round-bg i {
  font-size: 16px;
}

.round-bg.green {
  background-color: #0bf389;
  color: #fff;
}

.round-bg.pink {
  background-color: #ffae00;
  color: #fff;
}

.round-bg.red {
  background-color: #ff6d6d;
  color: #fff;
}

.round-bg.transparent {
  border: 1px solid #ddd;
}

.slider-btn.v1 {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  text-align: center;
  border: 2px solid rgba(168, 163, 163, 0.71);
  cursor: pointer;
  transition: 0.4s;
}

.pointer {
  cursor: pointer;
}
.slider-btn.v1 i {
  line-height: 33px;
  font-size: 16px;
  color: rgba(168, 163, 163, 0.9);
  transition: 0.4s;
}

.slider-btn.v1:hover {
  /*    background: #0cbcb7;*/
  color: #fff;
  border-color: transparent;
}

.slider-btn.v1:hover i {
  color: #fff;
}

.slider-btn.v2 {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  text-align: center;
  border: 1px solid #ddd;
  cursor: pointer;
  transition: 0.4s;
  background: #fff;
}

.slider-btn.v2 i {
  line-height: 29px;
  font-size: 12px;
}

.slider-btn.v2:hover {
  background: #0cbcb7;
  color: #fff !important;
  border-color: transparent;
}

.slider-btn.v2:hover i {
  color: #fff;
}

.slider-btn.v3 {
  width: 35px;
  height: 35px;
  border: 1px solid transparent;
  display: inline-block;
  border-radius: 50%;
  text-align: center;
  line-height: 35px;
  cursor: pointer;
  background: #0cbcb7;
}

.slider-btn.v3 i {
  font-size: 20px;
  color: #fff;
  font-weight: 600;
}

.slider-btn.v4 {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  text-align: center;
  border: 1px solid transparent;
  cursor: pointer;
  transition: 0.4s;
  background: #0cbcb7;
}

.slider-btn.v4 i {
  line-height: 35px;
  font-size: 18px;
  color: #fff;
}

.btn.v1 {
  background: #0cbcb7;
  padding: 9px 30px;
  border-radius: 50px;
  border: 1px solid transparent;
  color: #fff !important;
  transition: 0.3s;
  line-height: 1.1;
  font-size: 15px;
}

.btn:focus {
  box-shadow: none !important;
}

.btn.v1 i {
  margin-left: 5px;
}

.btn.v1:hover {
  background: #fff;
  color: #333 !important;
  border: 1px solid #ddd;
}

.btn.v2 {
  background: #bdc1ca;
  padding: 9px 15px;
  border-radius: 50px;
  color: #fff;
  transition: 0.3s;
  border: 1px solid transparent;
  font-size: 14px;
  transition: 0.5s;
  line-height: 1;
}

.btn.v2 i {
  margin-right: 7px;
}

.btn.v2:hover {
  background: transparent;
  color: #bdc1ca !important;
  border: 1px solid #bdc1ca;
}

.btn.v3 {
  background: #0cbcb7;
  padding: 9px 20px;
  border-radius: 50px;
  border: 1px solid transparent;
  color: #fff !important;
  transition: 0.3s;
  display: inline-block;
  font-size: 15px;
  font-weight: 500;
}

.btn.v3 i {
  margin-right: 8px;
}

.btn.v3:hover {
  background: #fff;
  color: #333 !important;
  border: 1px solid #ddd;
}

.btn.v3.white {
  background: #fff !important;
  color: #0cbcb7 !important;
}

.btn.v3.white:hover {
  background: #0cbcb7 !important;
  color: #fff !important;
  border-color: transparent;
}

.btn.v4 {
  background: #0cbcb7;
  padding: 9px 30px;
  border-radius: 50px;
  border: 1px solid transparent;
  color: #fff !important;
  transition: 0.3s;
  line-height: 1.1;
  font-size: 15px;
}

.btn.v4:hover {
  background: #fff;
  color: #333 !important;
  border: 1px solid #ddd;
}

.btn.v5 {
  color: #fff;
  border: 1px solid transparent;
  padding: 9px 15px;
  border-radius: 50px;
  font-size: 14px;
  transition: 0.5s;
  background: #1cbbb4;
  line-height: 1;
}

.btn.v5 i {
  margin-right: 7px;
}

.btn.v5:hover {
  background: transparent;
  color: #999;
  border: 1px solid #ddd;
}

.btn.v6 {
  color: #fff;
  /* border: 1px solid rgba(255, 255, 255, 0.7); */
  padding: 7px 15px;
  border-radius: 50px;
  font-size: 11px;
  letter-spacing: 2px;
  transition: 0.5s;
  text-transform: uppercase;
  line-height: 1.2;
  background: #f76900;
}

/* Static Page Css Start */

.static-page-container {
  align-items: center;
  margin: auto;
}

.static-page-container h2 {
  text-align: center;
  font-weight: bold;
  font-size: 20px;
  margin-top: 22px;
}

.static-page-container h3 {
  text-align: center;
  font-weight: bold;
  font-size: 17px;
}

.static-page-container p {
  text-align: center;
  font-size: 16px;
  max-width: 90%;
  margin: auto;
}

.static-page-flex-container,
.static-page-flex-container-reverse {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
  max-width: 85%;
}

.static-page-flex-container p,
.static-page-flex-container-reverse p {
  width: 100%;
  text-align: left;
}

.static-page-flex-container img,
.static-page-flex-container-reverse img {
  width: 700px;
}

.static-header-image {
}

.static-page-sub-heading {
  font-weight: bold;
  color: black;
}

@media only screen and (max-width: 900px) {
  .static-page-container {
    font-size: 17px;
  }

  .static-page-container h2 {
    font-size: 20px;
  }

  /* .static-header-image {
    height: 400px;
  } */
  .static-page-container h3 {
    font-size: 17px;
  }

  .static-page-flex-container {
    flex-wrap: wrap;
    padding: 0px;
  }

  .static-page-flex-container-reverse {
    flex-wrap: wrap-reverse;
    padding: 0px;
  }

  .static-page-flex-container p,
  .static-page-flex-container-reverse p {
    width: 100%;
    padding: 0px;
  }

  .static-page-container p {
    padding: 0px;
    text-align: left;
    font-size: 16px;
  }
}

/* Static Page Css End */

.btn.v6:hover {
  background: rgb(90, 88, 88);
  color: #fff;
  border: 1px solid transparent;
}

.btn.v6.red {
  color: #fff !important;
  background: #0cbcb7;
  border: 1px solid transparent;
}

.btn.v6.red:hover {
  color: #6c6c6c !important;
  background: #fff;
}

.btn.v6.dark {
  color: #6c6c6c !important;
  border: 1px solid #ddd;
}

.btn.v6.dark:hover {
  background: #f9f9f9;
}

.btn.v7 {
  background: #0cbcb7;
  color: #fff;
  padding: 11px 20px;
  border: 1px solid transparent;
  transition: 0.5s;
  border-radius: 50px;
}

.btn.v7:hover {
  background: transparent;
  color: #333;
  border: 1px solid #ddd;
}

.btn.v8 {
  background: #0cbcb7;
  padding: 9px 20px;
  border-radius: 50px;
  border: 1px solid transparent;
  color: #fff !important;
  transition: 0.3s;
  display: inline-block;
  font-size: 15px;
  font-weight: 500 !important;
}

.btn.v8:hover {
  background: #fff;
  color: #333 !important;
}

.btn.v8 i {
  margin-left: 5px;
}

.btn.v9 {
  background: transparent;
  padding: 15px 25px;
  border-radius: 50px;
  border: 1px solid #fff;
  color: #fff !important;
  transition: 0.3s;
  line-height: 1.1;
  font-size: 15px;
}

.btn.v9:hover {
  background: #fff;
  color: #333 !important;
  border: 1px solid #ddd;
}

.btn.v10 {
  background: transparent;
  padding: 15px 25px;
  border-radius: 50px;
  border: 1px solid;
  color: #f76900 !important;
  transition: 0.3s;
  line-height: 1.1;
  font-size: 15px;
}

.static-page-links {
  color: blue;
}

.large-btn {
  background: #f76900;
  padding: 15px;
  color: white !important;
  width: 60%;
  font-weight: 500;
  min-width: 285px;
  border-radius: 3px;
}

/*----------Social Buttons-----------*/

.social-buttons {
  padding-left: 0;
}

.social-buttons li {
  display: inline-block;
  padding: 0 7px;
}

.social-buttons li:first-child {
  padding-left: 0;
}

.social-buttons li a i {
  color: #fff;
  transition: 0.5s;
}

.social-buttons li a:hover i {
  color: #ccc !important;
}

.bg-green {
  background-color: #0cbcb7;
}

.bg-green-1 {
  background-color: rgba(30, 195, 139, 0.1);
}

.bg-white {
  background-color: #fff !important;
}

.bg-grey {
  background-color: #ddd !important;
}

.bg-pn {
  background-color: #f5f6f7 !important;
}

.bg-f {
  background-size: cover;
  background-position: center;
  height: 100vh;
  background-repeat: no-repeat;
}

.bg-h {
  background-position: center;
  background-size: cover;
  height: 550px;
}

.bg-s {
  background-position: center;
  background-size: cover;
  height: 400px;
}

.bg-xs {
  background-position: center;
  background-size: cover;
  height: 300px;
}

.bg-fixed {
  background-attachment: fixed;
}

.bg-fb {
  background: #3b5998;
}

.bg-tt {
  background: #34aaf3;
}

.bg-ig {
  background: #6ee777;
}

/*-----------------------------------------------------------
                  Fixed header
-------------------------------------------------------------*/

.header-fixed {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 2000;
  transition: 0.7s;
}

.header {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 999;
  transition: 0.7s;
}

.header.hide {
  top: -100px;
  visibility: hidden;
  transition: 0.7s;
}

.header.transparent.scroll {
  padding: 0;
  transition: 0.7s;
  z-index: 9999;
}

.header.transparent.scroll .site-navbar-wrap.v1 {
  background-color: #0cbcb7;
  box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.3);
}

.header.transparent.scroll .site-navbar-wrap.v2 {
  background-color: #fff;
  box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.3);
}

.header.transparent.scroll .site-navbar-wrap.v1 .add-list .btn.v8 {
  background: #fff;
  color: #000 !important;
}

.header.transparent.scroll-hide .site-navbar-wrap.v2 .add-list .btn.v8:hover {
  border: 1px solid #ededed;
}

/*-----------------------------------------------------------
                    Menu
-------------------------------------------------------------*/

.site-navbar-wrap {
  z-index: 99;
  transition: 0.3s all ease;
}

.site-navbar-wrap .navbar-brand {
  padding: 0;
  max-width: 150px;
}

.site-navbar-wrap.v1 {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  padding: 10px 0;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
}

.site-navbar-wrap.scrolled {
  padding: 0px 0;
  box-shadow: 0 0 30px -5px rgba(0, 0, 0, 0.1);
}

.site-navbar-top {
  display: none;
  font-size: 0.8rem;
  background: #1e7e34;
}

.site-navbar-top a {
  color: #fff;
}

.site-navbar-top .text-with-icon {
  color: rgba(255, 255, 255, 0.5);
}

.site-navbar-top,
.site-navbar {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.site-navbar {
  margin-bottom: 0px;
  width: 100%;
  border-bottom: none;
}

.site-navbar .site-logo {
  font-weight: 200;
}

.site-navbar .site-logo a {
  font-weight: 200;
  font-size: 26px;
  color: #000;
}

.site-navbar .site-navigation .site-menu {
  margin-bottom: 0;
}

.site-navbar .site-navigation .site-menu .active > a {
  color: #000;
}

.user-menu li a {
  padding-left: 5px !important;
  padding-right: 0 !important;
}

.user-menu li a {
  background: #0cbcb7;
  color: #fff !important;
  border-radius: 20px;
  transition: 0.4s;
  padding: 10px 20px !important;
}

.user-menu li a:hover {
  background: #fff;
  color: #333 !important;
}

.site-navbar .site-navigation .site-menu > li {
  display: inline-block;
  padding: 10px 10px;
}

.site-navbar-wrap.v1 .site-navbar .site-navigation .site-menu > li > a {
  padding: 10px 10px;
  color: #fff;
  font-size: 15px;
  font-weight: 400;
  text-decoration: none !important;
}

.site-navbar-wrap.v2 {
  border-bottom: 1px solid rgba(153, 153, 153, 0.22);
  padding: 15px 0;
  background-color: #fff;
}

.site-navbar-wrap.v2 .custum-btn {
  padding: 13px 16px;
  border-radius: 50px;
  font-size: 11px !important;
}

.site-navbar-wrap.v2 .site-navbar .site-navigation .site-menu > li > a {
  color: #000;
}

.site-navbar .site-navigation .site-menu > li > a:hover {
  color: #000;
}

.site-navbar .site-navigation .site-menu > li:last-child {
  padding-right: 0;
}

.site-navbar .site-navigation .site-menu .has-children {
  position: relative;
}

.has-children a {
  font-size: 15px;
  font-weight: 300;
}

.has-no-children a {
  font-size: 15px;
  font-weight: 400;
}

.site-navbar .site-navigation .site-menu .has-children > a {
  position: relative;
  padding-right: 20px;
  font-size: 15px;
  font-weight: 400;
}

.site-navbar-wrap .site-navbar .site-navigation .site-menu .has-children > a::before {
  position: absolute;
  content: "\f3d0";
  color: #fff;
  font-family: "Ionicons";
  top: 6px;
  right: -4px;
  font-size: 13px;
}

.site-navbar-wrap.v2 .site-navbar .site-navigation .site-menu .has-children > a::before {
  color: #000;
  top: -3px;
  right: 6px;
}

.dropdown > .has-children > a::before {
  position: absolute !important;
  content: "\f3d3" !important;
  color: #000 !important;
  font-family: "Ionicons" !important;
  top: 14px !important;
  right: 3px !important;
}

.site-navbar .site-navigation .site-menu .has-children .dropdown {
  visibility: hidden;
  opacity: 0;
  top: 122%;
  position: absolute;
  text-align: left;
  padding: 15px 30px;
  margin-top: 20px;
  margin-left: 0px;
  background: #fff;
  transition: 0.4s 0s;
  z-index: 9999;
}

.site-navbar .site-navigation .site-menu .has-children .dropdown {
  box-shadow: 0 5px 5px 5px rgba(0, 0, 0, 0.05);
  position: absolute;
  min-width: 15.3rem;
  z-index: 9999;
  border-radius: 2px;
}

.site-navbar .site-navigation .site-menu .has-children .dropdown li {
  position: relative;
}

.site-navbar .site-navigation .site-menu .has-children .dropdown li:before {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 0;
  height: 1px;
  background: #f91942;
  content: "";
  transition: 0.6s;
}

.site-navbar .site-navigation .site-menu .has-children .dropdown li:hover:before {
  width: 100%;
}

.dropdown.sub-menu {
  position: absolute;
  min-width: 12.9rem !important;
  z-index: 9999;
}

/* .dropdown li a:hover {
  margin-left: 5px;
} */

.site-navbar .site-navigation .site-menu .has-children .dropdown:before {
  border-color: rgba(136, 183, 213, 0);
  border-bottom-color: #000;
  border-width: 7px;
  margin-left: -7px;
}

.site-navbar .site-navigation .site-menu .has-children .dropdown a {
  text-transform: none;
  letter-spacing: normal;
  transition: 0s all;
  color: #fff;
}

.site-navbar .site-navigation .site-menu .has-children .dropdown .active > a {
  color: #28a745 !important;
}

.site-navbar .site-navigation .site-menu .has-children .dropdown > li {
  border-bottom: 1px solid #f5f6f7;
  list-style: none;
  margin: 0;
}

.site-navbar .site-navigation .site-menu .has-children .dropdown li:last-child {
  border-bottom: none;
}

.site-navbar .site-navigation .site-menu .has-children .dropdown > li > a {
  display: block;
  color: #666;
  transition: 0.3s;
  line-height: 1.5;
  font-size: 14px;
  font-weight: 300;
  padding: 10px 0;
}

.site-navbar .site-navigation .site-menu .has-children .dropdown > li.has-children > a:before {
  content: "\e315";
  right: 20px;
}

.site-navbar .site-navigation .site-menu .has-children .dropdown > li.has-children > .dropdown,
.site-navbar .site-navigation .site-menu .has-children .dropdown > li.has-children > ul {
  left: 100%;
  top: 0;
}

.site-navbar .site-navigation .site-menu .has-children .dropdown > li.has-children > .dropdown.sub-menu {
  left: 107% !important;
  top: -14px !important;
}

.site-navbar .site-navigation .site-menu .has-children:hover,
.site-navbar .site-navigation .site-menu .has-children:focus,
.site-navbar .site-navigation .site-menu .has-children:active {
  cursor: pointer;
}

.site-navbar .site-navigation .site-menu .has-children:hover > .dropdown,
.site-navbar .site-navigation .site-menu .has-children:focus > .dropdown,
.site-navbar .site-navigation .site-menu .has-children:active > .dropdown {
  transition-delay: 0s;
  margin-top: -1px;
  visibility: visible;
  opacity: 1;
}

.site-mobile-menu {
  width: 320px;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 20000;
  padding-top: 20px;
  background: #fff;
  height: calc(100vh);
  transform: translateX(110%);
  box-shadow: -10px 0 20px -10px rgba(0, 0, 0, 0.1);
  transition: 0.3s all ease-in-out;
}

.offcanvas-menu .site-mobile-menu {
  transform: translateX(0%);
}

.mobile-bar span {
  font-size: 25px;
  /* padding: 0 10px; */
  border-radius: 4px;
}

.site-navbar-wrap.v1 .mobile-bar span {
  color: #fff;
}

.site-navbar-wrap.v2 .mobile-bar span {
  color: #000;
}

.site-mobile-menu-close.js-menu-toggle span {
  color: #000;
  margin-right: 20px;
  font-size: 35px !important;
}

.site-mobile-menu .site-mobile-menu-header {
  width: 100%;
  float: left;
  padding-left: 20px;
  padding-right: 20px;
}

.site-mobile-menu .site-mobile-menu-header .site-mobile-menu-close {
  float: right;
  margin-top: 8px;
}

.site-mobile-menu .site-mobile-menu-header .site-mobile-menu-close span {
  font-size: 30px;
  display: inline-block;
  padding-left: 10px;
  padding-right: 0px;
  line-height: 1;
  cursor: pointer;
  transition: 0.3s all ease;
}

.site-mobile-menu .site-mobile-menu-header .site-mobile-menu-close span:hover {
  color: #25262a;
}

.site-mobile-menu .site-mobile-menu-header .site-mobile-menu-logo {
  float: left;
  margin-top: 10px;
  margin-left: 0px;
}

.site-mobile-menu .site-mobile-menu-header .site-mobile-menu-logo a {
  display: inline-block;
  text-transform: uppercase;
}

.site-mobile-menu .site-mobile-menu-header .site-mobile-menu-logo a img {
  max-width: 70px;
}

.site-mobile-menu .site-mobile-menu-header .site-mobile-menu-logo a:hover {
  text-decoration: none;
}

.site-mobile-menu .site-mobile-menu-body {
  overflow-y: scroll !important;
  position: relative;
  padding: 20px 30px;
  height: calc(100vh - 52px);
  padding-bottom: 150px;
}

.site-mobile-menu .site-nav-wrap {
  padding: 0;
  margin: 0;
  list-style: none;
  position: relative;
}

.site-mobile-menu .site-nav-wrap a {
  padding: 15px 0;
  display: block;
  position: relative;
  color: #212529;
}

.site-mobile-menu .site-nav-wrap a:hover {
  color: #28a745;
}

.site-mobile-menu .site-nav-wrap li {
  position: relative;
  display: block;
}

.site-mobile-menu .site-nav-wrap li.active > a {
  color: #28a745;
}

.site-mobile-menu .site-nav-wrap .arrow-collapse {
  position: absolute;
  right: 0px;
  top: 10px;
  z-index: 20;
  width: 36px;
  height: 36px;
  text-align: center;
  cursor: pointer;
  border-radius: 50%;
}

.site-mobile-menu .site-nav-wrap .arrow-collapse:hover {
  background: #f8f9fa;
}

.site-mobile-menu .site-nav-wrap .arrow-collapse:before {
  font-size: 12px;
  z-index: 20;
  font-family: "Ionicons";
  content: "\f3d0";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) rotate(-180deg);
  transition: 0.3s all ease;
}

.site-mobile-menu .site-nav-wrap .arrow-collapse.collapsed:before {
  transform: translate(-50%, -50%);
}

.site-mobile-menu .site-nav-wrap > li {
  display: block;
  position: relative;
  float: left;
  width: 100%;
}

.site-mobile-menu .site-nav-wrap > li > a {
  font-size: 16px;
}

.site-mobile-menu .site-nav-wrap > li > ul {
  padding: 0;
  margin: 0;
  list-style: none;
}

.site-mobile-menu .site-nav-wrap > li > ul > li {
  display: block;
}

.site-mobile-menu .site-nav-wrap > li > ul > li > a {
  padding-left: 40px;
  font-size: 16px;
}

.site-mobile-menu .site-nav-wrap > li > ul > li > ul {
  padding: 0;
  margin: 0;
}

.site-mobile-menu .site-nav-wrap > li > ul > li > ul > li {
  display: block;
}

.site-mobile-menu .site-nav-wrap > li > ul > li > ul > li > a {
  font-size: 16px;
  padding-left: 60px;
}

.add-list.float-right {
  margin-top: 4px;
}

/*---------------------------------------------------
               Hero Area
---------------------------------------------------- */

.hero_sec .ng-select {
  padding: 5px 6px;
}
.hero_sec .hero_input_box {
  background: #fff;
  border-radius: 15px;
}
.hero .hero-content {
  top: 0;
}
.hero .hero-slide::before {
  position: absolute;
  content: "";
  background: #0b0b0b5c;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.border-l-r {
  border-right: 1px solid #d5d5d5;
}
/* .hero  .slick-track{
  height: 500px;
} */

/* @media only screen and (max-width: 600px) {
  .hero {
    background-image: url("assets/images/header/mobile_main_banner.jpg");
  }
} */

.hero.bg-zoom {
  animation: breath 20s linear infinite;
}

@keyframes breath {
  0% {
    background-size: 100% auto;
  }

  50% {
    background-size: 110% auto;
  }

  100% {
    background-size: 100% auto;
  }
}

.hero__description,
.hero-desc.v2 {
  color: black;
  font-size: 1.2rem;
  font-weight: 500;
  margin-bottom: 0.9375rem;
}

.hero__title,
.hero-title.v2 {
  color: #fff;
  font-size: 3.5rem;
  line-height: 1.5;
  font-weight: 600;
  margin-bottom: 10px;
  margin-left: -4px;
  text-transform: capitalize;
  transition: all 500ms;
}

.hero__title.events-tab {
  display: none;
}

.hero__list {
  overflow: hidden;
}

.hero__list-item {
  list-style: none;
  margin-right: 0.0625rem;
  display: inline-block;
  margin: 0 10px;
}

.hero__list-item a {
  padding: 10px 20px;
  background: rgba(0, 0, 0, 0.6);
  color: #fff;
  font-size: 1.0625rem;
  font-weight: 600;
  display: inline-block;
  border-top-left-radius: 2px;
  border-top-right-radius: 2px;
}

.hero__list-item a i {
  display: inline-block;
  margin-top: 0.125rem;
  font-size: 1.25rem;
  margin-left: 0.8rem;
}

.hero__list-item a.active-list {
  background: #fff;
  color: #0cbcb7;
}

/*--------Hero one----------*/

.hero__form.v1 .nice-select.hero__form-input {
  line-height: 15px !important;
  font-size: 15px;
}

.nice-select.hero__form-input.custom-select span {
  color: #999;
}

.hero__form select {
  height: 3.3125rem;
}

.hero__form-favourite {
  padding: 1.875rem 0;
  background: transparent;
  margin-bottom: 5rem;
}

.hero__form-input {
  border: 1px solid transparent;
  border-bottom: 1px solid #ddd;
  margin-right: 0.625rem;
  color: #333;
  font-size: 0.9rem;
  font-weight: 400;
  background: transparent;
  outline: none;
  border-radius: 0px;
  transition: all 0.2s ease-in-out;
  margin-bottom: 0;
  padding: 10px;
}

.hero__form-input-favourite {
  border-bottom: 1px solid #ffffff;
  width: 16rem;
  margin-right: 1.875rem;
  color: #ccc;
}

.hero__form-input:focus {
  background: transparent;
  border-bottom: 1px solid #ebebeb;
}

.hero__form-input-favourite:focus {
  background: transparent;
  border: 1px solid #fb646f;
}

.hero__input-holders {
  display: inline-block;
  width: 48rem;
  background: #fff;
  padding: 0.625rem 1.5rem;
  border-radius: 1.6rem;
}

.hero-events {
  background: url("assets/images/header/slider-3.jpg");
  background-size: cover;
  background-position: top;
  background-repeat: no-repeat;
}

/*---------------Hero two------------*/

.hero__form.v2.filter {
  border-radius: 50px;
}

.hero__form.v2 .btn-default.btn-default-red {
  margin-left: 85px;
}

.hero__form.v2 .hero__form-input::before {
  position: absolute;
  top: -2px;
  width: 1px;
  height: 40px;
  content: "";
  background: #e0e0e0;
  left: -5px;
}

.hero__form.v2 .hero__form-input {
  border-bottom: none;
  position: relative;
}

.hero__form.v2 .nice-select.hero__form-input {
  line-height: 20px !important;
  font-size: 15px;
}

.hero__form.v2.bg-grey.filter .btn.v3 {
  margin-left: 77px;
}

/*---------Video Header------*/

.video-area.v1 {
  height: 100vh;
}

.video-area.v1 .video-content {
  z-index: 1001;
  padding-top: 50px;
}

.video-area.v1 .overlay {
  z-index: 1001;
}

.video-area.v1 .video-container {
  position: relative;
  overflow: hidden;
  width: 100vw;
  height: 100vh;
  z-index: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

/*------------Video Area Fullscreen----------------*/

.video-full,
.map-full,
.hero-full {
  min-width: 100%;
  min-height: 100%;
  position: fixed;
  right: 0;
  bottom: 0;
}

.sec_footer {
  position: absolute;
  bottom: 2%;
  width: 100%;
  z-index: 1001;
}

.video-full .hero__title,
.hero-title.v2 {
  line-height: 1.2;
}

.sec_footer .social-buttons.style2 li a i {
  font-size: 15px;
}

.sec_footer .contact-info .icon {
  font-size: 16px;
  width: 22px;
}

.sec_footer .contact-info .text {
  font-size: 12px;
}

.map-full.contact-map.v2 {
  position: fixed;
}

.map-full.contact-map.v2 #map_right_listing {
  height: 100vh;
}

.map-full .nice-select.open .list {
  height: 90px;
}

/*---------------Hero slider--------*/

.hero-slider-item {
  position: relative;
}

.hero-slider-item img {
  width: 100%;
}

.hero-slider-content {
  position: absolute;
  top: 40%;
  width: 640px;
}

.hero-slider-content h3 {
  color: #fff;
  font-size: 45px;
  font-weight: 400;
  line-height: 1.2;
}

.hero-slider-content p {
  color: #fff;
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 25px;
}

.hero-next,
.hero-prev {
  position: absolute;
  top: 50%;
  z-index: 999;
  display: none;
  transition: 0.5s;
  width: 35px;
  height: 35px;
  border-radius: 50%;
  text-align: center;
  border: 2px solid #f5f6f7;
  cursor: pointer;
  transition: 0.4s;
}

.hero-next i,
.hero-prev i {
  line-height: 33px;
  font-size: 16px;
  color: #f5f6f7;
  transition: 0.4s;
}

.hero-slider-wrap:hover .hero-next,
.hero-slider-wrap:hover .hero-prev {
  display: block;
}

.hero-next {
  right: 5%;
}

.hero-prev {
  left: 5%;
}

.hero-slider-form {
  z-index: 20;
  position: absolute;
  width: 100%;
  bottom: -10%;
}

.hero-slider-form .hero__form.v2 {
  padding: 45px 20px;
  background: #fff;
  border-radius: 3px;
  box-shadow: 0 0px 15px #55555547;
}

.hero-slider-form .hero__form.v2 .hero__form-input {
  border-bottom: 1px solid #ddd;
}

.hero-slider-form .hero__form.v2 .hero__form-input::before {
  display: none;
}

/*-----------hero parallax---------*/

.hero.v3 {
  background-attachment: fixed;
}

/*-----------hero Half form---------*/

.hero-area.v1 {
  background-size: cover;
  background-repeat: no-repeat;
  height: auto;
}

.hero-area.v1 .hero__form.v2 {
  border-radius: 0;
  background: transparent;
  padding: 0;
}

.hero-area.v1 .hero__form.v2 .hero__form-input {
  background: #fff;
  margin-bottom: 20px;
  height: 50px;
  border-radius: 3px;
  padding-left: 25px;
}

.hero-area.v1 .hero__form.v2 .hero__form-input::before {
  display: none;
}

.hero-area.v1 .hero-title.v2 {
  color: #fff;
  font-size: 2.375rem;
  line-height: 4.2rem;
  margin-bottom: 0;
  margin-left: 0;
}

.hero-area.v1 .hero-desc.v2 {
  margin-bottom: 0;
}

/*----------Form----------*/

.nice-select {
  background-color: transparent;
  border-bottom: 1px solid #ddd;
  box-sizing: border-box;
  clear: both;
  cursor: pointer;
  display: inline-block;
  font-size: 15px;
  line-height: 40px;
  padding-left: 18px;
  padding-right: 30px;
  position: relative;
  text-align: left !important;
  user-select: none;
}

.nice-select:after {
  content: "\f3d0";
  font-family: "Ionicons";
  display: block;
  pointer-events: none;
  position: absolute;
  right: 5%;
  top: 30%;
  font-size: 16px;
  color: #999;
  -webkit-transition: all 0.15s ease-in-out;
  transition: all 0.15s ease-in-out;
}

.nice-select.open:after {
  -webkit-transform: rotate(-180deg);
  -ms-transform: rotate(-180deg);
  transform: rotate(-180deg);
}

.nice-select.open .list {
  opacity: 1;
  pointer-events: auto;
  -webkit-transform: scale(1) translateY(0);
  -ms-transform: scale(1) translateY(0);
  transform: scale(1) translateY(0);
  width: 100%;
  height: 200px;
  overflow-y: scroll;
}

.nice-select.disabled {
  border-color: #ededed;
  color: #999;
  pointer-events: none;
}

.nice-select.disabled:after {
  border-color: #cccccc;
}

.nice-select .list {
  background-color: #fff;
  border-radius: 1px;
  box-shadow: 0 0 0 1px rgba(68, 68, 68, 0.11);
  box-sizing: border-box;
  margin-top: 4px;
  opacity: 0;
  overflow: hidden;
  padding: 0;
  pointer-events: none;
  position: absolute;
  top: 100%;
  left: 0;
  transform-origin: 50% 0;
  transform: scale(0.75) translateY(-21px);
  transition:
    all 0.2s cubic-bezier(0.5, 0, 0, 1.25),
    opacity 0.15s ease-out;
  z-index: 9;
}

.nice-select .list:hover .option:not(:hover) {
  background-color: transparent !important;
}

.nice-select .option {
  cursor: pointer;
  font-weight: 400;
  line-height: 40px;
  list-style: none;
  min-height: 40px;
  outline: none;
  padding-left: 18px;
  padding-right: 29px;
  text-align: left;
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
}

.nice-select .option:hover {
  background-color: #2ac590;
  color: #fff;
}

.nice-select .option.disabled {
  background-color: transparent;
  color: #999;
  cursor: default;
}

.custom-select,
.custom-select:focus {
  border-top: none;
  border-left: none;
  border-right: none;
  box-shadow: none;
  outline: none;
}

/*-------------------------------------------
               Breadcrumb
-----------------------------------------*/

.breadcrumb-section {
  position: relative;
  display: flex;
  align-items: center;
  height: 300px;
  background-attachment: fixed;
  background-repeat: no-repeat;
}

.page-title {
  color: #fff;
}

.breadcrumb-menu ul li {
  position: relative;
  display: inline-block;
}

.breadcrumb-menu ul li::before {
  position: absolute;
  content: "/";
  right: -3px;
  top: 2px;
  color: #fff;
}

.breadcrumb-menu ul li:last-child:before {
  display: none;
}

.breadcrumb-menu ul li:last-child a {
  padding-right: 0;
}

.breadcrumb-menu ul li a {
  color: #fff;
  font-size: 14px;
  padding: 0 10px;
  text-transform: uppercase;
  letter-spacing: 2px;
}

.breadcrumb-menu ul li:nth-child(1) a {
  padding-left: 0;
}

/*-----------------------------------------
        Catagory Box
------------------------------------------*/

.hero-catagory-wrapper {
  width: calc(100% + 20px);
  left: -20px;
  position: relative;
  display: flex;
  flex-wrap: wrap;
}

.hero-category-content {
  width: calc(100% * (1 / 6) - 20px);
  float: left;
  margin: 0 0 0 20px;
  padding: 30px 16px;
  text-align: center;
  transition: 0.4s;
  transform: scale(1);
  vertical-align: top;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border: 1px solid rgba(30, 195, 139, 0.4);
  border-radius: 4px;
}

.hero-category-content i {
  font-size: 35px;
  color: #0cbcb7;
}

.hero-category-content:hover {
  background: #0cbcb7;
}

.hero-category-content:hover i {
  color: #fff !important;
}

.hero-category-content .name {
  margin: 5px 0 0 0;
  color: #635e5e;
  transition: 0.4s;
}

.hero-category-content p.d-name {
  opacity: 0;
  transition: 0.4s;
  color: #fff;
  margin-top: -25px;
}

.hero-category-content:hover p.name {
  opacity: 0;
}

.hero-category-content:hover p.d-name {
  opacity: 1;
  margin-top: -25px;
}

/*----------style2-----------------*/

.hero-catagory-menu {
  transform: translateY(35px);
}

.hero-catagory-menu p {
  display: block;
  color: #fff;
  margin-bottom: 20px;
  font-weight: 300;
  font-size: 18px;
}

.hero-catagory-menu ul li {
  display: inline-block;
  margin-left: 5px;
}

.hero-catagory-menu ul li:first-child {
  margin-left: 0;
}

.hero-catagory-menu ul li {
  font-size: 18px;
}

.hero-catagory-menu ul li a {
  color: #fff;
  border: 1px solid rgba(255, 255, 255, 0.7);
  padding: 7px 15px;
  border-radius: 50px;
  font-size: 11px;
  letter-spacing: 2px;
  transition: 0.5s;
  text-transform: uppercase;
  line-height: 1.2;
  background: transparent;
}

.hero-catagory-menu ul li a:hover {
  background: rgba(255, 255, 255, 0.1);
  color: #fff;
  border: 1px solid transparent;
}

.hero-catagory-menu ul li a i {
  font-size: 16px;
}

.hero-catagory-content.v2 i {
  color: #fff;
  font-size: 40px;
}

.hero-catagory-content.v2:hover i,
.hero-catagory-content.v2:hover .name {
  color: #0cbcb7;
}

.hero-category-content.v2 i,
.hero-category-content.v2 .name {
  color: #fff;
}

.hero-category-content.v2 {
  border: 1px solid transparent;
}

.hero-category-content.v2:hover {
  background: transparent;
  border: 1px solid #fff;
}

.hero-catagory-wrapper.v2 ul li {
  display: inline-block;
}

/*------------------------------------
            Work Proces
------------------------------------*/

.work-process-content {
  background: #fff;
  transition: 0.5s;
  box-shadow: 0 0 3px #cbcbcb;
  border-radius: 6px;
  margin: 12px 0;
  position: relative;
  min-height: 330px;
}

.work-process-content.v1:hover {
  transform: translateY(-10px);
}

.work-process-content .number {
  background: #0cbcb7;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  color: #fff;
  text-align: center;
  line-height: 50px;
  padding: 0;
  margin: 0 auto 30px;
}

.work-process-content .quote_icon i {
  font-size: 34px;
  color: #f7690063;
}
.process-icon {
  text-align: center;
  position: relative;
}

.process-icon.v1 {
  width: 76px;
  height: 76px;
  border: 3px solid #f76900;
  display: flex;
  flex-direction: column;
  vertical-align: middle;
  border-radius: 50%;
  overflow: hidden;
}

.work-process-content .quote_icon {
  top: 12px;
  right: 12px;
}

.process-icon i {
  line-height: 100px;
}

.process-icon span {
  position: absolute;
  width: 25px;
  height: 25px;
  /* background: #0cbcb7;
  border-radius: 50%; */
  top: -17px;
  line-height: 24px;
  color: #fff;
  border: 5px solid #fff;
  box-sizing: content-box;
}

.work-process-content .title {
  font-size: 17px;
  font-weight: 500;
  color: #000;
  text-align: start;
}

.work-process-content .title {
  font-size: 17px;
  font-weight: 500;
  color: #000;
  text-align: start;
}

.work-process-content .info {
  display: -webkit-box;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  transition: all 500ms;
}

.work-process-content:hover .info {
  -webkit-line-clamp: none;
}

/*--------------------------------------------
            Popular category
----------------------------------------------*/

.popular-catagory-content {
  position: relative;
  overflow: hidden;
  margin-bottom: 30px;
}

.popular-catagory-img img {
  border-radius: 3px;
}

.popular-catagory-content .cat-content {
  opacity: 0;
  position: absolute;
  top: 0;
  transition: all 0.6s;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  background-color: rgba(30, 195, 139, 0.9);
  border-radius: 3px;
}

.popular-catagory-content .title {
  color: #fff;
  font-size: 30px;
  margin: 0 0 10px 0;
  position: absolute;
  transform: translate(-50%, -50%);
  display: inline-block;
  top: 45%;
  left: 50%;
  padding-bottom: 5px;
}

.popular-catagory-content .title:before {
  position: absolute;
  bottom: 0%;
  left: 50%;
  content: "";
  width: 30px;
  height: 2px;
  background: #fff;
  transform: translateX(-50%);
}

.popular-catagory-content .info {
  font-size: 14px;
  color: #fff;
  margin: 0;
}

.popular-catagory-content .cat-content span {
  color: #fff;
  font-size: 18px;
  margin-top: 70px;
}

/*-----------------------------
        style 2
------------------------------*/

.popular-catagory-content.v2 .cat-content i {
  color: #fff;
  font-size: 30px;
}

.popular-catagory-content.v2 .cat-content {
  opacity: 1;
  background: transparent;
}

.popular-catagory-content.v2 .title {
  top: 52%;
  font-weight: 400;
  font-size: 20px;
}

.popular-catagory-content.v2 .cat-content span {
  font-size: 15px;
}

.popular-catagory-content.v2 {
  border-radius: 3px;
}

/*------------------------------------------
       Discount Coupon
--------------------------------------------*/

.coupon-wrap .swiper-wrapper {
  padding-bottom: 10px;
}

.coupon-content {
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 3px;
}

.coupon-item .price {
  color: #999;
  font-size: 15px;
  font-weight: 500;
}

.coupon-owner a.rating {
  display: block;
}

.price {
  color: #000;
  font-weight: 600;
  margin-left: 10px;
}

.coupon-item .modal .code {
  border: 2px dashed #000;
  text-align: center;
  padding: 50px 0;
  margin: 0;
}

.coupon-item .modal-body {
  display: flex;
  flex-direction: column;
}

.coupon-item .modal-body .btn {
  border: 1px solid #000;
  margin-top: 15px;
}

.coupon-content p.d-inline {
  font-size: 20px;
  font-weight: 500;
}

.coupon-bottom .trend-left p {
  display: inline-block;
}

.coupon-owner a {
  font-size: 17px;
}

.modal-backdrop {
  z-index: 200;
}

.store-content span {
  margin-right: 5px;
  font-weight: 600;
}

.store-content div {
  padding-bottom: 10px;
}

.coupon-code {
  border: 2px dotted #ddd;
  text-align: center;
}

.coupon-code h5 {
  margin-bottom: 0;
  padding: 10px 0;
}

.coupon-bottom {
  padding: 15px 15px 0px 15px;
}

.modal-content {
  padding: 0px 10px 25px 10px;
}

.coupon-owner a {
  font-weight: 500;
  margin-right: 15px;
}

.coupon-next,
.coupon-prev {
  position: absolute;
  top: 42%;
  z-index: 999;
}

.coupon-next {
  right: -3%;
}

.coupon-prev {
  left: -3%;
}

.rating i {
  color: #ffc728;
}

/*--------------------------------------------------------
           Trending places
 -------------------------------------------------------- */

.trending-place-item {
  background: #fff;
  margin: 0;
  -webkit-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  transition: all 0.5s ease;
  position: relative;
  border-radius: 3px;
}

.trending-img {
  position: relative;
  overflow: hidden;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  height: 100%;
}

.trending-img img {
  transition: 0.5s;
}

.trending-place-item a {
  display: block;
  text-decoration: none;
  color: #000;
  font-size: 15px;
  letter-spacing: 1px;
}

.trending-place-item .save-btn {
  position: absolute;
  left: 6%;
  z-index: 1;
  top: 75%;
  color: #fff;
  text-align: center;
  font-weight: 700;
  background-color: rgba(0, 0, 0, 0.37);
  width: 30px;
  height: 30px;
  font-size: 17px;
  border-radius: 50%;
  line-height: 1.9;
  cursor: pointer;
  transition: 0.5s;
}

.trending-place-item .save-btn1 {
  position: absolute;
  left: 6%;
  z-index: 999;
  top: 80%;
  color: #fff;
  text-align: center;
  font-weight: 700;
  background-color: #f76900;
  width: 30px;
  height: 30px;
  font-size: 17px;
  border-radius: 50%;
  line-height: 1.9;
  cursor: pointer;
  transition: 0.5s;
}

.trending-place-item .save-btn:hover {
  background: #0cbcb7;
}

.trending-place-item img {
  width: 100%;
}

.trending-place-item span {
  font-size: 17px;
  font-weight: 400;
}

.trending-rating,
.trending-rating-orange,
.trending-rating-pink,
.trending-rating-green {
  position: absolute;
  right: 10px;
  width: 45px;
  height: 45px;
  text-align: center;
  font-size: 19px;
  background: #ff7474;
  color: #fff;
  border-radius: 50%;
  top: 15px;
  right: 17px;
  text-align: center;
  line-height: 44px;
}

.trending-rating-orange {
  background: #ffae00 !important;
}

.trending-rating-green {
  background: #46cd38 !important;
}

.trending-rating-pink {
  background: #ff6d6d !important;
}

.trending-title-box {
  padding: 8px 28px 16px 28px;
}

.trending-title-box h4 a {
  font-size: 18px;
  letter-spacing: 0;
  font-family: "Poppins", sans-serif;
}

.trending-row {
  position: relative;
}

.trending-places {
  /*    background: #f5f6f7;*/
  position: relative;
}

.trending-pagination,
.client-pagination {
  transform: translate(50%, 50%);
  position: absolute;
  right: 50%;
  bottom: -8%;
}

.trending-pagination .swiper-pagination-bullet,
.client-pagination .swiper-pagination-bullet {
  width: 12px;
  height: 12px;
  display: inline-block;
  border-radius: 50%;
  background: #0cbcb7;
  margin-right: 6px;
  opacity: 0.4;
}

.trending-pagination .swiper-pagination-bullet-active,
.client-pagination .swiper-pagination-bullet-active {
  opacity: 1;
  background: #0cbcb7 !important;
}

.product-rating li {
  display: inline-block;
}

.product-rating li i {
  font-size: 18px;
  cursor: pointer;
  color: #ffc600;
}

.review-summury {
  line-height: 55px;
}

.customer-review {
  margin-bottom: 12px;
}

.trend-left span {
  margin-right: 7px;
}

.lb-data .lb-number {
  display: none !important;
}

.trend-left span,
.trend-right a {
  font-size: 18px;
  cursor: pointer;
  display: inline-block;
  line-height: 28px;
}

.trend-right i {
  color: #999;
  font-size: 18px;
}

.trending-title-box h4 {
  font-family: "Poppins", sans-serif;
  margin-bottom: 0;
}

.trending-title-box p {
  color: #141414;
  margin: 0;
}

.description h3 {
  color: #999;
  margin: 0;
}

.description h3 span {
  color: #999;
  margin: 0;
}

.trending-title-box p span {
  color: #ffb006;
  padding: 0;
}

.trending-address li i {
  color: #0cbcb7;
  position: absolute;
  font-size: 20px;
  line-height: 1.2;
}

.trending-address li p {
  margin-left: 40px;
}

.trending-address {
  padding: 0;
  margin: 10px 0 0;
}

.trending-address li {
  list-style: none;
  color: #737882;
  position: relative;
}

.trending-bottom {
  border-top: 1px solid #ededed;
  padding-top: 20px;
}

.trending-bottom p {
  display: inline-block;
}

.trending-address li p {
  margin-left: 40px;
  padding-bottom: 5px;
}

.trend-closed {
  color: #ff3a6d;
  font-size: 13px;
  display: block;
  font-weight: 400;
  display: inline-block;
  margin-left: 10px;
  line-height: 2.2;
}

.trend-open {
  color: #46cd38;
  font-size: 14px;
  display: block;
  font-weight: 400;
  display: inline-block;
  margin-left: 10px;
}

.trend-open p {
  font-size: 14px;
}

.trend-open i {
  margin-right: 5px;
  font-size: 15px;
}

.single-place {
  overflow: hidden;
  position: relative;
  transition: all 0.2s ease-in-out;
  border-radius: 5px;
}

.single-place::after,
.single-blog-item.v2:after {
  content: "";
  top: 0;
  position: absolute;
  height: 100%;
  width: 100%;
  z-index: 9;
  /* background: linear-gradient(to top, rgba(0, 0, 0, 0.65) 0%, rgba(0, 0, 0, 0.65) 35%, rgba(22, 22, 23, 0) 80%, rgba(0, 0, 0, 0) 100%); */
  border-radius: 4px;
  opacity: 1;
}

.single-place-image {
  width: 100%;
  height: auto;
  border-radius: 5px;
  transition: all 0.3s ease-in-out;
  position: relative;
}

.single-place-content {
  position: absolute;
  bottom: 1.6rem;
  left: 0.5rem;
  width: 100%;
  padding: 0 1.875rem;
  z-index: 10;
}

.single-place-title {
  font-size: 1.5rem;
  text-transform: uppercase;
  transition: 0.5s;
  transform: translateY(30px);
}

.single-place-title a,
.single-place-list li {
  color: #fff;
}

.single-place-title a {
  font-size: 22px;
}

.single-place-list {
  transform: translatey(20px);
  transition: 0.5s;
}

.single-place-list li {
  font-size: 16px;
  display: inline-block;
  position: relative;
  margin-right: 20px;
}

.single-place-list li:first-child:after {
  position: absolute;
  top: 0;
  right: -18px;
  content: "~";
  background: transparent;
  width: 10px;
  font-size: 18px;
}

.single-place-list.v2 li:first-child::after {
  display: none;
}

.single-place-list li span {
  font-weight: 600;
  margin-right: 5px;
}

.single-place-image {
  transition: 0.5s;
  transform: scale(1);
}

.single-place:hover .single-place-image {
  transform: scale(1.1);
}

.explore-place {
  transition: all 0.5s;
  transform: translateY(40px);
  opacity: 0;
  transition-delay: 0.4s;
}

.popular-next,
.popular-prev {
  position: absolute;
  top: 45%;
  z-index: 999;
}

.popular-prev {
  left: -3%;
}

.popular-next {
  right: -3%;
}

.filter .active a {
  color: #0cbcb7;
  text-decoration: none;
}

/*------------------------------------------------
           Testimonial Section
--------------------------------------------------*/

.testimonial-wrapper {
  position: relative;
}

.single-testimonial-item {
  opacity: 0.4;
}

.single-testimonial-item.swiper-slide-active {
  opacity: 1;
}

.single-testimonial-item.swiper-slide-active .testimonial-content {
  background: rgba(30, 195, 139, 0.85);
  box-shadow: 0 10px 10px rgba(0, 0, 0, 0.07);
  border-radius: 3px;
}

.single-testimonial-item.swiper-slide-active .testimonial-content h5,
.single-testimonial-item.swiper-slide-active .testimonial-content p,
.single-testimonial-item.swiper-slide-active .testimonial-content span {
  color: #fff;
}

.testimonial-img.text-center img {
  width: 120px;
  height: 120px;
  border-radius: 50%;
  border: 8px solid #fff;
  margin-bottom: -55px;
}

.testimonial-content {
  background-color: #fff;
  padding: 60px 30px 40px 30px;
  margin: 15px;
  transition: 0.6s;
}

.testimonial-content h5 {
  margin-bottom: 5px;
}

.testimonial-content p {
  margin-top: 20px;
}

.testimonial-next,
.testimonial-prev {
  position: absolute;
  bottom: -60px;
  z-index: 999;
}

.testimonial-next {
  right: 46%;
}

.testimonial-prev {
  left: 47%;
}

/*----------------------------------------
            Call to Action
----------------------------------------*/

.call-to-action {
  position: relative;
}

.action-title h2,
.action-title p {
  color: #fff;
}

/*------------------------------------------
            Partner section
-----------------------------------------*/

.single-partner {
  text-align: center;
}

.partner-next,
.partner-prev {
  position: absolute;
  top: 42%;
  z-index: 999;
}

.partner-prev {
  left: -1%;
}

.partner-next {
  right: -1%;
}

/*----------------------------------
       Scroll-to-top
-------------------------------------*/

.scrolltotop {
  border: 1px solid transparent;
  border-radius: 50%;
  bottom: 40px;
  color: #fff;
  cursor: pointer;
  display: none;
  font-size: 20px;
  height: 35px;
  text-align: center;
  position: fixed;
  right: 30px;
  width: 35px;
  z-index: 999;
  background: #0cbcb7;
  line-height: 35px;
}

.scrolltotop:after {
  position: absolute;
  z-index: -1;
  content: "";
  top: 100%;
  left: 5%;
  height: 10px;
  width: 90%;
  opacity: 1;
  background: radial-gradient(ellipse at center, rgba(0, 0, 0, 0.25) 0%, rgba(0, 0, 0, 0) 80%);
}

/*----------------------------------------------
                FAQ Page
------------------------------------------------*/

.faq-section .card {
  background-color: transparent;
  border-bottom: 1px solid #ddd;
  margin-bottom: 10px;
}

.faq-section .card-body {
  padding: 30px 10px;
}

.faq-section .card-header,
.faq-section .card-header:first-child,
.faq-section .card-header:last-child {
  background-color: #f1f1f1;
  border: none;
  padding: 0;
}

.faq-section .card-header > a[aria-expanded="true"] {
  background-color: #141414;
  color: #fff;
}

.faq-section .card-header > a {
  border-color: #f1f1f1;
  color: #141414;
  display: block;
  font-size: 12px;
  font-weight: 700;
  letter-spacing: 0.075em;
  padding: 14px;
  text-decoration: none;
  text-transform: uppercase;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.faq-section .card-header > a[aria-expanded="true"]::before {
  content: "\f462";
  font-family: "Ionicons";
  float: right !important;
  line-height: 12px;
  font-size: 20px;
}

.faq-section .card-header > a[aria-expanded="false"]::before {
  color: #777;
  content: "\f489";
  font-family: "Ionicons";
  float: right !important;
  line-height: 12px;
  font-size: 20px;
}

/*---------------------------------------------
                    Invoice
---------------------------------------------*/

.payment_receipt--wrapper {
  background: #fff;
  padding: 50px 50px 70px 50px;
}

.payment_receipt--wrapper .atbd_thank_you {
  text-align: center;
  margin: 0 0 1rem;
}

.payment_receipt--wrapper h4 {
  margin: 0 0 0.66667rem;
}

.payment_receipt--wrapper ul {
  margin-bottom: 2rem;
}

.payment_receipt--wrapper ul li {
  margin-bottom: 0.4rem;
}

.payment_receipt--wrapper ul li span {
  color: #272b41;
  font-weight: 600;
}

.payment_receipt--wrapper .atbd_payment_summary_wrapper {
  margin-top: 3.33333rem;
  margin-bottom: 3.33333rem;
}

.payment_receipt--wrapper .atbd_payment_summary {
  font-size: 1.2rem;
  color: #272b41;
  font-weight: 700;
  text-align: center;
  margin: 0 0 1.66667rem;
}

.payment_receipt--wrapper .atbd_payment_summary_wrapper .table td {
  padding: 1rem 1.33333rem;
}

.payment_receipt--wrapper .atbd_payment_summary {
  font-size: 1.2rem;
  color: #272b41;
  font-weight: 700;
  text-align: center;
  margin: 0 0 1.66667rem;
}

.checkout-table .table {
  background: #fff;
}

.checkout-table .table thead th {
  border-bottom: 0;
  padding: 1.33333rem 2rem;
}

.checkout-table .table tbody td {
  border-color: #e3e6ef;
  padding: 1.33333rem 2rem;
}

/*--------------------------------------------
            App Section
---------------------------------------------*/

.app-section {
  position: relative;
  background: #f5f6f7;
}

.app-img {
  position: absolute;
  top: -130px;
}

.app-content h2 {
  font-weight: 300;
  font-size: 34px;
  line-height: 1.2;
}

.app-content h2 span {
  text-transform: capitalize;
  font-weight: 600;
  font-size: 42px;
  text-shadow: 0px 1px 0px rgba(0, 0, 0, 0.59);
  color: #333333;
}

.app-content ul {
  padding: 10px 0px;
}

.app-content ul li {
  list-style-type: none;
  font-size: 18px;
  line-height: 34px;
  color: #555;
}

.app-content span {
  color: #555;
  font-size: 16px;
  padding: 15px 0 10px 0;
  display: inline-block;
  font-weight: 400;
}

.app-content ul li i {
  margin-right: 10px;
  color: #990099;
}

/*----------------------------------------------
               Footer section
-----------------------------------------------*/

.page-wrapper.fixed-footer {
  margin-bottom: 465px;
  position: relative;
  z-index: 1000;
  background-color: #fff;
}

.footer-wrapper.footer-fixed {
  background-size: auto;
  position: fixed;
  bottom: 0;
  width: 100%;
}

.footer-wrapper.v1 .footer-bottom-area {
  border-top: 1px solid rgba(217, 217, 217, 0.98);
}

.footer-wrapper.v1 .contact-info .text,
.footer-wrapper.v1 .contact-info .text a,
.footer-wrapper.v1 .contact-info .icon,
.footer-wrapper.v1 .social-buttons li a i {
  color: #555;
}

.footer-content.nav {
  display: inline-block;
}

.footer-logo {
  margin-top: 0px;
}

.footer-logo img {
  margin-bottom: 0px;
  max-width: 115px;
}

.footer-top-area {
  color: #fff;
  position: relative;
}

.footer-bottom-area {
  padding: 25px 0;
  border-top: 1px solid rgba(255, 255, 255, 0.23);
}

.footer-bottom-area p {
  font-size: 13px;
  letter-spacing: 1px;
  font-weight: 400;
  color: #555;
}

.footer-top-area .footer-content .title {
  font-size: 20px;
  font-weight: 500;
  color: #333;
  margin-top: 20px;
}

.footer-content li {
  padding-bottom: 10px;
}

.footer .info,
.phone-number,
.email {
  color: #7a7a7a;
  font-size: 14px;
}

.company-desc p {
  font-size: 14px;
  color: #555;
}

.footer .info {
  margin-bottom: 10px;
}

.footer .info,
.phone-number {
  color: #fff;
  font-size: 14px;
}

.phone-number span,
.email span {
  color: #999;
}

.footer-content ul li a {
  color: #fff;
  font-size: 15px;
}

.social-buttons.style2 li a i {
  font-size: 25px;
}

.contact-info {
  line-height: 1.7;
}

.contact-info .icon {
  font-size: 18px;
  padding-right: 10px;
  width: 30px;
  color: #fff;
}

.contact-info .icon,
.contact-info .text {
  display: table-cell;
}

.contact-info .text,
.contact-info .text a {
  color: #fff;
  font-size: 14px;
  line-height: 1.2;
  font-weight: 400;
}

/*--------------style2-----------*/

.footer-wrapper.v2 {
  background: #f5f6f7;
}

.footer-wrapper.v2 .company-desc p {
  color: #555;
}

.footer-wrapper.v2 .footer-top-area .footer-content .title,
.footer-wrapper.v2 .contact-info .icon,
.footer-wrapper.v2 {
  color: #333;
}

.social-buttons li a i {
  color: #f76900;
}

.footer-wrapper.v2 .contact-info .text,
.footer-wrapper.v2 .contact-info .text a {
  color: #555;
  font-weight: 400;
}

.footer-wrapper.v2 .footer-bottom-area {
  border-top: 1px solid rgba(92, 113, 106, 0.13);
  background: #f5f6f7;
}

.payment-method img {
  margin-right: 10px;
}

.additional-link li {
  display: inline-block;
  position: relative;
  margin-right: 20px;
}

.additional-link li:last-child {
  margin-right: 0;
}

.additional-link li:before {
  position: absolute;
  bottom: 8px;
  right: -10px;
  content: "";
  background: #ccc;
  width: 1px;
  height: 15px;
}

.additional-link li:last-child:before {
  display: none;
}

.additional-link li a {
  font-size: 14px;
  color: #555;
  transition: 0.4s;
}

.additional-link li a:hover {
  color: #333;
}

/*--------------------------------------------
              Pricing Table
----------------------------------------------*/

.list-details-tab.pricing-list {
  display: inline-flex;
}

.list-details-tab.nav-tabs.pricing-list .nav-item {
  padding: 0 0 10px 0;
}

.list-details-tab.nav-tabs.pricing-list .nav-item:last-child {
  margin-right: 0;
}

.pricing-table {
  padding-bottom: 20px;
  background: #fff;
  border-radius: 5px;
  text-align: center;
  position: relative;
  transition: all 0.3s ease 0s;
  box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.1);
  overflow: hidden;
}

.pricing-table .title {
  padding: 40px 20px 170px;
  margin: 0 0 30px;
  font-size: 20px;
  font-weight: 500;
  color: #fff;
  text-transform: uppercase;
  overflow: hidden;
  position: relative;
}

.pricing-table .title:after,
.pricing-table .title:before {
  content: "";
  width: 280px;
  height: 200px;
  border-radius: 80px;
  background: #fff;
  position: absolute;
  bottom: -175px;
  left: -46px;
  transform: rotate(-85deg);
}

.pricing-table .title:after {
  border-radius: 100px;
  bottom: auto;
  top: 150px;
  left: auto;
  right: -70px;
  transform: rotate(-40deg);
}

.pricing-table .price-value {
  display: inline-block;
  width: 140px;
  height: 140px;
  line-height: 65px;
  border-radius: 50%;
  background: #fff;
  box-shadow: 0 0 0 8px rgba(166, 167, 163, 0.24);
  font-size: 35px;
  font-weight: 600;
  color: #404040;
  position: absolute;
  top: 110px;
  left: 50%;
  transform: translateX(-50%);
  transition: all 0.3s ease 0s;
}

.pricing-table .month {
  display: block;
  font-size: 16px;
  font-weight: 400;
  line-height: 0;
}

.listing-badge {
  background-color: #fff;
  float: left;
  position: absolute;
  transform: rotate(45deg);
  right: -64px;
  top: 22px;
  text-align: center;
  width: 200px;
  font-size: 12.5px;
  margin: 0;
  z-index: 9;
  color: #f91942;
  font-weight: 500;
  line-height: 28px;
}

.pricing-table.blue.featured {
  overflow: hidden;
}

.pricing-table .pricing-content {
  list-style: none;
  padding: 0;
  margin-bottom: 20px;
  text-align: left;
  transition: all 0.3s ease 0s;
}

.pricing-table .pricing-content li {
  padding: 7px 0 7px 75px;
  font-size: 16px;
  font-weight: 400;
  color: #000;
  letter-spacing: 1px;
  position: relative;
}

.pricing-table .pricing-content li i {
  color: #0cbcb7;
}

.price-num-item {
  font-size: 45px;
  position: relative;
  font-weight: 600;
  color: #666;
  display: inline-block;
  padding-top: 25px;
}

.mouth-cont {
  z-index: 1;
  opacity: 1;
  position: relative;
}

.year-mont .mouth-cont {
  opacity: 0;
}

.price-num span.curen {
  position: relative;
  top: -32px;
  padding-right: 7px;
  font-weight: 400;
  font-size: 17px;
}

.price-num-item p {
  margin-top: -17px;
  font-weight: 300;
  margin-left: 5px;
  font-style: italic;
}

.pricing-table {
  transition: 0.5s;
}

.pricing-content li i {
  margin-right: 10px;
}

.pricing-table .pricing-content li.disable {
  color: #707070;
}

.pricing-table .pricing-content li.disable:before {
  display: none;
}

/*---------------------------------------
                404 PAGE
------------------------------------------*/

.error-content i {
  font-size: 60px;
  display: block;
  color: #0cbcb7;
}

.error-content p {
  margin-bottom: 30px;
}

.error-content a {
  color: #0cbcb7;
}

.error-search-container input {
  border: 1px solid #ddd;
  padding: 10px;
  border-radius: 5px;
  width: 100%;
}

.error-search-container button {
  background: transparent;
  border: none;
  padding: 0;
  margin-left: -27px;
  font-size: 17px;
  cursor: pointer;
}

/*------------------------------------------------
                     Blog
--------------------------------------------------*/

.single-blog-item.v1 {
  border: none;
  margin-bottom: 30px;
}

.single-blog-item.v1 .card-body {
  padding: 10px 0px 15px 0px;
}

.card-body span.blog-cat {
  background: #0cbcb7;
  color: #fff;
  padding: 3px 10px;
  border-radius: 15px;
  font-weight: 500;
}

.single-blog-item.v1 .card-title a:hover {
  color: #999;
}

.single-blog-item.v1 .card-title {
  margin-top: 10px;
}

.blog-cat {
  position: absolute;
  top: 20px;
  left: 20px;
}

.single-blog-item.v2 {
  position: relative;
  transition: all 0.3s;
  margin-bottom: 30px;
}

.single-blog-item.v2 img {
  width: 100%;
  border-radius: 3px;
}

.single-blog-item.v2:after {
  content: "";
  top: 0;
  left: 0;
  position: absolute;
  height: 100%;
  width: 100%;
  z-index: 9;
  background: linear-gradient(
    to top,
    rgba(0, 0, 0, 0.65) 0%,
    rgba(0, 0, 0, 0.65) 35%,
    rgba(22, 22, 23, 0) 80%,
    rgba(0, 0, 0, 0) 100%
  );
  border-radius: 4px;
  opacity: 1;
}

.blog-hover-content {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 0 25px 25px;
  z-index: 10;
}

.blog-title {
  margin-bottom: 0;
}

.blog-title a {
  margin-bottom: 0;
  font-size: 22px;
  line-height: 1.5;
  display: inline-block;
  transition: 0.5s;
  color: #fff;
}

.blog-hover-content .blog-title {
  transform: translatey(35px);
  left: 0;
  transition: 0.4s;
}

.blog-hover-content a.btn.v6 {
  transform: translatey(15px);
  transition: 0.4s;
  opacity: 0;
}

.single-blog-item.v3 {
  margin-bottom: 40px;
}

/*---------Blog Sidebar------------*/

.sidebar {
  display: block;
}

.sidebar .widget.search {
  display: block;
  margin-top: 0;
  position: relative;
  right: 0;
  z-index: 1;
}

.sidebar .widget.search input {
  border-radius: 3px;
}

.sidebar .widget .search-button {
  background: transparent;
  border: none;
  cursor: pointer;
  line-height: 1;
  position: absolute;
  top: 10px;
  right: 5px;
}

.sidebar .widget {
  margin-top: 30px;
}

.sidebar .widget .widget-title {
  border-bottom: 1px solid #ddd;
  font-size: 18px;
  margin-bottom: 20px;
  padding-bottom: 10px;
}

.sidebar .widget.categories ul li {
  font-size: 14px;
  line-height: 40px;
  padding-left: 5px;
}

ul.icon li {
  list-style-type: none;
}

ul.icon li::before {
  content: "\f3d3";
  font-family: "Ionicons";
  height: 10px;
  left: 20px;
  position: absolute;
  width: 10px;
}

.widget.categories ul li a {
  margin-left: 15px;
}

.sidebar .widget.recent-posts .post-list {
  padding-left: 0;
}

.sidebar .widget.recent-posts .entry-item {
  list-style: none;
  margin-bottom: 15px;
  padding-left: 0;
}

.sidebar .widget.recent-posts .entry-item .entry-img {
  display: table-cell;
}

.sidebar .widget.recent-posts .entry-item .entry-img img {
  min-width: 70px;
}

.sidebar .widget.recent-posts .entry-item .entry-text {
  display: table-cell;
  vertical-align: middle;
  padding-left: 15px;
}

.sidebar .widget.recent-posts .entry-title {
  line-height: 1.1;
  margin-bottom: 0;
}

.sidebar .widget.recent-posts .entry-title a {
  color: #141414;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0;
}

.sidebar .widget.recent-posts .entry-date {
  color: #777;
  font-size: 12px;
}

/*--------------pagination---------------*/

/*.post-nav {
    padding: 30px 0 50px 0;
}*/

.post-nav .page-prev {
  text-align: left;
  margin-top: 5px;
}

.post-nav .page-prev p,
.post-nav .page-next p {
  color: #333;
}

.post-nav > div a {
  color: #777;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0.075em;
  line-height: 3.2;
}

.post-nav > div a span {
  text-transform: uppercase;
}

.page-prev a span {
  padding-left: 20px;
}

.page-next a span {
  padding-right: 20px;
}

.post-nav .page-prev a span::before {
  content: "\f3d2";
  font-family: "Ionicons";
  font-size: 18px;
  font-weight: 400;
  left: 0;
  line-height: 0.8;
  position: absolute;
  top: 0;
}

.page-num ul {
  padding-left: 0;
}

.page-num li.active,
.page-num li:hover {
  background: #0cbcb7;
}

.page-num li.active a,
.page-num li:hover a {
  color: #fff;
}

.page-num li {
  display: inline-block;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: #f9f9f9;
  text-align: center;
  transition: 0.5s;
  cursor: pointer;
  border: 1px solid transparent;
  margin: 0 3px;
}

.page-num li a {
  color: #000;
}

.post-nav .page-next {
  text-align: right;
  margin-top: 5px;
}

.post-nav .page-next a span::after {
  content: "\f3d3";
  font-family: "Ionicons";
  font-size: 18px;
  font-weight: 400;
  line-height: 0.8;
  position: absolute;
  right: 0;
  top: 0;
}

/*---------Style2-----------*/

.single-blog-item.v2 {
  position: relative;
  cursor: pointer;
}

.post-content-wrap .post-title.text-center {
  line-height: 1;
}

.post-img {
  margin-top: 30px;
}

.post-content p {
  margin-top: 30px;
}

.comments-title {
  border-bottom: 1px solid #ddd;
  padding-bottom: 20px;
}

.review-box.comment-box {
  border: none;
  padding: 0;
}

.post-tags span {
  color: #000;
  font-weight: 500;
  margin-right: 5px;
}

.author-name h4 {
  padding-right: 15px;
}

.author-name h4::after {
  color: #ddd;
  content: "/";
  font-weight: 300;
  padding-left: 15px;
}

.author {
  margin: 40px 0;
}

.author-info p {
  margin-top: 30px;
}

.comments {
  margin-top: 100px;
}

/*-------------------------------------------
             Filter Listing
--------------------------------------------*/

.range-text {
  line-height: 39px;
}

.price-range .ui-slider-handle.ui-corner-all.ui-state-default {
  border-radius: 50%;
  width: 12px;
  height: 12px;
  border: none;
  box-shadow: 0px 5px 13px rgba(167, 167, 167, 0.7);
  cursor: pointer;
}

.price-range.ui-slider-horizontal .ui-slider-range {
  top: 0;
  height: 4px;
  background: #0cbcb7;
}

.ui-slider-horizontal {
  height: 4px;
  background: #ddd;
}

.price-range.ui-widget.ui-widget-content {
  border: none;
  margin-left: 10px;
}

.filter-box.style1 {
  background: rgba(0, 0, 0, 0) none repeat scroll 0 0;
  margin-bottom: 30px;
}

.filter-box.style1.right {
  padding: 8px 0 0 0;
}

.filter-box.style1.left {
  padding: 8px 0 0 0;
}

.filter-sub-title h5 {
  color: #2f333a;
  display: block;
  font-size: 14px;
  font-weight: 500;
  line-height: 1;
  margin: 30px 0 10px;
  padding: 0 0 10px;
  position: relative;
  text-transform: uppercase;
}

.filter-sub-menu.style1 ul li.has-sub > a,
.open-filter > a {
  color: #777;
  cursor: pointer;
  display: block;
  font-size: 15px;
  line-height: 20px;
  padding: 14px 15px;
  position: relative;
  border: 1px solid #ddd;
  border-radius: 3px;
}

.theme-menu .filter-sub-menu.style1 ul li.has-sub > a {
  border-bottom: none;
  text-transform: uppercase;
  font-weight: 600;
}

.theme-menu .filter-sub-menu.style1 ul li.has-sub li a {
  border-bottom: none;
  margin-bottom: 15px;
}

.theme-menu .filter-sub-menu.style1 > ul > li > a {
  text-transform: uppercase;
  font-size: 16px;
  font-weight: 600;
  color: #363f4d;
}

.theme-menu .filter-sub-menu.style1 ul li.has-sub > a:after {
  content: "\f218";
  font-family: "Ionicons";
}

.theme-menu .filter-sub-menu.style1 ul li.has-sub.open > a:after {
  content: "\f209";
}

.filter-sub-menu.style1 ul li.has-sub > a:after,
.open-filter > a:after {
  color: #999;
  content: "\f35f";
  font-family: "Ionicons";
  font-size: 20px;
  position: absolute;
  right: 13px;
  font-weight: 300;
  transition: 0.5s;
}

.filter-sub-menu.style1 ul li.has-sub.open {
  display: block;
  width: auto;
}

.option-box .has-sub {
  margin-top: 35px;
}

.filter-sub-menu.style1 ul li.has-sub.open > a:after {
  -webkit-transform: rotate(-180deg);
  -ms-transform: rotate(-180deg);
  transform: rotate(-180deg);
}

.filter-sub-menu.style1 ul li.has-sub li a {
  border: 0 none;
  border-bottom-color: currentcolor;
  border-bottom-style: none;
  border-bottom-width: 0px;
  display: block;
  font-size: 14px;
  line-height: 14px;
  margin: 0 0px 10px 0;
  padding: 0 10px 5px;
  color: #333;
  border-bottom: 1px solid #ddd;
}

.filter-sub-menu.style1 ul li.has-sub li a:hover {
  color: #8a8f6a;
}

.filter-sub-menu.style1 ul li.has-sub li:last-child a {
  border: none;
  margin-bottom: 10px;
}

.filter-sub-menu.style1 ul li ul {
  display: none;
  margin-top: 17px;
}

.explore__form-checkbox-list.full-filter {
  width: 950px;
  position: absolute;
  top: 35px;
  left: -278%;
  background: #fff;
  z-index: 999;
  padding: 35px 30px;
  box-shadow: 0px 18px 26px rgba(221, 221, 221, 0.35);
}

#amount {
  width: 100%;
  border: none;
  font-size: 18px;
}

.nav.item-filter-list li {
  display: inline-block;
  line-height: 0;
  margin-right: 10px;
}

.item-filter-list li a {
  transition: 0.3s;
}

.item-filter-list li a:hover,
.item-filter-list li a.active {
  color: #0cbcb7;
  border: 1px solid #0cbcb7;
  border-radius: 3px;
}

.item-element p {
  line-height: 38px;
  font-size: 15px;
}

.item-element span {
  color: #0cbcb7;
  font-weight: 500;
}

.item-filter-list li a {
  color: #333333;
  font-size: 25px;
  line-height: 0;
  display: inline-block;
  border: 1px solid #ddd;
  border-radius: 3px;
  padding: 5px 10px;
}

.item-filter-list li a i {
  display: inline-block;
}

.filter-input {
  border: 1px solid #ddd;
  margin-top: 25px;
  border-radius: 3px;
  width: 100%;
  padding: 10px 20px;
  height: 50px;
}

.filter-input:last-child {
  padding: 5px 20px;
}

.filter-input.nice-select::after {
  content: "\f35f";
  font-family: "Ionicons";
  display: block;
  margin-top: 5px;
  pointer-events: none;
  position: absolute;
  right: 12px;
  top: 5%;
  font-size: 20px;
  color: #999;
  -webkit-transition: all 0.15s ease-in-out;
  transition: all 0.15s ease-in-out;
}

.filter-input.nice-select {
  font-size: 15px;
  font-weight: 400;
}

.filter-title {
  position: relative;
}

.filter-title span {
  position: absolute;
}

.filter-title p {
  font-size: 18px;
  color: #000;
}

.filter-checkbox input[type="checkbox"] {
  display: none;
}

.filter-checkbox.one-in-row label {
  margin-top: 9px;
  width: 100%;
}

.filter-checkbox label {
  display: inline-block;
  cursor: pointer;
  position: relative;
  padding-left: 28px;
  margin-right: 20px;
  margin-bottom: 10px;
  line-height: 24px;
  font-size: 14px;
}

.half-filter .filter-checkbox label {
  display: block;
}

.half-filter .filter-checkbox p {
  padding: 10px 0;
  color: #000;
  font-size: 16px;
  font-weight: 500;
}

.filter-checkbox label::before {
  content: "";
  display: inline-block;
  width: 18px;
  height: 18px;
  margin-right: 10px;
  position: absolute;
  left: 0;
  top: 2px;
  background-color: #fff;
  border: 1px solid #d0d0d0;
  border-radius: 3px;
  box-sizing: border-box;
  transition: all 0.25s;
  font-family: "Ionicons";
  font-size: 25px;
  color: #fff;
  text-align: center;
  line-height: 21px;
}

.filter-checkbox input[type="checkbox"]:checked + label::before {
  content: "\f3fd";
  background-color: #0cbcb7;
  border: none;
}

.full-filter .filter-checkbox label {
  display: block;
}

.list-ratings {
  margin-left: 25px;
  line-height: 1.2;
}

.full-filter #amount {
  font-size: 17px;
  margin-left: 5px;
  font-weight: 500;
}

.full-filter .filter-title p {
  font-size: 17px;
  font-weight: 500;
}

.explore__form-checkbox-list.full-filter .filter-checkbox p {
  font-size: 17px;
  color: #000;
  font-weight: 500;
  margin-bottom: 15px;
}

.filter .filter-sub-menu.style1 ul li.has-sub > a {
  padding: 8px 0;
  border-bottom: 1px solid #ddd;
}

.explore__form-checkbox-list.full-filter {
  left: -342%;
  z-index: 2000;
}

.full-filter .price-range.ui-widget.ui-widget-content {
  border: none;
  margin-left: 10px;
}

.hero__form.filter {
  background: #f9f9f9;
}

.hero__form.filter button:hover {
  background: #fff;
  color: #000;
  border: 1px solid #ddd;
}

.item-wrapper .product-grid .trending-place-item {
  box-shadow: 0 0 17px 0 rgba(0, 0, 0, 0.1);
  margin-bottom: 30px;
}

.item-wrapper .product-list .trending-place-item {
  box-shadow: 0 0 17px 0 rgba(0, 0, 0, 0.1);
  margin-bottom: 50px;
}

.item-wrapper .product-list .trending-place-item .trending-title-box {
  padding: 30px;
}

.item-wrapper .product-list .trending-place-item .trending-img img {
  box-shadow: 0 0 17px 0 rgba(0, 0, 0, 0.08);
  border-top-right-radius: 0 !important;
  border-bottom-left-radius: 3px !important;
  height: 100%;
}

#search-filter::placeholder,
#location-filter::placeholder {
  color: #888;
  font-size: 15px;
  font-weight: 400;
}

/*--------filter with sticky map------*/

.explore__map-side {
  width: 43%;
  position: fixed;
  top: 70px;
  height: 100%;
  right: 0;
  padding-left: 32px;
}

.explore__map-side #map-container {
  margin-bottom: 0;
  height: 100%;
}

.explore__map-side #map_right_listing {
  width: 100%;
  height: 100%;
  z-index: 10000;
}

/* .contact-map {
    overflow: hidden;
}

.contact-map #map {
    width: 100%;
    height: 500px;
} */

.explore__map-side.v2 {
  left: -2.5%;
}

/*-------------------
        Style2
--------------------*/

.contact-map.v2 {
  position: relative;
  overflow: visible;
}

.filter-wrapper.style1.v2 {
  position: absolute;
  bottom: 60px;
  width: 100%;
}

.contact-map.v2 .filter-wrapper.style1.v2 .hero__form.filter {
  box-shadow: 0 18px 12px 0 rgba(0, 0, 0, 0.12);
}

.filter-wrapper.style1.v2 .hero__form.filter {
  background: #fff;
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.12);
}

.contact-map.v2 #map_right_listing {
  height: 600px;
}

/*---------------------------------------
            Single Listing Page
---------------------------------------*/

.listing-details-wrapper {
  position: relative;
}

.listing-details-wrapper .video-container {
  height: 550px;
}

.list-details-title.v1 {
  transform: translateY(350px);
  width: 100%;
}

.list-details-title.v2 {
  margin-top: 18px;
}

.list-details-title.v3 {
  z-index: 1001;
  transform: translateY(350px);
}

.list-details-btn .share-btn {
  margin-left: 15px;
}

.single-listing-title h2 {
  margin-bottom: 5px;
  display: inline-block;
}

.single-listing-title h2 i,
.single-listing-title p i {
  color: #0bf389;
}

.single-listing-title h2 i {
  margin-left: 7px;
}

.list-details-title.v1 .single-listing-title h2,
.list-details-title.v3 .single-listing-title h2,
.list-details-title.v1 .single-listing-title p,
.list-details-title.v3 .single-listing-title p,
.list-details-title.v1 .list-ratings a,
.list-details-title.v3 .list-ratings a {
  color: #fff;
}

.list-details-title.v2 .single-listing-title h2,
.list-details-title.v2 .single-listing-title p,
.list-details-title.v2 .list-ratings a {
  color: #333;
}

.list-ratings span {
  color: #ffc600;
  font-size: 20px;
}

.list-details-btn {
  margin-top: 40px;
}

.list-ratings a {
  margin-left: 8px;
  font-weight: 500;
  color: #666;
  font-size: 13px;
}

.share-btn:hover .social-share li {
  opacity: 1;
}

.share-btn {
  position: relative;
}

.social-share {
  position: absolute;
  bottom: -40px;
  right: 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.iti__country-list {
  height: 400px;
  overflow: scroll;
}

.iti__selected-flag {
  position: absolute;
  display: flex;
  align-items: center;
  padding: 0.375rem 0.75rem;
  font-size: 0.875rem;
  line-height: 1.6;
}

.social-share li {
  display: inline-block;
  text-align: center;
  font-size: 15px;
  opacity: 0;
  transition: 0.5s;
  margin-right: 5px;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  border: 1px solid #000;
}

.social-share li a {
  margin-left: 0;
}

.social-share li a i {
  color: #fff;
}

/* .social-share li:nth-child(2) {
    transition-delay: 0.2s;
}

.social-share li:nth-child(3) {
    transition-delay: 0.4s;
} */

.social-share li a {
  margin-right: 0;
}

.social-btn li {
  display: inline-block;
  border-radius: 50%;
  margin: 0 5px;
}

.social-btn li a {
  display: inline-block;
  width: 35px;
  height: 35px;
}

.social-btn li a:hover {
  opacity: 0.8;
}

.social-btn li a i {
  color: #fff;
  font-size: 18px;
  line-height: 1.9;
}

.list_menu {
  width: 100%;
  background: #fff;
  z-index: 999;
  transition: 0.4s;
  border-bottom: 1px solid #ddd;
}

.list-details-tab.fixed_nav li {
  display: inline-block;
}

.list-details-tab.fixed_nav {
  margin: 0 auto;
}

.list-details-tab .nav-link {
  border-top: none;
  border-left: none;
  border-right: none;
  font-size: 16px;
  color: #000;
  padding: 10px 0;
}

.list-details-tab .nav-link {
  position: relative;
  text-align: center;
}

.list-details-tab .nav-item {
  margin-bottom: 0 !important;
  position: relative;
  padding: 25px 0;
}

.list-details-tab .nav-item:after {
  position: absolute;
  bottom: -0.04rem;
  left: 0;
  width: 0;
  height: 1px;
  content: "";
  background: #0cbcb7;
  visibility: hidden;
  opacity: 0;
  transition: 0.5s;
  border-color: transparent;
}

.list-details-tab .nav-item.active:after,
.list-details-tab .nav-item:hover:after {
  visibility: visible;
  opacity: 1;
  width: 100%;
}

.list-details-tab .nav-item.active a {
  color: #0cbcb7;
}

.list-details-tab .nav-item a {
  font-size: 16px;
}

.list-details-section {
  padding-top: 50px;
}

.list-details-section:first-child {
  padding-top: 30px !important;
}

#overview h4 {
  margin-bottom: 10px;
}

.list-details-btn div {
  display: inline-block;
}

.single-listing-title p i {
  margin-right: 5px;
}

.tab-pane.active {
  animation: slide-up 0.4s ease-out;
}

@keyframes slide-up {
  0% {
    opacity: 0;
    transform: translateY(20%);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.review_wrap .customer-review {
  display: inline-block;
  margin-bottom: 0;
}

.review_wrap .customer-review i {
  color: #ffc600;
}

.book-btn a {
  display: block;
  padding: 13px 0;
  background: #0cbcb7;
  color: #fff !important;
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 26px;
  border-radius: 3px;
  cursor: pointer;
}

.similar-title-box {
  margin-top: 10px;
}

.similar-title-box h5 {
  letter-spacing: 0;
}

.similar-item img {
  border-radius: 5px;
}

.similar-listing {
  position: relative;
  margin-top: 60px;
}

.similar-title-box h5 {
  display: inline-block;
  margin-bottom: 0;
}

.similar-title-box p {
  display: inline-block;
  float: right;
  font-size: 13px;
  line-height: 1.7;
}

.similar-title-box p span {
  line-height: 1.7;
}

.similar-item .customer-review {
  margin-bottom: 0;
  margin-top: 10px;
}

.similar-item .customer-review p {
  font-size: 14px;
}

.similar-item .customer-review p i {
  margin-right: 5px;
  color: #0cbcb7;
}

.similar-item .customer-review .product-rating li {
  line-height: 1.2;
}

.similar-next,
.similar-prev {
  position: absolute;
  top: 41%;
  z-index: 999;
  opacity: 0;
  transition: 0.5s;
}

.similar-next {
  right: 1%;
}

.similar-prev {
  left: 1%;
}

.similar-listing:hover .similar-next,
.similar-listing:hover .popular-next,
.similar-listing:hover .similar-prev,
.similar-listing:hover .popular-prev {
  opacity: 1;
}

/*-------Single listing video Header------------*/

.listing-details-wrapper .video-container {
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 550px;
  z-index: 0;
}

.listing-details-wrapper .video-container .overlay {
  z-index: 1001;
}

.listing-details-wrapper .video-content {
  z-index: 1001;
}

/*--------------Single Listing sidebar-------------*/

.sidebar-title h2 {
  color: #2f333a;
  display: block;
  font-size: 18px;
  font-weight: 500;
  line-height: 25px;
  padding: 0 0 10px;
  position: relative;
}

.listing-sidebar {
  padding-left: 15px;
}

.sidebar-widget {
  /* border: 1px solid #ddd; */
  margin-bottom: 30px;
  border-radius: 3px;
}

.sidebar-widget-booking {
  /* border: 1px solid #ddd; */
  margin-bottom: 30px;
  border-radius: 3px;
}

/*
#map {
    width: 100%;
    height: 250px;
}
*/

.sidebar-widget.info {
  /* padding: 20px 20px 0 20px; */
}

.commute-details {
  display: flex;
  justify-content: space-between;
}

/* .commute-details i {
  font-size: 35px;
} */

.commute-details h3 {
  line-height: 1.5;
  font-size: 12px;
  text-overflow: ellipsis;
  overflow: hidden;
  font-weight: 400;
  color: #777;
  margin: 0;
}
.commute-details p {
  line-height: 1.5;
  font-size: 12px;
  text-overflow: ellipsis;
  overflow: hidden;
}

.display_inline {
  display: inline;
}

.commute-details-item {
  display: flex;
  flex: 1;
  max-width: 49%;
  gap: 10px;
  align-items: flex-start;
}

.flex-item {
  display: flex;
  justify-content: space-between;
}

.commute-label {
  align-self: baseline;
  padding-top: 2px;
  text-align: center;
}

.lh-0 {
  line-height: inherit !important;
}

.commute-value {
  overflow: hidden;
  white-space: nowrap;
}

.commute-label p {
  line-height: 1;
  font-size: 10px;
  text-align: center;
}

.sidebar-widget-booking.info {
  padding: 20px 20px 0 20px;
}

.sidebar-widget.info .filter-input.nice-select,
.sidebar-widget.info #datepicker-to input,
.sidebar-widget.info #datepicker-from input {
  font-size: 14px;
  font-weight: 300;
}

.sidebar-widget.info h3 {
  border-bottom: 1px solid #ddd;
  padding-bottom: 12px;
}

.sidebar-widget.info h3 i {
  margin-right: 8px;
}

.sidebar-widget-booking.info h3 i {
  margin-right: 8px;
}

.amt-total span {
  color: #0cbcb7;
  font-weight: 600;
  font-size: 18px;
}

.sidebar-widget.listing-tags,
.sidebar-widget.ad-box {
  border: none;
}

.sidebar-widget.ad-box img {
  border-radius: 3px;
}

.address {
  color: #737882;
  padding-left: 25px;
  padding-right: 25px;
  margin-top: 20px;
}

.address:last-child {
  padding-bottom: 25px;
  margin-bottom: 0;
}

.address > span {
  font-size: 18px;
  float: left;
  color: #0cbcb7;
}

.address p {
  margin: 0 0 0 34px;
}

.follow {
  background: #fff;
}

.follow-img {
  text-align: center;
  padding: 25px 25px 10px 25px;
  color: #3e434b;
}

.follow-img img {
  border-radius: 50%;
  max-width: 120px;
}

.follow-img h6 {
  margin: 11px 0 0;
}

.follow ul {
  margin: 0;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  text-align: center;
}

.follow ul li {
  list-style: none;
  padding: 13px 0;
  width: 33%;
}

.follow ul li h6 {
  margin: 0;
}

.social-counts span {
  font-size: 14px;
  color: #737882;
}

.listing-detail-map.v1 {
  height: 500px;
  margin-bottom: -100px;
}

.listing-detail-map.v2 {
  height: 250px;
}

.listing-detail-map.v3 {
  height: 450px;
}

#sidebar {
  background: #f5f5f5;
  padding: 50px 30px;
}

.booking .title {
  text-align: center;
  text-transform: capitalize;
  margin-bottom: 30px;
}

.booking #datepicker,
.booking #from,
.booking #to {
  color: #7a7a7a;
  width: 100%;
  padding: 10px 20px;
  border: transparent;
  border-radius: 3px;
  margin-bottom: 15px;
}

.booking #inputGroupSelect01 {
  color: #7a7a7a;
  padding: 10px 20px;
  border: transparent;
  border-radius: 3px;
  margin-bottom: 15px;
}

.booking label {
  font-size: 16px;
  text-transform: capitalize;
  font-weight: 700;
}

.booking .submit-btn {
  background: #0cbcb7;
  color: #ffff;
  width: 100%;
}

.form-group.time-slots .filter-input span {
  font-size: 13px;
}

/*----------Overview------------*/

.list-single-facts {
  background: #fff;
  margin-top: 100px;
}

.inline-facts h6 span {
  font-weight: 600;
  color: #0cbcb7;
}

.list-single-facts ul {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background: #f9f9f9;
  columns: 2;
}

.list-single-facts .inline-facts {
  padding: 20px;
}

.list-single-facts .inline-facts i {
  font-size: 34px;
  color: #0cbcb7;
}

ul.listing-features {
  columns: 3;
}

.listing-features li {
  text-align: left;
  margin-bottom: 5px;
  color: #888da0;
  font-weight: 400;
  position: relative;
  padding-left: 30px;
  font-size: 15px;
}

.listing-features li i {
  position: absolute;
  left: 0;
  top: 7px;
  font-size: 18px;
  color: #0cbcb7;
}

.list-single-facts .inline-facts h6 {
  color: #999;
  font-size: 14px;
  margin-top: 10px;
  letter-spacing: 0px;
  font-weight: 400;
}

/*----------Gallery-----------*/

.carousel-indicators {
  position: absolute;
  bottom: -10px;
  left: -3px;
  right: 0;
  z-index: 15;
  display: flex;
  justify-content: center;
  padding-left: 0;
  margin-right: 0;
  margin-left: 0;
  list-style: none;
  width: 100%;
}

.carousel-indicators li {
  width: 120px;
  text-indent: -999px;
  cursor: pointer;
  background-color: rgba(255, 255, 255, 0.5);
}

.carousel-indicators li:last-child {
  margin-right: -3px;
}

.carousel-control-prev span,
.carousel-control-next span {
  width: 30px;
  height: 30px;
  background: #0cbcb7;
  border-radius: 50%;
  line-height: 31px;
  font-size: 15px;
  opacity: 1;
  position: absolute;
}

.carousel-control-prev span {
  left: 15px;
  transition: 0.5s;
}

.mat-slide-toggle.mat-checked .mat-slide-toggle-bar {
  background-color: black;
}

.mat-slide-toggle.mat-checked .mat-slide-toggle-thumb {
  background-color: black;
}

.carousel-control-next span {
  right: 15px;
  transition: 0.5s;
}

/*--------------Features & Pricing--------------*/

.room-type-item {
  position: relative;
  margin: 0 0 25px 0;
  border-bottom: 1px solid #dedede;
  transition: all 0.35s ease-in-out;
}

.room-type-item:last-child {
  border-bottom: none;
  padding-bottom: 0;
  margin-bottom: 0;
}

.room-type-item img {
  border-radius: 5px;
}

.feature-left p span {
  font-weight: 500;
  color: #0cbcb7;
}

.room-type-item .feature-left h3 {
  font-size: 16px;
  font-weight: 500;
  line-height: 16px;
  margin-bottom: 8px;
}

.feature-left.float-left p {
  line-height: 1.3;
}

.room-type-item .feature-left p {
  margin: 0 0 7px;
  line-height: 19px;
}

.room-type-item .feature-right {
  text-align: right;
}

.feature-right button.btn.v2 {
  margin-top: 70px;
}

.amt-title h4 {
  margin-bottom: 5px;
}

.price-amt {
  color: #0cbcb7;
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 5px;
  line-height: 16px;
}

.room-type-item .feature-right .price-amt {
  display: block;
}

.facilities-list {
  margin-top: 85px;
  list-style: none;
}
.call_icon {
  position: fixed;
  width: 45px;
  height: 45px;
  bottom: 131px;
  right: 12px;
  background-color: #276ad6;
  color: #fff;
  border-radius: 50px;
  text-align: center;
  font-size: 22px;
  box-shadow: 0 0 3px #a9a9a9;
  z-index: 100;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 500ms;
}
.call_icon_tooltip {
  width: 150px;
  background: #276ad6;
  color: #fff;
  font-size: 16px;
  padding: 4px 4px;
  border-radius: 2px;
  left: auto;
  position: absolute;
  display: flex;
  align-items: center;
  right: 30px;
  white-space: nowrap;
  pointer-events: none;
  justify-content: center;
  opacity: 0;
  visibility: hidden;
  transition: all 500ms;
}

.call_icon_tooltip::before {
  position: absolute;
  content: "";
  right: -3px;
  border-right: 4px solid #276ad6;
  border-bottom: 4px solid #276ad6;
  background: #276ad6;
  width: 9px;
  height: 9px;
  transform: rotate(45deg);
  z-index: -1;
}

.call_icon:hover .call_icon_tooltip {
  opacity: 1;
  visibility: visible;
  right: 55px;
}

.call_icon i {
  color: #fff;
}

.float {
  position: fixed;
  width: 45px;
  height: 45px;
  bottom: 77px;
  right: 12px;
  background-color: #25d366;
  color: #fff;
  border-radius: 50px;
  text-align: center;
  font-size: 25px;
  box-shadow: 0 0 3px #a9a9a9;
  z-index: 100;
  display: flex;
  align-items: center;
  justify-content: center;
}

.zsiq_theme1.zsiq_floatmain {
  width: 45px;
  max-height: 58px;
  height: 60px !important;
}
.zsiq_theme1 .zsiq_flt_rel {
  position: relative;
  padding: 0;
  width: 45px;
  border-radius: 60px;
  height: 45px;
  font-size: 14px;
  background-color: #328a59;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 0;
  box-shadow: 0 0 3px #a9a9a9;
}
.zsiq_theme1 .zsiq_user {
  display: flex;
  align-items: center;
  justify-content: center;
}
.zsiq_theme1 .siqicon:before {
  margin: auto;
  z-index: 1111;
  font-size: 20px;
  line-height: 0;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
}

.my-float {
  margin-top: 16px;
}

.facilities-list li {
  float: left;
  margin-right: 14px;
  cursor: pointer;
  position: relative;
}

.facilities-list li i {
  color: #0cbcb7;
  font-size: 18px;
  opacity: 0.6;
}

.facilities-list li:hover span {
  visibility: visible;
  opacity: 1;
}

.facilities-list li:hover i {
  opacity: 1;
}

.facilities-list li span {
  position: absolute;
  opacity: 0;
  left: -20px;
  top: -34px;
  height: 26px;
  line-height: 26px;
  min-width: 90px;
  color: #fff;
  font-size: 11px;
  visibility: hidden;
  opacity: 0;
  border-radius: 4px;
  background: #0cbcb7;
  transition: 0.3s;
  text-align: center;
}

/*-----------Reviews------------*/

.review-box {
  border: 1px solid #ddd;
  border-radius: 5px;
  padding: 0 30px;
}

.customer-review_wrap {
  border-bottom: 1px solid #ddd;
  display: inline-block;
  width: 100%;
  padding: 30px 0;
}

.review_wrap li li {
  margin-left: 135px;
}

.review_wrap > li:last-child .customer-review_wrap {
  border-bottom: none;
}

.reviewer-img {
  float: left;
}

.reviewer-img img {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  margin-bottom: 10px;
}

.reviewer-img p {
  color: #3e434b;
  margin: 4px 0 0;
  text-align: center;
  font-size: 16px;
  line-height: 1.2;
}

#reviews .review-box .review_wrap .customer-review_wrap .review-img img {
  margin-top: 10px !important;
}

.reviewer-img span {
  font-size: 14px;
  color: #737882;
  text-align: center;
}

/*-----------reviews-------*/

.review-item {
  border: 1px solid #ebebeb;
  background: #fff;
  border-radius: 3px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.03);
  padding: 30px 30px 60px 30px;
  margin-bottom: 30px;
}

.review-item__post-image {
  margin-right: 1.25rem;
}

.review-item__post {
  display: inline-flex;
}

.review-header {
  margin-bottom: 15px;
}

.review-item__image {
  width: 120px;
  border-radius: 5px;
}

.review-item__post-title {
  font-size: 18px;
  margin-bottom: 0;
}

.review-item__post-title a {
  color: #363636;
}

.review-item__post-location {
  font-size: 14px;
  color: #999;
  padding-bottom: 0.375rem;
  margin: 10px 0;
  line-height: 1rem;
}

.review-item__post-reviews {
  font-size: 0.8125rem;
  color: #999999;
  line-height: 1rem;
  font-weight: 300;
}

.review-item__post-reviews i {
  color: #cccccc;
}

.review-item__date {
  display: inline-block;
  color: #999999;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.25rem;
  margin: 0 0 1rem;
}

.review-item__title {
  color: #363636;
  font-size: 0.9375rem;
  font-weight: 600;
  line-height: 1.25rem;
  margin: 0;
}

.review-item__description {
  color: #666666;
  margin: 10px 0 20px;
}

.review-item__content {
  margin: 15px 0;
}

.review-item__helpful {
  padding: 0.25rem 0.5rem;
  padding-right: 0.5rem;
  padding-right: 2.5rem;
  color: #999999;
  font-size: 0.875rem;
  font-weight: 200;
  border: 1px solid #ebebeb;
  position: relative;
  border-radius: 0.125rem;
}

.review-item__helpful i {
  font-size: 1rem;
  float: left;
  margin-right: 0.25rem;
  margin-top: 0.125rem;
  transition: all 0.2s ease-in-out;
}

.review-item__helpful span {
  position: absolute;
  right: 0;
  top: 0;
  padding: 0.25rem 0;
  text-align: center;
  color: #666666;
  font-size: 0.875rem;
  display: inline-block;
  width: 2rem;
  border-left: 1px solid #ebebeb;
  transition: all 0.2s ease-in-out;
}

.review-item__reply {
  float: right;
  padding: 0.25rem 0.5rem;
  color: #999999;
  font-size: 0.875rem;
  font-weight: 200;
  border: 1px solid #ebebeb;
  border-radius: 1.25rem;
}

.customer-content-wrap {
  margin: 0 0 0 110px;
}

.customer-content h6 {
  color: #3e434b;
  margin-bottom: 0;
}

.customer-review span {
  width: 10px;
  height: 10px;
  background: #46cd38;
  border-radius: 50%;
  display: block;
  float: left;
  margin: 0 5px 0 0;
}

.customer-content p {
  color: #737882;
  display: inline-block;
  line-height: 0.8;
}

.review-item__rating {
  background: #46cd38;
  padding: 8px 14px;
  font-size: 18px;
  color: #fff;
  border-radius: 3px;
}

.customer-rating {
  background: #46cd38;
  padding: 8px 14px;
  float: right;
  font-size: 18px;
  color: #fff;
  border-radius: 3px;
}

.customer-text {
  color: #737882;
  margin: 15px 0 0;
  line-height: 1.6;
}

.customer-text {
  color: #737882;
  margin: 15px 0 0;
  line-height: 1.6;
  font-size: 16px;
  font-weight: 300;
}

.customer-content-wrap span {
  color: #737882;
  display: inline-block;
}

.customer-content-wrap a {
  border: 1px solid #ccc;
  color: #737882;
  font-size: 14px;
  border-radius: 3px;
  padding: 5px 10px;
}

.review-img img {
  border-radius: 5px;
  margin-right: 5px;
  max-width: 120px;
}

a.rate-review {
  border: 1px solid #e0e0e0;
  border-radius: 50px;
  font-size: 13px;
  color: #666;
  font-weight: 500;
  padding: 9px 20px;
  display: inline-block;
  transition: 0.3s;
  line-height: 1.2;
}

a.rate-review span {
  border-left: 1px solid #e0e0e0;
  line-height: 1.2;
  padding-left: 10px;
  margin-left: 5px;
  transition: 0.3s;
  color: #666;
}

.like-btn a i {
  margin-right: 10px;
}

a.rate-review i {
  margin-right: 10px;
}

a.rate-review:hover {
  border-color: #0cbcb7;
  color: #0cbcb7;
}

a.rate-review:hover i {
  color: #0cbcb7;
}

.contact-form {
  padding: 1.875rem;
  background: #fafafa;
  margin-bottom: 1.875rem;
  border: 1px solid #ebebeb;
  border-radius: 2px;
}

.contact-form__rate {
  margin-bottom: 0;
}

.contact-form__rate-bx {
  display: inline-block;
  margin-right: 0.5rem;
  font-size: 18px;
  color: #cccccc;
}

.contact-form__rate-bx i {
  cursor: pointer;
}

.contact-form__rate-bx-show {
  display: inline-block;
  color: #999999;
  font-weight: 300;
  font-size: 1rem;
  margin-bottom: 1.5rem;
}

.contact-form__upload-btn {
  text-align: right;
  position: relative;
}

.contact-form__input-file {
  display: inline-block;
  width: 136px;
  opacity: 0;
  position: relative;
  z-index: 2;
  cursor: pointer;
}

.contact-form__upload-btn span {
  position: absolute;
  top: 0;
  right: 0;
  padding: 9px 20px;
  color: #666;
  font-size: 13px;
  font-weight: 200;
  border: 1px solid #cccccc;
  border-radius: 1.25rem;
  transition: all 0.2s ease-in-out;
}

.contact-form__upload-btn span i {
  font-size: 1rem;
  float: left;
  margin-right: 0.25rem;
  margin-top: 0.125rem;
}

.contact-form__rate-bx i.active,
.contact-form__rate-bx i.selected {
  color: #ffc600;
}

.contact-form__input-text,
.contact-form__textarea {
  display: block;
  width: 100%;
  padding: 0.625rem 1.25rem;
  color: #000;
  font-size: 0.9375rem;
  font-weight: 300;
  background: #ffffff;
  outline: none;
  border: 1px solid #dddddd;
  margin: 0 0 1.875rem;
  border-radius: 2px;
  box-shadow: 0 3px 8px rgba(0, 0, 0, 0.02);
  transition: all 0.2s ease-in-out;
}

/*------------booking---------------*/

.booking-dialog {
  max-width: 700px;
}

#datepicker-from span,
#datepicker-to span {
  position: absolute;
  top: 15px;
  right: 10px;
}

#datepicker-from span i,
#datepicker-to span i {
  font-size: 16px;
}

#datepicker-to input,
#datepicker-from input {
  font-size: 15px;
  padding: 24px 20px;
  border-radius: 3px;
}

.book-amount button.quantity-left-minus,
.book-amount button.quantity-right-plus {
  border-radius: 3px;
  background: transparent;
  border-top: 1px solid #ddd;
  border-left: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
  border-right: 1px solid #ddd;
  padding: 13px 15px;
}

.book-amount input {
  border-left: 0;
  border-right: 0;
  text-align: center;
  padding: 25px 0;
}

.booking-dialog label {
  text-align: left;
  display: inherit;
  font-size: 17px;
  color: #000;
  font-weight: 400;
}

.booking-dialog .filter-input {
  margin-top: 0;
}

.booking-dialog .form-group {
  margin-bottom: 0;
}

.book-btn.col-md-12 {
  border-top: 1px solid #ddd;
}

.book-btn .btn.v3 {
  margin-top: 22px;
  margin-bottom: 0;
}

.booking-dialog .modal-content {
  padding: 0px 10px 10px 10px;
}

.time-slots .nice-select .option {
  border-bottom: 1px solid #ddd;
  /*    line-height: 2;*/
}

.time-slots .filter-input.nice-select {
  font-size: 15px;
  font-weight: 400;
}

/*------------Coupon---------------*/

.coupon-top #clock p {
  display: inline-grid;
  color: #fff;
  font-size: 17px;
  margin-right: 10px;
}

.coupon-top #clock p span {
  font-size: 20px;
  font-weight: 600;
}

.coupon-widget {
  border-radius: 4px;
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  color: #fff;
  transition: 0.3s;
  display: block;
}

.coupon-widget a.coupon-top {
  padding: 20px;
  position: relative;
  z-index: 10;
  text-align: center;
  cursor: default;
  display: block;
  cursor: pointer;
  background: rgba(255, 255, 255, 0.07);
}

.coupon-link-icon {
  position: absolute;
  top: 15px;
  right: 18px;
  font-size: 16px;
}

.coupon-widget a.coupon-top h3 {
  font-size: 18px;
  line-height: 22px;
  text-align: center;
  margin: 0;
  font-weight: 400;
  color: #fff;
}

.coupon-widget .coupon-bottom {
  padding-bottom: 20px;
  margin: 0 0;
  position: relative;
  z-index: 10;
  text-align: center;
  border-radius: 0 0 4px 4px;
}

#clock {
  margin-top: 20px;
}

.coupon-scissors-icon {
  position: absolute;
  top: -15px;
  left: 35px;
  z-index: 100;
  font-size: 20px;
}

.coupon-bottom p {
  display: inline-block;
}

.coupon-code {
  font-weight: 500;
  color: #fff;
  font-size: 18px;
  letter-spacing: 2px;
  display: inline-block;
  padding: 7px 20px;
  border-radius: 2px;
  position: relative;
  z-index: 11;
  text-transform: uppercase;
  transition: 0.4s;
  border: 2px dashed #ddd;
}

.coupon-code-wrap input {
  font-size: 14px;
  padding: 10px 12px;
  border-radius: 4px;
  border: 1px solid #ddd;
}

.coupon_code {
  background: #0cbcb7;
  color: #fff;
  border: 1px solid transparent !important;
  cursor: pointer;
  transition: 0.5s;
  margin-left: 10px;
}

.coupon_code:hover {
  background: transparent;
  border: 1px solid #ddd !important;
  color: #999;
}

.coupon-widget .coupon-bottom::after {
  left: initial;
  right: 0;
  transform: translate(50%, -50%);
}

.countdown-deals {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.daily-deals-wrap.v1 .cdown {
  padding: 5px 0;
  width: 50px;
}

.daily-deals-wrap.v1 {
  margin-top: 15px;
}

.daily-deals-wrap.v1.countdown-deals .cdown {
  color: #fff;
}

.daily-deals-wrap.v1 .cdown span {
  font-size: 25px;
  display: inline-block;
  line-height: 31px;
  color: #fff;
}

.daily-deals-wrap.v1 .countdown-deals .cdown p {
  font-size: 12px;
  line-height: 12px;
  color: #fff;
}

/*-----------Contact map------------*/

.contact-map {
  margin-top: 70px;
}

.contact-map.v1 {
  margin-top: -10px;
  margin-bottom: 80px;
}

.listing-detail-map.v2 {
  z-index: 9;
}

/*-------coupon code----------*/

.banner-wdget-content {
  padding: 90px 30px 70px;
  z-index: 10;
}

.banner-wdget-content h4 {
  font-weight: 500;
  color: #fff;
  font-size: 18px;
}

.banner-wdget-content h4 span {
  color: #5ecfb1;
  font-weight: 400;
}

.banner-wdget-content .countdown {
  margin-top: 15px;
}

.banner-wdget-content .countdown-item {
  width: 25%;
  float: left;
  color: #fff;
}

/*------Business Hours----*/

.business-title {
  border-bottom: 1px solid #ddd;
  padding: 25px;
}

.business-title h6 {
  display: inline-block;
  margin-bottom: 0;
}

.business-title h6 i {
  color: #0cbcb7;
}

.business-title span {
  background: #0bf389;
  padding: 5px 10px;
  font-size: 12px;
  border-radius: 50px;
  color: #fff;
  font-weight: 600;
}

ul.business-hours {
  margin: 0;
  padding: 25px;
  list-style-type: none;
}

ul.business-hours li {
  display: flex;
  align-items: center;
  justify-content: space-between;
  line-height: 1.2;
}

ul.business-hours li span {
  font-size: 0.93333rem;
}

.business-hours .trend-closed,
.business-hours .business-open {
  margin: 0;
}

.business-hours .business-open:last-child {
  padding-bottom: 0;
}

/*---------Tags-----------*/

.list-tags li {
  margin-right: 5px;
  display: inline-block;
  margin-bottom: 5px;
}

/*---------------------------------
        User Login Page
---------------------------------*/

.login-wrapper {
  padding: 30px 50px 45px 50px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  background: #fff;
  display: inline-block;
}

.user-login-section {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.user-login-section .nav-tabs {
  border-bottom: none;
}

.user-login-section .nav-tabs .nav-link.active {
  color: #0cbcb7;
}

.user-login-section .nav-tabs .nav-link {
  border: none;
  font-size: 18px;
  color: #333;
  text-transform: uppercase;
  font-weight: 500;
}

.single-banner-item.style2 .nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  background-color: transparent;
}

#login-form .form-control:focus,
#register-form .form-control:focus {
  outline: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
}

#login-form input,
#register-form input {
  padding: 22px;
  border-radius: 5px;
}

/*---------------------------------------
                Add Listing Page
---------------------------------------*/

.add-listing__input-file-box {
  position: relative;
  margin-bottom: 1.875rem;
}

.add-listing__input-file {
  width: 100%;
  height: 150px;
  position: relative;
  z-index: 2;
  cursor: pointer;
  opacity: 0;
}

.add-listing__input-file-wrap {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 1px dashed #999;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.add-listing__input-file-wrap i {
  font-size: 2.5rem;
  color: #ccc;
}

.add-listing__input-file-wrap p {
  color: #ccc;
  margin-bottom: 0;
}

label.fix_spacing {
  font-size: 16px;
  line-height: 50px;
  margin-bottom: 0;
  font-weight: 400 !important;
}

.add_list_content .filter-input {
  margin-top: 0 !important;
  color: #999 !important;
  font-weight: 300;
  font-size: 15px;
}

.add_list_content .filter-input:focus {
  outline-color: transparent;
}

.add_list_content h4 {
  margin-bottom: 30px;
}

.add_list_content h4 i {
  margin-right: 5px;
  color: #0cbcb7;
}

.add_list_content label {
  font-weight: 500;
  color: #000;
  text-transform: capitalize;
}

.add_list_content #list_info {
  border-radius: 5px;
}

.tab-content.add_list_content .form-group {
  margin-bottom: 30px;
}

.nice-select.filter-input ul li {
  font-size: 15px;
}

.res-box label a {
  color: #0cbcb7;
}

.res-box label {
  margin-left: 5px;
}

#social_network button:last-child {
  margin-left: 10px;
}

/*---------------------------------------------
                Booking Information
--------------------------------------------*/

.boxed-widget {
  border-radius: 3px;
  padding: 32px;
  transform: translate3d(0, 0, 0);
  z-index: 90;
  position: relative;
  border: 1px solid #ddd;
}

.boxed-widget.summary h3 {
  padding: 0 0 25px;
}

.boxed-widget h3 {
  font-size: 20px;
  padding: 0 0 25px;
  margin: 0 0 25px;
  display: block;
  border-bottom: 1px solid #e8e8e8;
}

.boxed-widget h3 i {
  margin-right: 10px;
  color: #0cbcb7;
}

.boxed-widget ul {
  list-style: none;
  padding: 0;
  margin: 0;
  font-size: 14px;
}

.boxed-widget ul li {
  width: 100%;
  color: #000;
  margin: 10px 0;
  transition: 0.2s;
  cursor: default;
  font-size: 15px;
  font-weight: 600;
}

.boxed-widget ul li span {
  float: right;
  font-weight: 400;
}

.total_cost span {
  font-size: 18px;
  color: #0cbcb7;
  font-weight: 500;
}

.total_cost h4 {
  display: inline-block;
}

.total_cost.clearfix.border-top {
  padding-top: 10px;
}

.payment-options {
  padding: 10px 0;
  text-align: left;
  padding: 25px;
  margin-top: 50px;
  border: 1px solid #ddd;
  border-radius: 3px;
}

.payment-options h4 {
  margin-bottom: 0;
}

.payment-options .custom-checkbox {
  line-height: 40px;
  width: 100%;
}

.custom-checkbox {
  margin-top: 10px;
  cursor: pointer;
  user-select: none;
}

.payment-options .custom-checkbox input[type="radio"] {
  vertical-align: middle;
}

input[type="checkbox"],
input[type="radio"] {
  margin-right: 10px;
}

.payment-options .sm-heading {
  border-bottom: none;
  line-height: 1;
  margin-left: 0;
  width: auto;
}

.custom-checkbox .sm-heading {
  display: inline-block;
  font-size: 12px;
}

.sm-heading,
.xsm-heading {
  color: #141414;
  display: block;
  font-size: 14px;
  font-weight: 700;
  letter-spacing: 0.075em;
  text-transform: uppercase;
}

.alert-text {
  padding: 0 0 0 27px;
}

.payment-options .custom-checkbox {
  line-height: 40px;
  width: 100%;
}

.payment-options .custom-checkbox .card-options {
  display: inline-block;
  float: right;
}

.card-options img {
  margin: 0 0 0 6px;
}

/*----------------------------------------
                Booking success
------------------------------------------*/

.booking-confirmation-page {
  text-align: center;
}

.booking-confirmation-page i {
  color: #23b35f;
  font-size: 140px;
  line-height: 1.1;
}

.booking-confirmation-page h2 {
  font-size: 40px;
  font-weight: 700;
  letter-spacing: -0.5px;
  margin-bottom: 10px;
  display: block;
}

.booking-confirmation-page p {
  font-size: 20px;
  display: block;
  margin-bottom: 30px;
}

.booking-confirmation-page a.button {
  padding: 11px 35px;
  background-color: #eee;
  color: #444;
  font-weight: 600;
  font-size: 16px;
}

/*------style2------*/

.listing-details-next,
.listing-details-prev {
  position: absolute;
  top: 46%;
  z-index: 9999;
  opacity: 0;
  transition: 0.5s;
}

.listing-details-next {
  right: 3%;
}

.listing-details-prev {
  left: 3%;
}

.listing-details-slider:hover .listing-details-next,
.listing-details-slider:hover .listing-details-prev {
  opacity: 1;
}

/*-------------------------------------
                User Profile
----------------------------------------*/

.user-avatar {
  height: auto;
  border-radius: 50%;
  display: table-cell;
}

.user-name {
  position: relative;
  display: table-cell;
  padding-left: 15px;
}

.user-follow.text-right p {
  display: inline-block;
  margin-right: 15px;
}

.user-follow.text-right p span {
  color: #0cbcb7;
  font-weight: 600;
}

.user-follow.text-right a {
  margin-top: -5px;
}

.user-name h4 {
  font-size: 20px;
  margin-bottom: 0;
}

.user-follow li {
  display: inline-block;
  padding-left: 15px;
  font-size: 15px;
}

.user-follow {
  margin-right: 10px;
  padding-top: 65px;
}

.user-follow li span {
  color: #0cbcb7;
  font-weight: 600;
}

.user-avatar img {
  border-radius: 50%;
  width: 120px;
}

.user-contact li i {
  margin-right: 12px;
  font-size: 20px;
}

.user-contact li {
  font-weight: 400 !important;
  font-size: 20px;
}

.boxed-widget .address {
  padding-left: 0;
  padding-right: 0;
}

.user_info_menu {
  border-bottom: 1px solid #ddd;
}

.user_info_menu .nav-tabs {
  border-bottom: none;
}

.user_info_menu .list-details-tab .nav-item::after {
  bottom: -1px;
}

/*---------------------------------
        About Page
----------------------------------*/

.about-text {
  padding: 30px 30px 30px 0;
  background: #fff;
}

.about-text span {
  color: #000;
  position: relative;
  display: inline-block;
  padding-bottom: 5px;
}

.about-text span::before {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 45px;
  height: 2px;
  content: "";
  background: #0cbcb7;
}

.about-text h2 {
  line-height: 1.2;
  margin: 30px 0;
}

/*--------Team section--------*/

.team-section.section-padding {
  position: relative;
}

.single-team-info h4 {
  color: #fff;
  margin-bottom: 5px;
}

.single-team-info {
  background-color: #0cbcb7;
  padding: 30px 15px;
  position: absolute;
  bottom: -100%;
  opacity: 0;
  transition: all 0.5s;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.single-team-member:hover .single-team-info {
  bottom: 0;
  opacity: 1;
  width: 100%;
  height: 100%;
}

.single-team-info .social-buttons.style2 {
  margin-top: 8px;
}

.single-team-info span {
  color: #fff;
}

.team-button .swiper-button-next i,
.team-button .swiper-button-prev i {
  background: #ff4a57;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  text-align: center;
  font-size: 16px;
  color: #fff;
  line-height: 30px;
}

.team-wrapper {
  position: relative;
}

.team-next,
.team-prev {
  position: absolute;
  top: 43%;
  z-index: 999;
}

.team-next {
  right: -3%;
}

.team-prev {
  left: -3%;
}

/*--------counter section----------*/

#counter {
  padding: 80px 0;
}

.counter-text {
  text-align: center;
}

.counter-text h6 {
  color: #fff;
  font-size: 40px;
  margin-bottom: 0;
  line-height: 1.2;
  margin-top: 25px;
}

.counter-text p {
  font-size: 17px;
  color: #fff;
}

.counter-text i {
  border: 1px solid #ddd;
  padding: 10px;
  border-radius: 50%;
  color: #fff;
  font-size: 30px;
}

.counter-section {
  position: relative;
}

/*-----------------------------------------
                    Contact Page
----------------------------------------*/

#contact_form .form-control-wrap input {
  border-top: none;
  border-left: none;
  border-right: none;
}

#contact_form .form-control-wrap input,
#contact_form .form-control-wrap textarea {
  font-weight: 300;
}

#contact_form .form-group {
  margin-bottom: 0;
}

#contact_form .form-group .help-block {
  color: #f91942;
  font-size: 15px;
}

.map-view-wrap_item {
  position: absolute;
  width: 350px;
  border-radius: 6px;
  background: #fff;
  padding: 25px 30px;
  top: 80px;
  right: 120px;
  z-index: 9999;
}

.list-single-main-item-title {
  margin: 0 0 20px 0;
}

.list-single-main-item-title h3 {
  color: #444;
  text-align: left;
  font-size: 18px;
  font-weight: 600;
}

.address-list {
  display: block;
}

.contact-social li {
  display: inline-block;
  width: 35px;
  height: 35px;
  border: 1px solid transparent;
  border-radius: 50%;
  background: #0cbcb7;
  text-align: center;
  margin-right: 5px;
  transition: 0.5s;
  cursor: pointer;
}

.contact-social li:hover {
  border: 1px solid #ddd;
  background: transparent;
}

.contact-social li:hover a i {
  color: #444;
}

.contact-social li a i {
  color: #fff;
  font-size: 15px;
  line-height: 35px;
}

.address-list li {
  margin-bottom: 12px;
  font-size: 15px;
}

.address-list ul li span i {
  font-size: 20px;
}

.address-list ul li span {
  width: 30px;
  display: inline-block;
  text-align: left;
}

.address-list ul li a {
  font-size: 14px;
  margin-left: 5px;
}

.contact-widget p {
  margin-top: 15px;
}

.contact-widget {
  border: 1px solid #ddd;
  padding: 20px;
}

/* -----------------------------------------------------------------
                         9. COLOR SWITCHER
------------------------------------------------------------------*/

.color-switcher {
  background-color: #fff;
  border: 1px solid #e5e5e5;
  border-radius: 2px;
  padding: 10px;
  position: fixed;
  top: 150px;
  transition: all 0.4s ease 0s;
  width: 200px;
  z-index: 9998;
}

.hide-color-switcher {
  left: -200px;
}

.show-color-switcher {
  left: 0;
}

.color-switcher a.switcher-button {
  background: #fff;
  border: 1px solid #e5e5e5;
  border-left-color: transparent !important;
  border-radius: 2px;
  cursor: pointer;
  font-size: 22px;
  width: 45px;
  height: 45px;
  line-height: 43px;
  position: absolute;
  top: -1px;
  left: 198px;
  text-align: center;
}

.color-switcher .color-switcher-title h5 {
  margin-bottom: 0;
  color: #666;
}

.color-switcher .color-list a.color {
  cursor: pointer;
  display: inline-block;
  height: 30px;
  margin: 10px 5px 0 5px;
  width: 30px;
  border-radius: 5px;
}

.switcher-button i {
  color: #0cbcb7;
}

.red-theme {
  background-color: #f91942;
}

.green-theme {
  background-color: #1ec38b;
}

.blue-theme {
  background-color: #6ae;
}

.orange-theme {
  background-color: #fd901b;
}

.pink-theme {
  background-color: #fc69a4;
}

.purple-theme {
  background-color: #6449e7;
}

.violet-theme {
  background-color: #990099;
}

.aqua-theme {
  background-color: #1cbbb4;
}

.switcher-button i:before {
  -webkit-animation: rotation 2s infinite linear;
}

@-webkit-keyframes rotation {
  from {
    -webkit-transform: rotate(0deg);
  }

  to {
    -webkit-transform: rotate(359deg);
  }
}

/*-------------------------------------------
            Preloader
-------------------------------------------*/

.preloader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #fff;
  z-index: 9999;
  overflow-x: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}

/*------------------------------------------
        Map Marker
-------------------------------------------*/

#map_right_listing {
  width: 100%;
  height: 100vh;
}

.infoBox {
  -webkit-animation: fadeIn 0.9s;
  animation: fadeIn 0.9s;
  padding-right: 50px;
}

.infoBox > img {
  position: absolute !important;
  right: 60px !important;
  top: 10px !important;
  z-index: 9999;
  width: 20px;
  height: 20px;
  display: block;
  cursor: pointer;
}

/* .gmnoprint {
  bottom: 200px !important;
  position: absolute !important;
  left: auto !important;
  right: -50px;
} */

span.infobox_rate {
  display: inline-block !important;
  float: right;
  background-color: #f91;
  font-weight: 500;
  text-align: center;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  padding: 0 !important;
  line-height: 31px !important;
  margin-top: -33px;
}

#map_contact {
  width: 100%;
  height: 450px;
}

.marker_info img {
  display: block;
  width: 240px;
  height: 140px;
  -webkit-border-radius: 5px 5px 0 0;
  -moz-border-radius: 5px 5px 0 0;
  -ms-border-radius: 5px 5px 0 0;
  border-radius: 5px 5px 0 0;
}

.marker_info {
  width: 240px;
  border-radius: 5px;
  text-align: left;
  background: #000;
  background: #fff;
  position: relative;
  z-index: 999;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.12);
}

.marker_info h3 {
  font-size: 16px;
  font-size: 1rem;
  line-height: 1.1;
  font-weight: 500;
  margin-top: 3px;
  color: #444;
  margin-bottom: 10px;
}

.marker_info em {
  display: inline-block;
  font-size: 12px;
  font-size: 0.75rem;
  color: #333;
  font-style: normal;
  font-weight: 500;
}

.marker_info span {
  display: block;
  padding: 20px;
  font-size: 13px;
  font-size: 0.8125rem;
  line-height: 1.2;
  color: #fff;
  position: relative;
}

.cluster-visible {
  background-image: none !important;
  background-color: #0cbcb7;
  border-radius: 50%;
  color: #fff !important;
  height: 45px !important;
  line-height: 45px !important;
  width: 45px !important;
  animation: cluster-animation 1.5s linear infinite;
}

.cluster-visible::before {
  opacity: 0.2;
  box-shadow: inset 0 0 0 4px #66676b;
  content: "";
  position: absolute;
  border-radius: 50%;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  animation: cluster-animation 1.5s linear infinite;
}

/* 
new css */
.faq_sec .card {
  box-shadow: 0 0 3px #a7a7a7;
  background: #fff;
  border-radius: 6px 6px 0 6px !important;
  margin: 9px 0;
  border: none;
}
.faq_sec .card .card-header img {
  width: 15px;
  height: 15px;
  object-fit: contain;
}
.faq_sec .card .card-header {
  background: #fff !important;
  box-shadow: none;
  border-bottom: none !important;
  border-radius: 0 !important;
}
.faq_sec .card .card-header .rotate-icon {
  transition: all 500ms;
}

.faq_sec .card .card-header .card_btn.collapsed {
  box-shadow: none !important;
}
.faq_sec .card .card-header .card_btn:not(.collapsed) .rotate-icon {
  transform: rotate(90deg);
}

.faq_sec .card {
  box-shadow: none;
  border-radius: 0 !important;
}
.faq_sec .card .card-body p {
  font-size: 18px;
}
.faq_sec .card_acord {
  border-bottom: 1px solid #dddddd !important;
}

.faq_sec .card .btn {
  background: none;
  box-shadow: 5px 5px 15px rgba(0, 0, 0, 0.2) !important;
  padding: 12px;
  border: none;
  color: #fff;
  white-space: normal;
}

.earn_sec {
  background-image: url("./assets/images/ready-to-move.webp");
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  position: relative;
  overflow: hidden;
  justify-content: center;
  aspect-ratio: 1024 / 259;
  display: flex;
  flex-direction: column;
}

.earn_sec::before {
  position: absolute;
  content: "";
  background: #00000061;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

/* end */

@keyframes cluster-animation {
  0% {
    box-shadow:
      0 4px 10px rgba(12, 188, 183, 0.2),
      0 0 0 0 rgba(12, 188, 183, 0.2),
      0 0 0 5px rgba(12, 188, 183, 0.2),
      0 0 0 10px rgba(12, 188, 183, 0.2);
  }

  100% {
    box-shadow:
      0 4px 10px rgba(12, 188, 183, 0.2),
      0 0 0 5px rgba(12, 188, 183, 0.2),
      0 0 0 10px rgba(12, 188, 183, 0.2),
      0 0 0 20px rgba(12, 188, 183, 0);
  }
}

.btn_infobox_reviews {
  color: #999 !important;
  padding: 0 !important;
}

.marker_info span strong {
  display: block;
  font-weight: 500;
}

.marker_info:after {
  right: 100%;
  top: 56%;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  border-color: transparent;
  border-right-color: #fff;
  border-width: 12px;
  margin-top: -12px;
}

.marker_info::after {
  right: 100%;
  top: 56%;
  border: solid transparent;
  border-top-color: transparent;
  border-top-width: medium;
  border-right-color: transparent;
  border-right-width: medium;
  border-bottom-color: transparent;
  border-bottom-width: medium;
  border-left-color: transparent;
  border-left-width: medium;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  border-color: transparent;
  border-right-color: transparent;
  border-right-color: #fff;
  border-width: 12px;
  margin-top: -12px;
}

/*------------------------------------------
                Responsive Css
-------------------------------------------*/

@media (max-width: 1200px) {
  .trending-btn-wrap {
    margin: 3px 0 0;
  }

  .hero.bg-zoom {
    animation: none;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .listing-sidebar {
    padding-left: 0;
  }

  .business-title {
    padding: 10px;
  }

  ul.business-hours {
    padding: 10px;
  }

  .add_list .list-details-tab .nav-item {
    margin-right: 25px;
  }

  .hero-slider-form {
    bottom: 45%;
  }
}

@media only screen and (max-width: 1199px) {
  .coupon_code {
    margin-left: 0;
    margin-top: 10px;
  }

  .list-tags {
    margin-bottom: 50px;
  }

  .hero-next,
  .hero-prev {
    top: 38%;
  }

  .hero-slider-item.bg-f {
    height: 50vh;
  }
}

@media only screen and (min-width: 575px) and (max-width: 1199px) {
  .sidebar .widget.recent-posts .entry-title a {
    font-size: 20px;
  }
}

@media only screen and (max-width: 1024px) {
  /* .hero__form {
    padding: 30px 25px 30px 25px;
  } */

  .hero__title {
    font-size: 3.8rem;
    line-height: 4.4rem;
    margin-left: -3px;
  }

  .trending-prev,
  .popular-prev {
    left: -2.5%;
  }

  .trending-next,
  .popular-next {
    right: -2.5%;
  }

  .blog-hover-content {
    padding: 0 25px;
  }

  .hero-category-content.v2 {
    width: calc(100% * (1 / 3) - 20px);
    padding: 10px 15px;
  }

  .app-content {
    padding-top: 15px;
  }

  .app-content h2 {
    font-size: 25px;
  }

  .app-content h2 span {
    font-size: 35px;
  }
}

@media only screen and (min-width: 1025px) {
  .single-place:hover .single-place-title {
    transform: translatey(-15px);
  }

  .single-place:hover .single-place-list {
    transform: translatey(-25px);
    opacity: 1;
  }

  .single-place:hover .explore-place {
    transform: translatey(-12px);
    color: #fff;
    opacity: 1;
  }

  .popular-catagory-content:hover.popular-catagory-content .cat-content {
    opacity: 1;
  }

  .single-blog-item.v2:hover .blog-hover-content .blog-title {
    transform: translatey(-20px);
  }

  .single-blog-item.v2:hover .blog-hover-content a.btn.v6 {
    transform: translatey(-6px);
    transition-delay: 0.1s;
    opacity: 1;
  }
}

@media only screen and (max-width: 1024px) {
  .single-place-content {
    bottom: 3.6rem;
  }

  .single-place .explore-place {
    transform: translatey(30px);
    color: #fff;
    opacity: 1;
  }

  .popular-catagory-content .cat-content {
    opacity: 1;
  }

  .blog-hover-content .blog-title {
    transform: translatey(15px);
  }
}

@media only screen and (min-width: 992px) and (max-width: 1024px) {
  .hero-category-content {
    width: calc(100% * (1 / 3) - 21px);
    margin-bottom: 15px;
  }

  .app-img {
    top: -85px;
  }
}

@media only screen and (max-width: 992px) {
  /* .trending-next,
  .popular-next,
  .coupon-next,
  .team-next,
  .testimonial-next,
  .trending-prev,
  .popular-prev,
  .coupon-prev,
  .team-prev,
  .testimonial-prev {
    top: 105%;
  } */

  .partner-next,
  .partner-prev {
    top: 60px;
  }

  /* .trending-prev,
  .popular-prev,
  .coupon-prev,
  .team-prev,
  .partner-prev,
  .testimonial-prev {
    left: 44%;
  } */
  /* 
  .trending-next,
  .popular-next,
  .coupon-next,
  .team-next,
  .partner-next,
  .testimonial-next {
    right: 44%;
  } */

  .blog-cat {
    top: 20px;
    left: 25px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .hero__description {
    font-size: 1rem;
  }
  .workspace_steps .workspace_img img {
    height: 100% !important;
  }
  .hero_sec {
    height: calc(100dvh - 50px) !important;
  }

  .hero-category-content {
    width: calc(100% * (1 / 3) - 21px);
    margin-bottom: 15px;
  }

  .page-wrapper.fixed-footer {
    margin-bottom: 580px;
  }

  .entry-text {
    margin-top: 10px;
  }

  .app-img {
    top: -5px;
  }
  .Spaces_box .space_img_box img {
    height: 210px !important;
  }
}

@media only screen and (min-width: 481px) and (max-width: 991px) {
  .section-title {
    font-size: 1.7rem;
  }

  .nav-folderized .nav h2::before {
    opacity: 0;
  }
}

@media only screen and (max-width: 991px) {
  .color-switcher {
    display: none;
  }

  .single-blog-item.v1 img,
  .popular-catagory-img img,
  .coupon-img img {
    width: 100%;
  }

  .hero__input-holders {
    width: 40rem;
    margin-bottom: 1rem;
  }

  .sm-center {
    text-align: center !important;
  }

  .sm-right {
    float: right !important;
  }

  .md-left {
    float: left !important;
  }

  .btn.v1 {
    font-size: 16px !important;
    padding: 10px 20px !important;
    display: inline-block !important;
  }

  .hero__title {
    margin-top: 100px;
  }

  .site-navbar-wrap.v1 {
    padding: 15px 0;
  }

  .site-nav-wrap a.btn.v1 {
    margin-top: 15px;
    padding: 5px;
  }

  .filter-wrapper .container-fluid {
    position: relative;
  }

  .contact-map.v2 .gmnoprint {
    bottom: 75px !important;
  }

  /* .explore__map-side {
    width: 100%;
    position: absolute;
    top: 320px;
    left: 0 !important;
    height: 350px;
    padding-left: 15px;
  } */
  /* .gmnoprint {
    bottom: 150px !important;
  } */

  .contact-map {
    margin-top: 50px;
  }

  .filter-wrapper.style1.v2 .hero__form.filter {
    background: transparent;
    box-shadow: none;
  }

  .filter-wrapper.style1.v2 .hero__form.v2 .hero__form-input {
    border: none;
    box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.12);
  }

  .filter-wrapper.style1.mar-top-100.half-map {
    margin-top: 38px !important;
  }
  /* 
  .filter-wrapper.style1.mar-top-100.half-map .form-group.filter-group {
    margin-top: 450px;
  } */
  .filter-wrapper.style1.mar-top-100.half-map .mb_filter_row {
    margin-bottom: 400px;
  }

  .add-list {
    display: none !important;
  }

  .sm-none {
    display: none !important;
  }

  .pricing-table {
    margin-bottom: 30px;
  }

  .sm-right {
    text-align: right !important;
  }

  .listing-sidebar {
    padding-left: 0;
  }

  .hero__form.v2 {
    /* padding: 0; */
    background: transparent;
  }

  .hero__form.v2 .hero__form-input::before {
    display: none;
  }

  .hero__form.v2 .nice-select.hero__form-input {
    line-height: 0 !important;
  }

  .hero__form.v2 .nice-select::after {
    right: 5%;
    top: 53%;
  }

  .explore__form-checkbox-list.full-filter {
    left: 5%;
  }

  .filter-checkbox {
    margin-bottom: 25px;
  }

  .full-filter .price-range.ui-widget.ui-widget-content {
    width: 300px;
  }

  .nav-folderized .nav h2 {
    cursor: pointer;
  }

  .nav-folderized ul {
    max-height: 0;
    overflow: hidden;
    transition: 0.5s ease-out;
  }

  .nav-folderized .nav h2:after {
    content: "\f3d0";
    float: right;
    font-family: "Ionicons";
    font-size: 17px;
    line-height: 1;
    margin-left: 10px;
    position: absolute;
    right: 0;
  }

  .nav-folderized .nav.open h2:after {
    content: "\f3d8";
    float: right;
    font-family: "Ionicons";
    font-size: 18px;
  }

  .nav-folderized .nav.open ul {
    height: auto;
    max-height: 500px;
    transition: 0.5s ease-in !important;
  }

  .hero.v2,
  .hero.v3 {
    padding-top: 54px;
  }

  .hero-catagory-wrapper.v2 {
    transform: translateY(0px);
    padding-bottom: 250px;
  }

  .slider-btn.v1 {
    width: 30px;
    height: 30px;
  }

  .slider-btn.v1 i {
    line-height: 28px;
    font-size: 15px;
  }

  .user-follow.text-right p {
    margin-top: 20px;
  }

  .review-item__post {
    display: flex;
  }

  .review-item__content {
    margin-top: 50px;
  }

  .review-item__post-image {
    margin-bottom: 10px;
  }

  .boxed-widget.summary {
    margin-top: 30px;
  }

  .add_list .nav.nav-tabs.list-details-tab {
    display: block;
  }

  .add_list .list-details-tab .nav-item {
    border-bottom: 1px solid #ddd;
  }

  .map-view-wrap_item {
    position: relative;
    left: 0;
    top: 0;
    background: transparent;
    border-radius: 0;
    z-index: 999;
    padding: 40px 0 0 0;
  }

  .contact-widget {
    margin-top: 50px;
  }

  .blog-hover-content .blog-title {
    transform: translatey(-35px);
  }

  .device-logo a img {
    width: 32%;
  }

  .filter-sub-menu.style1 ul li.has-sub > a {
    margin-top: 15px;
  }

  .footer-top-area .footer-content .title {
    margin-top: 15px;
    line-height: 1;
  }

  .footer-logo .company-desc {
    margin-bottom: 20px;
  }

  #list-view.product-list .trending-place-item .trending-title-box {
    padding: 15px 30px;
  }

  .hero-slider-form {
    position: relative;
    margin-top: 30px;
  }

  .hero-slider-form .hero__form.v2 {
    box-shadow: none;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  ul.listing-features {
    columns: 2;
  }

  .page-wrapper.fixed-footer {
    margin-bottom: 600px;
  }

  .hero-slider-content h3 {
    font-size: 38px;
  }

  .hero-slider-content p {
    font-size: 15px;
  }

  .hero-slider-content {
    width: 450px;
  }
}

@media only screen and (min-width: 768px) {
  .fixed-header {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.3);
    transform: translateY(0);
  }
}

@media only screen and (max-width: 767px) {
  .bg-xs {
    height: 500px;
  }

  .desktop-button-fixed-container {
    width: 100% !important;
    justify-content: center !important;
    align-items: center !important;
    gap: 12px;
  }
  .desktop-button-fixed-container .desktop_content_btm {
    flex-direction: column !important;
    justify-content: center !important;
    align-items: center !important;
  }
  .desk_btn {
    justify-content: center !important;
    align-items: center !important;
  }

  .hero__list-item a {
    padding: 5px 15px;
  }

  .sm-center {
    text-align: center !important;
  }

  .sm-right {
    float: right !important;
  }

  .sm-left {
    text-align: left !important;
  }

  .hero__description {
    font-size: 1rem;
  }

  .hero__title {
    letter-spacing: 0;
  }

  .hero-img.text-right {
    display: none;
  }

  .pricing-table .title:before {
    height: 400px;
    top: 100px;
    left: 55px;
  }

  .pricing-table .title:after {
    width: 550px;
    height: 550px;
    top: 150px;
    right: -100px;
    transform: rotate(-20deg);
  }

  .counter-text {
    margin-top: 35px;
  }

  .margin-bot-35 {
    margin-bottom: 35px;
  }

  .hero-category-content {
    width: calc(100% * (1 / 3) - 21px);
    margin-bottom: 15px;
  }

  .list-details-btn {
    margin-top: 22px;
  }

  .list-details-tab li.nav-item,
  .list-details-tab.fixed_nav li {
    display: block;
  }

  .list_menu {
    border-bottom: none;
  }

  .list-details-title.v1 {
    transform: translateY(300px);
  }

  .item-wrapper .product-list .trending-place-item .trending-title-box {
    padding: 20px 30px;
  }

  .hero__list-item a {
    font-size: 1rem;
  }

  .hero__list-item a i {
    font-size: 0.9375rem;
  }

  .hero__form-input {
    width: 100%;
    margin-right: 0;
  }

  .hero__input-holders {
    width: 100%;
    border-radius: 0.25rem;
  }

  .list-details-tab.nav-tabs .nav-link {
    text-align: left;
    border-bottom: 1px solid #ddd;
  }

  .list-details-tab.nav-tabs .nav-link.active {
    border-bottom: none;
  }

  .list-details-tab.nav-tabs {
    border-bottom: none;
  }

  .feature-left.float-left {
    margin-top: 25px;
  }

  .room-type-item .feature-right {
    margin-top: -47px;
  }

  .facilities-list {
    margin-top: 45px;
  }

  .listing-details-slider .single-listing-slider a img,
  .room-type-item img {
    width: 100%;
  }

  .customer-text {
    margin: 70px 0 0;
  }

  .review_wrap .rate-review {
    margin-bottom: 10px;
  }

  .list-tags {
    margin-bottom: 30px;
  }

  .review-box {
    padding: 0 10px;
  }

  /* .trending-prev,
  .popular-prev,
  .coupon-prev,
  .team-prev,
  .partner-prev,
  .testimonial-prev {
    left: 43%;
  }

  .trending-next,
  .popular-next,
  .coupon-next,
  .team-next,
  .partner-next,
  .testimonial-next {
    right: 43%;
  } */

  .similar-next {
    right: 2%;
  }

  .coupon-desc.float-right h4 {
    margin-top: 30px;
    margin-bottom: 0;
  }

  .coupon-owner {
    margin-bottom: 20px;
  }

  .contact-widget {
    margin-top: 50px;
  }

  .list-details-tab.nav-tabs.pricing-list .nav-item {
    margin-right: 0;
    text-align: left;
  }

  .list-details-tab.nav-tabs.pricing-list .nav-item:first-child {
    padding-right: 15px;
  }

  .app-content {
    padding-bottom: 30px;
  }

  .app-img {
    position: relative;
    top: 10px;
  }

  .action-title p {
    margin-bottom: 30px;
  }

  .add_list_content .filter-input {
    margin-bottom: 15px;
  }

  .list-details-title.v3 {
    transform: translateY(300px);
  }

  .user_info_menu {
    border-bottom: none;
  }
}

@media (max-width: 575px) {
  .xs-left {
    float: left !important;
  }

  .hero__description {
    font-size: 1rem;
  }

  .hero__list-item a {
    font-size: 0.9375rem;
  }

  .hero__list-item a i {
    font-size: 0.9375rem;
  }

  .trending-btn-wrap .btn-danger {
    width: 100%;
  }

  ul.listing-features {
    columns: 2;
  }

  .list-single-facts ul {
    columns: 1;
  }

  a.rate-review {
    padding: 6px 10px;
    margin-right: 10px;
  }

  a.rate-review i {
    margin-right: 5px;
  }

  a.rate-review span {
    padding-left: 5px;
  }

  .review_wrap .rate-review {
    float: left !important;
    margin-bottom: 10px;
  }

  .review_wrap.has-child {
    margin-left: 30px;
  }

  .explore__form-checkbox-list.full-filter {
    left: 2%;
  }

  .nav-folderized .nav h2:after {
    right: 5%;
  }

  .page-wrapper.fixed-footer {
    margin-bottom: 640px;
  }

  .counter-text h6 {
    font-size: 25px;
  }

  .counter-text i {
    font-size: 20px;
  }

  .counter-text p {
    font-size: 15px;
  }

  .hero-catagory-menu ul li {
    margin-bottom: 15px;
  }

  .contact-form__upload-btn {
    margin-bottom: 40px;
  }

  .contact-form__input-file {
    width: 157px;
  }

  .sec_footer .social-buttons.style2,
  .sec_footer .list.footer-list {
    text-align: center !important;
  }

  .sec_footer .contact-info {
    display: inline-block;
  }

  .hero-slider-content {
    width: auto;
    padding-right: 40px;
  }

  .hero-slider-content h3 {
    font-size: 26px;
    line-height: 1;
  }

  .hero-slider-content p {
    font-size: 14px;
  }
}

@media only screen and (max-width: 480px) {
  .xs-center {
    text-align: center !important;
  }

  .hero__form.v1.bg-white {
    padding: 20px 15px;
  }

  .section-title {
    font-size: 1.5rem;
  }

  .pricing-table .title:after,
  .pricing-table .title:before {
    width: 280px;
    height: 200px;
    top: 220px;
    left: -46px;
  }

  .pricing-table .title:after {
    top: 150px;
    left: auto;
    right: -70px;
  }

  .trending-title-box {
    padding: 22px 14px;
  }

  .hero-category-content {
    width: calc(100% * (1 / 2) - 21px);
    margin-bottom: 15px;
  }

  .review-item__description {
    margin: 10px 0 20px 0;
  }

  .review-item__image {
    width: 100%;
  }

  .review-item__post {
    display: block;
  }

  .list-ratings span {
    font-size: 16px;
  }

  .slider-btn.v4 {
    width: 30px;
    height: 30px;
  }

  .slider-btn.v4 i {
    line-height: 30px;
  }

  .single-listing-title h2 {
    font-size: 23px;
  }

  .contact-form__upload-btn {
    text-align: left;
    margin-bottom: 37px;
  }

  .contact-form__upload-btn span {
    top: 0;
    left: 0;
  }

  .contact-form__upload-btn span i {
    margin-right: 0.25rem;
  }

  ul.business-hours {
    padding: 15px;
  }

  .customer-rating {
    float: left;
  }

  /* .trending-prev,
  .popular-prev,
  .coupon-prev,
  .team-prev,
  .partner-prev,
  .testimonial-prev {
    left: 38%;
  }

  .trending-next,
  .popular-next,
  .coupon-next,
  .team-next,
  .partner-next,
  .testimonial-next {
    right: 38%;
  } */

  .hero.v2 .hero-desc.v2 {
    margin-bottom: 0;
    line-height: 1.2;
    font-size: 13px;
    font-weight: 500;
  }

  .hero.v2 .hero-title.v2 {
    font-size: 1.19rem;
    font-weight: 700;
    margin-bottom: 0;
  }

  .review_wrap li li {
    margin-left: 0;
  }

  .app-content form input[type="text"] {
    margin-bottom: 15px;
  }

  .popular-catagory-content .title {
    font-size: 25px;
    top: 38%;
  }

  .popular-catagory-content .cat-content span {
    margin-top: 60px;
  }

  .blog-hover-content a.btn.v6 {
    opacity: 0;
  }

  .blog-hover-content .blog-title {
    transform: translatey(12px);
  }

  .single-blog-item.v2:hover .blog-hover-content a.btn.v6 {
    opacity: 0;
  }

  .popular-item.sm-grid .single-place-content {
    bottom: 2.6rem;
  }

  .popular-item.sm-grid .single-place-title {
    margin-bottom: 5px;
  }

  .popular-item.sm-grid .single-place-title a {
    font-size: 20px;
  }

  .contact-form__input-file {
    width: 170px;
  }

  .payment_receipt--contents .btn.v3 {
    margin-top: 20px;
  }
}

@media (max-width: 320px) {
  .trending-title-box span {
    padding: 0 4px;
  }

  .trending-title-box p {
    font-size: 13px;
  }

  .trending-title-box ul li span {
    font-size: 12px;
    padding: 0 2px 0 0;
  }
}

/*@media only screen (max-width:767px) and(orientation:landscape){

    .hero-title.v2,
    .hero-desc.v2{
        display: none;
    }

}*/

.btn.v8,
.btn.v1,
.btn.v3,
.overlay.green,
.header.transparent.scroll .site-navbar-wrap.v1,
.filter-checkbox input[type="checkbox"]:checked+label::before,
.single-team-info,
.btn.v6.red,
.scrolltotop,
.trending-pagination .swiper-pagination-bullet-active,
.client-pagination .swiper-pagination-bullet-active,
.trending-pagination .swiper-pagination-bullet,
.client-pagination .swiper-pagination-bullet,
.process-icon span,
.page-num li.active,
.price-range.ui-slider-horizontal .ui-slider-range,
.book-btn a,
.facilities-list li span,
.slider-btn.v3,
.coupon_code,
.contact-social li,
.btn.v4,
.btn.v7,
.business-title span,
.slider-btn.v4,
/*.footer-wrapper.footer-fixed .footer-bottom-area,
.footer-wrapper .footer-bottom-area,*/

.faq-section .card-header>a[aria-expanded="true"],
.price-range .ui-slider-handle.ui-corner-all.ui-state-default,
.site-navbar .site-navigation .site-menu .has-children .dropdown li:before,
.list-details-tab .nav-item::after,
.page-num li:hover,
.hero-category-content:hover,
.about-text span::before,
/*.slider-btn.v1:hover,*/

.trending-place-item .save-btn:hover {
  background-color: #f76900;
}

.carousel-control-prev span,
.carousel-control-next span {
  background-color: white;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.05);
}

.trending-place-item .save-btn1:hover {
  background-color: rgba(0, 0, 0, 0.37);
}

.slider-btn.v1:hover,
.hero-prev:hover,
.hero-next:hover {
  border-color: #f76900;
}

.slider-btn.v1:hover i,
.hero-prev:hover i,
.hero-next:hover i {
  color: #f76900;
}

/*
.footer-wrapper.footer-fixed{
    background-color: rgba(249, 25, 66, 0.85);
}
*/

.btn.v3.white:hover {
  background: #f76900 !important;
}

.hero .hero__list-item a.active-list {
  color: #f76900;
}

.bg-green-1 {
  background: rgba(249, 25, 66, 0.08);
}

.popular-catagory-content .cat-content,
.popular-catagory-content.v2:hover .cat-content {
  background: rgba(249, 25, 66, 0.8);
}

.pricing-table .title {
  background: rgba(249, 25, 66, 0.8);
}

.switcher-button i,
.listing-features li i,
.list-details-tab .nav-item.active a,
.price-amt,
.facilities-list li i,
.feature-left p span,
.user-follow.text-right p span,
.pricing-table .pricing-content li i,
.user-login-section .nav-tabs .nav-link.active,
.booking-confirmation-page i,
.total_cost span,
.boxed-widget h3 i,
.add_list_content h4 i,
.error-content a,
.error-content i,
.res-box label a,
.business-title h6 i,
.similar-item .customer-review p i,
.address > span,
.sidebar-widget.info h3 i,
.single-listing-title h2 i,
.single-listing-title p i,
.btn.v3.white,
.item-element span,
.trending-address li i,
.hero-category-content i,
.app-content ul li i,
.site-navbar .site-navigation .site-menu .has-children .dropdown > li > a:hover,
a.rate-review:hover i {
  color: #f76900 !important;
}

.trending-pagination .swiper-pagination-bullet-active,
.client-pagination .swiper-pagination-bullet-active {
  background: #f76900 !important;
}

.nice-select .option:hover {
  background: #f76900;
  color: #fff;
}

.hero-category-content {
  border: 1px solid rgba(249, 25, 66, 0.4);
}

.single-testimonial-item.swiper-slide-active .testimonial-content {
  background: rgba(249, 25, 66, 0.85);
}

.item-filter-list li a.active,
.item-filter-list li a:hover,
a.rate-review:hover {
  color: #f76900;
  border: 1px solid #f76900;
}

/* .section-title.v1::before {
  content: url("assets/images/bg/line.jpg") !important;
} */

.price-range {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 50%;
  min-width: 340px;
}

.price-range mat-form-field {
  width: 40%;
}

.bottom-fixed-container {
  /* display: flex; */
  position: fixed;
  bottom: 0px;
  width: 100%;
  z-index: 99;
  background-color: white;
}

.bottom-fixed-button {
  width: 90%;
  margin: 10px auto;
}

.bottom-fixed-button a {
  margin-bottom: 3px;
}

.flex-shimmer {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.flex-shimmer div {
  width: 47%;
  margin: 10px 14px;
}

@media only screen and (max-width: 700px) {
  .flex-shimmer div {
    width: 100%;
    margin: 10px auto;
  }
}

.shortlist-share-container .btn {
  border-radius: 29px;
  font-size: 16px;
  background: #ece8e8;
  width: 34px;
  height: 34px;
  cursor: pointer !important;
}

.shortlist-share-container .btn-shortlisted {
  border-radius: 29px;
  font-size: 16px;
  background: #ece8e8;
  width: 38px;
  height: 38px;
  cursor: pointer !important;
}

.shortlist-share-container .on-image-icon {
  font-size: 19px;
  position: relative;
  bottom: 3px;
  right: 4px;
}

.desktop-button-fixed-container {
  position: fixed;
  bottom: 0px;
  text-align: center;
  padding: 13px;
  background: white;
  align-items: baseline;
  z-index: 1000;
  /* width: 57.9%; */
}

.desktop-button-fixed-container div:first-child {
  display: flex;
  justify-content: space-between;
}

.desktop-button-fixed-container div {
  display: flex;
  justify-content: flex-end;
  color: black;
}

.desktop-button-fixed-container i {
  color: #f76900;
}

.desktop-button-fixed-container strong {
  font-weight: 600;
}

.find-office-container div {
  margin-left: 5px;
}

.find-office-container i {
  color: #f76900;
}

.booking-icon,
.booking-text {
  vertical-align: middle;
  display: inline-block;
}

.booking-text {
  font-size: 12px;
  color: black;
}

.find-office-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding-top: 6px;
}

.booking-icon img {
  width: 10px;
  height: 10px;
  margin-right: 1px;
}

.booking-text span {
  font-weight: bold;
}

.privacy-content {
  width: 100%;
  position: relative;
  z-index: 4;
  float: left;
  background-color: #fff;
}

.privacy-content.fs-slider-wrap {
  padding-top: 80px;
}

section.parallax-section {
  padding: 110px 0;
}

section.parallax-section.small-par {
  padding: 90px 0;
}

.list-single-main-media img {
  width: 100%;
  height: auto;
}

.privacy-container {
  max-width: 1224px;
  width: 92%;
  margin: 0 auto;
  position: relative;
  z-index: 5;
}

.absolute-main-search-input .container {
  max-width: 900px;
}

section.parallax-section .bg1 {
  height: 120%;
  top: 0;
}

.parallax-section .section-title-static h2 {
  font-size: 44px;
}

.parallax-section .section-title-static h2,
.parallax-section .section-title-static p {
  color: #fff;
}

.parallax-section .section-title-static h2 {
  font-size: 44px;
}

.parallax-section .section-subtitle {
  color: #fff;
  opacity: 0.2;
}

.parallax-section .section-subtitle:before {
  display: none;
}

.hero-section .intro-item h2,
.parallax-section .section-title-static h2,
.soon-title,
.list-single-header-item h2,
.list-single-header-inside .list-single-header-item h2 {
  font-size: 34px;
}

.bg1 {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  background-size: cover;
  background-attachment: scroll;
  background-position: center;
  background-repeat: repeat;
  background-origin: content-box;
}

section.hero-section .bg1 {
  height: 120%;
  top: -10%;
}

section.list-single-section .bg1 {
  height: 110%;
  top: -5%;
}

@media only screen and (-webkit-min-device-pixel-ratio: 2) {
  .bg1 {
    background-attachment: scroll !important;
    -webkit-background-size: cover;
    background-position: center;
  }
}

.section-title-static {
  float: left;
  width: 100%;
  position: relative;
  padding-bottom: 50px;
}

.section-title-static p {
  color: rgb(0, 14, 84);
  font-size: 13px;
}

.section-title-static h2 {
  float: left;
  width: 100%;
  text-align: center;
  color: rgb(0, 14, 84);
  font-size: 34px;
  font-weight: 600;
}

.parallax-section .section-title-static h2,
.parallax-section .section-title-static p {
  color: #fff;
}

.parallax-section .section-title-static.left-align,
.parallax-section .section-title-static.left-align h2 {
  text-align: left;
}

.hero-section .intro-item h2,
.parallax-section .section-title-static h2,
.soon-title,
.list-single-header-item h2,
.list-single-header-inside .list-single-header-item h2 {
  font-size: 34px;
}

.section-separator {
  float: left;
  width: 100%;
  position: relative;
  margin: 15px 0;
}

section.gray-section .section-separator {
  background: #f9f9f9;
}

.section-separator:before {
  content: "";
  position: absolute;
  left: 50%;
  top: 0;
  height: 4px;
  margin-left: -25px;
  width: 50px;
  border-radius: 3px;
}

.section-separator.fl-sec-sep:before {
  left: 0;
  margin-left: 0;
}

section.gray-section .section-separator {
  background: #f9f9f9;
}

section {
  float: left;
  width: 100%;
  position: relative;
  padding: 80px 0;
  overflow: hidden;
  background: #fff;
  z-index: 1;
}

section.gray-section {
  background: #f9f9f9;
}

section.parallax-section {
  padding: 110px 0;
}

section.parallax-section.small-par {
  padding: 90px 0;
}

.hero-section-wrap {
  z-index: 30;
}

section.parallax-section .bg1 {
  height: 120%;
  top: 0;
}

.section-title p {
  color: rgb(0, 14, 84);
  font-size: 13px;
}

.terms h3 {
  text-decoration: underline;
}

.fl-wrap {
  float: left;
  width: 100%;
  position: relative;
}

.about-wrap .list-single-main-item-title-1 h3 {
  font-size: 24px;
}

.list-single-main-item-title-1 h3 {
  color: #000e54;
  text-align: left;
  font-size: 18px;
  font-weight: 600;
}

.about-wrap .list-single-main-item-title-1 h3 {
  padding-top: 30px;
}

.list-single-main-item-title-1 h3 a {
  color: #334e6f;
}

.list-single-main-item-title-1 {
  margin: 20px 0 0 0;
}

.list-author-widget-contacts {
  margin-top: 30px;
  padding-bottom: 20px;
  border-bottom: 1px solid #eee;
  float: left;
  width: 100%;
}

.list-author-widget-contacts p {
  text-align: left;
}

.list-author-widget-contacts li {
  float: left;
  width: 100%;
  margin-bottom: 15px;
}

.list-author-widget-contacts li span,
.list-author-widget-contacts li a {
  float: left;
  text-align: left;
  font-weight: 500;
}

.list-author-widget-contacts li span {
  min-width: 80px;
}

.list-author-widget-contacts li span i {
  margin-right: 7px;
  font-size: 14px;
}

.list-author-widget-contacts li a {
  margin-left: 20px;
  color: #878c9f;
}

.list-widget-social {
  margin-top: 20px;
}

.list-widget-social,
.list-widget-social li {
  float: left;
}

.list-widget-social li {
  margin-right: 8px;
}

.list-widget-social li a {
  width: 36px;
  height: 36px;
  display: block;
  line-height: 36px;
  border-radius: 4px;
  color: #fff;
}

.list-widget-social li a {
  background: #f76900;
}

.list-widget-social li a i {
  margin-left: 13px;
}

.list-widget-social li a:hover {
  background: #2c3b5a;
}

#contact-form #message {
  float: left;
  width: 100%;
  padding-bottom: 20px;
  text-align: left;
}

.custom-form {
  float: left;
  width: 100%;
  position: relative;
}

.list-single-main-item {
  padding: 10px 20px;
  background: #fff;
  border-radius: 0;
  border: none;
  margin-bottom: 0;
}

.list-single-main-item {
  padding: 30px 30px;
  border-radius: 100%;
  background: #fff;
  border-radius: 10px;
  border: 1px solid #eee;
  margin-bottom: 20px;
}

.list-single-main-item p {
  text-align: left;
  color: #878c9f;
}

.custom-form .nice-select .list li:hover,
.custom-form .nice-select .list li.selected {
  color: #fff;
}

.custom-form textarea,
.custom-form input[type="text"],
.custom-form input[type="email"],
.custom-form input[type="password"],
.custom-form input[type="button"] {
  float: left;
  border: 1px solid #eee;
  background: #f9f9f9;
  width: 100%;
  padding: 15px 20px 15px 55px;
  border-radius: 6px;
  color: #666;
  font-size: 13px;
  -webkit-appearance: none;
}

.custom-form input::-webkit-input-placeholder,
.custom-form textarea::-webkit-input-placeholder {
  color: #666;
  font-weight: 500;
  font-size: 13px;
  font-family: "Quicksand", sans-serif;
}

.custom-form input:-moz-placeholder,
.custom-form textarea:-moz-placeholder {
  color: #888da0;
  font-weight: 600;
  font-size: 13px;
}

.custom-form textarea {
  height: 200px;
  resize: none;
  padding: 25px 20px;
  -webkit-appearance: none;
}

.custom-form input {
  margin-bottom: 20px;
}

.custom-form label {
  float: left;
  position: relative;
  width: 100%;
  text-align: left;
  font-weight: 500;
  color: #666;
}

.main-register .custom-form label {
  padding-bottom: 12px;
}

.custom-form label i {
  padding-right: 12px;
  font-size: 16px;
  position: absolute;
  top: 25px;
  color: #f76900;
  left: 20px;
}

.main-register .custom-form input[type="text"],
.main-register .custom-form input[type="email"],
.main-register .custom-form input[type="password"] {
  padding-left: 20px;
}

.custom-form input::-webkit-input-placeholder,
.custom-form textarea::-webkit-input-placeholder {
  color: #666;
  font-weight: 500;
  font-size: 13px;
  font-family: "Quicksand", sans-serif;
}

.custom-form input::-webkit-input-placeholder,
.custom-form textarea::-webkit-input-placeholder {
  color: #666;
  font-weight: 500;
  font-size: 13px;
  font-family: "Quicksand", sans-serif;
}

.custom-form input:-moz-placeholder,
.custom-form textarea:-moz-placeholder {
  color: #888da0;
  font-weight: 600;
  font-size: 13px;
}

.custom-form textarea {
  height: 200px;
  resize: none;
  padding: 25px 20px;
  -webkit-appearance: none;
}

.custom-form input {
  margin-bottom: 20px;
}

.custom-form.no-icons input,
.custom-form.no-icons textarea {
  padding-left: 10px;
}

.flat-btn {
  background: #f76900;
}

.d-none {
  display: none;
}

.h-cursor {
  cursor: pointer;
}

.main {
  margin-left: 251px !important;
  /* Same as the width of the sidenav */
}

.dropdown-menu {
  /* height: 360px; */
  width: 250px !important;
  z-index: 1 !important;
  top: 0px !important;
  left: unset !important;
  background-color: #fff !important;
  overflow-x: hidden !important;
  margin-top: 55px !important;
  right: 0;
  box-shadow: 10px 10px 20px #0000006b;
}

.dropdown-menu a {
  padding: 6px 8px 6px 16px !important;
  text-decoration: none !important;
  font-size: 15px !important;
  font-weight: 400 !important;
  line-height: 30px !important;
  list-style: none !important;
  display: block !important;
  color: #000 !important;
}

.dropdown-menu a:hover {
  color: #fff !important;
  background-color: #f76900 !important;
}

@media screen and (max-height: 450px) {
  .dropdown-menu {
    margin-top: 75px !important;
    top: 0px !important;
    margin-left: -170px !important;
  }

  .dropdown-menu a {
    font-size: 15px !important;
  }
}

.menu_item_active {
  background-color: #f76900 !important;
  color: #fff !important;
}

.menu_item_active a:hover {
  color: #fff !important;
}

.other-block {
  padding-top: 85px;
}

@media (max-width: 1200px) {
  .dropdown-menu {
    margin-top: 123px !important;
  }

  .other-block {
    padding-top: 80px;
  }
}

@media (max-width: 992px) {
  .dropdown-menu {
    margin-top: 55px !important;
  }

  .other-block {
    padding-top: 55px;
  }
}

.li-drp-btn {
  border: 1px solid #001740;
  border-radius: 50%;
  height: 30px;
  width: 30px;
  background-color: #001740 !important;
  color: #fff;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.li-drp-btn i {
  font-size: 16px;
}

.li-drp-btn:hover {
  color: #fff !important;
  background-color: #f76900 !important;
  border: 1px solid #f76900 !important;
}

.li-drp-btn-logout {
  color: #fff;
}

.over-view-drop .mat-expansion-panel-body {
  padding: 0;
}

.over-view-drop .mat-expansion-indicator {
  position: absolute;
  right: 10px;
  color: #000;
}

.over-view-drop .mat-expansion-indicator:after {
  color: rgba(0, 0, 0, 1);
}

.dialogClass-c .mat-dialog-container {
  padding: 0;
}

.dialogClass-c .mat-form-field {
  font-family: "Poppins", sans-serif;
  width: calc(100% - 15px);
}

.dialogClass-c .mat-datepicker-toggle {
  color: #f76900;
}

.bg-t-btn {
  background: transparent;
  border: none;
  font-size: 16px;
  text-transform: uppercase;
  font-weight: 600;
  letter-spacing: 1px;
  cursor: pointer;
  color: #f76900 !important;
  -webkit-transition: all 0.5s ease-out !important;
  transition: all 0.5s ease-out !important;
}

.bg-t-btn:hover {
  color: #000e54;
  -webkit-transition: all 0.5s ease-out !important;
  transition: all 0.5s ease-out !important;
}

.outline-btn {
  border: 1px solid #000;
  padding: 15px 30px;
  border-radius: 5px;
  color: #000;
  text-decoration: none;
  font-weight: 600;
  background: #fff;
  cursor: pointer;
}

.back-btn {
  display: table;
  padding: 10px;
  color: #f76900 !important;
  text-decoration: none;
  font-weight: 600;
  cursor: pointer;
}

.back-btn span {
  font-size: 16px;
}

.back-btn i {
  font-size: 14px;
}

.custum-btn {
  border: 1px solid #f76900;
  background: #f76900;
  padding: 15px 30px;
  border-radius: 15px;
  color: #fff !important;
  font-size: 15px;
  text-transform: uppercase;
  letter-spacing: 1px;
  text-decoration: none;
  font-weight: 600 !important;
  -webkit-transition: all 0.5s ease-out;
  transition: all 0.5s ease-out;
  cursor: pointer;
}

.custum-btn:hover {
  border-color: #fff;
  background: #ff7c52;
  -webkit-transition: all 0.5s ease-out;
  transition: all 0.5s ease-out;
}

.outline-orng-btn {
  /* background: #fff; */
  background: #f76900;
  /* color: #F76900 !important; */
}

.outline-blk-btn {
  border: 2px solid #000;
  background: #fff;
  color: #000 !important;
}

.outline-blk-btn:hover {
  background: #f76900;
  border-color: #fff;
  color: #fff !important;
}

.red-btn {
  border-color: #f0000f !important;
  background: #fff !important;
  color: #f0000f !important;
}

.red-btn:hover {
  background: #ed4752 !important;
  color: #fff !important;
}

.outline-orng-btn:hover {
  color: #fff !important;
}

.blu-btn {
  border-color: #000e54;
  background: #000e54;
}

.blu-btn:hover {
  background: #1d37b5;
  border-color: #0723ab;
}

.boking_btn:hover {
  background: #40a667 !important;
}

.btn-close {
  border: none;
  background: transparent;
  font-size: 14px;
  color: #000;
}
.btn-close i {
  color: #000;
}

.bookinng-sec-c {
  border: 1px solid #dbdbdb;
}

.bookinng-sec-c .custum-btn {
  width: 100%;
  text-align: center;
  margin-top: 10px;
  display: table;
}

.btn-grp-c {
  margin-top: 25px;
}

.save-btn-custom {
  background: #f4f4f4;
  border-radius: 100%;
  font-size: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.booking-call {
  color: #000 !important;
  font-size: 16px;
  cursor: pointer;
  font-weight: 600;
}

.hour-rate {
  display: flex;
  align-items: center;
  flex-direction: column;
  border-bottom: 1px solid #dbdbdb;
}

.hour-rate h2 {
  margin: 0;
  font-weight: 700;
  display: flex;
  align-items: center;
}

.hour-rate h2 img {
  width: 16px;
  height: 20px;
  object-fit: contain;
  margin-right: 3px;
}

.hour-rate h2 span {
  font-size: 16px;
}

.dialogClass-l {
  width: 600px;
}

.dialogClass-l mat-dialog-container {
  padding: 0;
}

.dialogClass-l mat-dialog-container form {
  width: 100%;
}

.dialogClass-l mat-error {
  font-size: 14px;
  position: relative;
  z-index: 0;
  top: -20px;
  font-weight: 500;
}

.dialogClass-l mat-form-field {
  width: 100%;
}

.dialogClass-l .otp-from-c mat-error {
  top: 10px;
}

.otp-inputs input:focus-visible {
  outline: -webkit-focus-ring-color auto 0px !important;
}

.plan-list .custum-btn {
  display: table;
  font-size: 14px;
  margin-top: 10px;
  padding: 5px 15px;
}

.shortlist-share-container {
  top: 85px !important;
}

.logo-top {
  width: 130px;
  height: auto;
  object-fit: contain;
}

.dialogClass-c {
  max-width: 1100px !important;
}

.nsm7Bb-HzV7m-LgbsSe.Bz112c-LgbsSe.pSzOP-SxQuSe {
  width: auto !important;
  height: auto !important;
  border: none !important;
}

.nsm7Bb-HzV7m-LgbsSe.Bz112c-LgbsSe.pSzOP-SxQuSe {
  border: none !important;
}

button:focus {
  outline: 0px dotted !important;
  outline: 0px auto -webkit-focus-ring-color !important;
}

:focus-visible {
  outline: -webkit-focus-ring-color auto 0px !important;
}

.co-vs-mod .mat-form-field-appearance-legacy .mat-form-field-suffix .mat-datepicker-toggle-default-icon {
  width: 20px !important;
  color: #f76900 !important;
}

.booking-list-filter .mat-form-field-appearance-fill .mat-form-field-flex {
  background: #fff !important;
}

.booking-list-filter .mat-form-field-appearance-fill .mat-form-field-flex svg {
  color: #f76900;
  width: 20px;
}

.booking-list-filter .mat-form-field-underline {
  display: none;
}

.booking-list-filter .mat-form-field-wrapper {
  padding-bottom: 0;
}

.booking-list-filter .mat-form-field-appearance-fill .mat-form-field-flex {
  padding: 0.75em 0.75em 0.75em 1.5em;
  align-items: center;
}

.single-listing-title h1 {
  font-size: 30px;
}

.booking-all-list-single-img-tag {
  display: table;
  background: #f76900;
  position: absolute;
  left: 0;
  top: 15px;
  padding: 5px 10px;
}

.booking-all-list-single-img-tag:before {
  display: block;
  position: absolute;
  top: 0;
  right: -10px;
  width: 10px;
  height: 0px;
  content: "";
  border-top: 15px solid #f76900;
  border-left: 0px solid transparent;
  border-right: 10px solid transparent;
}

.booking-all-list-single-img-tag:after {
  display: block;
  position: absolute;
  bottom: 0;
  right: -10px;
  width: 10px;
  height: 0px;
  content: "";
  border-bottom: 15px solid #f76900;
  border-left: 0px solid transparent;
  border-right: 10px solid transparent;
}

.booking-all-list-single-img-tag p {
  font-size: 14px;
  margin: 0;
  color: #fff;
  line-height: 1;
  font-weight: 500;
}

.booking-list {
  background: #f9f9f9;
  padding-bottom: 50px;
}

.booking-list-hrader-filters {
  align-items: center;
  padding: 50px 0 30px;
}

.booking-list-hrader-filters h2 {
  margin: 0;
  font-weight: 600;
}

.booking-list-tabs {
  padding: 0px 29px;
  background: #fff;
  border-radius: 15px;
  display: flex;
  align-items: center;
}

.booking-list-tabs-single {
  font-size: 14px;
  font-weight: 500;
  padding: 15px 20px;
  display: table;
  color: #a0a0a0;
  cursor: pointer;
}

.booking-list-tabs-single.active-tabs {
  color: #000;
  border-bottom: 2px solid #f76900;
}

.booking-list-filter {
  display: flex;
  align-items: center;
}

.booking-list-filter-single {
  padding: 18px 20px;
  border-color: #ffffff;
  margin-left: 15px;
  border-radius: 5px;
}

.booking-all-list-single {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 10px;
  background: #fff;
  padding: 20px;
  margin-bottom: 20px;
}

.booking-all-list-single-img-cont {
  display: flex;
  align-items: center;
}

.booking-all-list-single-img img {
  width: 200px;
  height: 150px;
  object-fit: cover;
  border-radius: 10px;
}

.booking-all-list-single-img {
  position: relative;
}

.booking-list-single-info {
  display: flex;
  align-items: center;
}

.booking-list-single-info-single {
  display: flex;
  align-items: center;
}

.booking-all-list-single-cont {
  margin-left: 15px;
}

h4 {
  margin: 0;
}

.booking-all-list-single-cont h4 {
  font-weight: 600;
  text-decoration: underline;
  margin-bottom: 5px;
}

.booking-all-list-single-cont p {
  display: table;
  color: #000;
  font-weight: 500;
  line-height: 1;
  font-size: 16px;
}

.booking-all-list-single-cont-location {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.booking-all-list-single-cont-location img {
  width: 15px;
  height: 15px;
  object-fit: contain;
  margin-right: 5px;
}

/* .booking-list-single-about-info .booking-list-single-info-single i{
  color: #f76900;
  font-size: 16px;
  margin-right: 5px;
} */

.booking-list-single-info-single img {
  width: 15px;
  height: 15px;
  object-fit: contain;
  margin-right: 5px;
}

.booking-list-single-about-info .booking-list-single-info-single {
  margin-right: 30px;
  position: relative;
}

.booking-all-list-single-cont-od-heading {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.booking-all-list-single-cont-od {
  margin-bottom: 30px;
}

.booking-all-list-single-cont-od .booking-list-single-info-single:after {
  content: "";
  background: #ddd;
  border-radius: 100%;
  width: 10px;
  height: 10px;
  position: absolute;
  right: -20px;
  top: 4px;
}

.booking-list-single-info-single:last-child:after {
  display: none;
}

.booking-list-single-date-info {
  margin-bottom: 10px;
}

.booking-list-single-date-info .booking-list-single-info-single {
  margin-right: 20px;
  position: relative;
}

.booking-list-single-date-info .booking-list-single-info-single:after {
  content: "";
  background: #f76900;
  width: 2px;
  height: 100%;
  position: absolute;
  right: -12px;
}

.booking-list-single-confirm-info .booking-list-single-info-single:first-child p {
  color: #888888;
  margin-right: 10px;
}

.booking-list-single-confirm-info .booking-list-single-info-single img {
  width: 25px;
  height: 25px;
}

.booking-confirm p {
  font-weight: 700;
  color: #05ac34;
}

.booking-waiting p {
  font-weight: 700;
  color: #0085ff;
}

.booking-cancel p {
  font-weight: 700;
  color: #ff0000;
}

.booking-all-list-single-btn {
  display: flex;
  flex-direction: column;
}

.booking-all-list-single-btn .custum-btn {
  margin-bottom: 10px;
}

.custum-btn:last-child {
  margin-bottom: 0;
}

.read-more-btn {
  font-size: 16px;
  font-weight: 500;
  text-decoration: underline !important;
  display: table;
  cursor: pointer;
  color: #000 !important;
}

.read-more-btn:hover {
  color: #f76900 !important;
}

.profile-management-c {
  background: #fff;
}

.profile-management-c .booking-list-cont {
  width: 50%;
  margin: 0 auto;
}

.basic-info {
  border-bottom: 1px solid #ddd;
  margin-bottom: 25px;
  padding-bottom: 20px;
}

.info-head {
  margin-bottom: 25px;
}

.info-head h4 {
  font-weight: 600 !important;
  color: #000 !important;
}

.pm-single p {
  font-weight: 600 !important;
  color: #000 !important;
}

.btn-grp .custum-btn {
  width: 100%;
}

.profile-pic {
  margin: 50px 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
}

.profile-pic-img {
  display: table;
}

.profile-pic-img img {
  width: 150px;
  height: 150px;
  object-fit: cover;
  border-radius: 100%;
}

.profile-pic-change-icon {
  position: absolute;
  right: 0;
  bottom: 0;
}

.profile-pic-change-icon {
  position: absolute;
  right: 0;
  bottom: 0;
  background: #000;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  cursor: pointer;
  -moz-transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
  -webkit-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}

.profile-pic-change-icon label {
  color: #fff !important;
  font-size: 18px;
  margin: 0px !important;
}

.profile-pic-change-icon:hover {
  box-shadow: 5px 5px 15px rgba(0, 0, 0, 0.5);
  transform: scale(1.2);
  -moz-transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
  -webkit-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}

.f-w-mdl {
  text-align: center;
}

.f-w-mdl h4 {
  margin: 0;
}

.f-w-mdl img {
  width: 50px;
  height: 80px;
  object-fit: contain;
  margin: 30px auto 20px;
  display: table;
}

.inquiry_form .mat-form-field-infix {
  border-top: 0.4em solid transparent;
}

.profile-management-c .mat-form-field-infix {
  border-top: 0.4em solid transparent;
}

.info-sub-head {
  background: #f4f4f4;
  padding: 10px 20px;
  margin-bottom: 25px;
}

.inquiry_form .mat-form-field-wrapper {
  padding-bottom: 15px !important;
}

.enq-mod-c {
  width: 600px;
  overflow: auto;
}

.visit-scdule-mod {
  width: 65%;
  max-width: 1100px;
}

.step-term-condition a {
  color: #f76900;
}

.modal-custom-header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 20px;
  border-bottom: 1px solid #e9ecef;
}

.modal-custom-header h4 {
  margin: 0;
}

.multi-step-header {
  padding: 10px 20px;
  text-align: center;
  background: #f3f3f3;
}

.multi-step-header p {
  margin: 0;
}

.visit-scdule-mod mat-dialog-container {
  padding: 0 !important;
}

.visit-scdule-mod .header-div {
  padding: 20px;
}

.sdle-vist-bdy {
  padding: 20px;
}

.sdle-vist-bdy h2 {
  font-weight: 100;
  text-align: center;
}

.sdle-vist-bdy .step-term-condition {
  margin-top: 20px;
  text-align: center;
}

.sdle-vist-all {
  max-height: 85vh;
  overflow: auto;
}

/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #c5c4c4;
}

.vst-sdle-cont {
  width: 50%;
  margin: 0 auto;
}

@media only screen and (max-width: 1500px) {
  .booking-all-list-single-cont p {
    font-size: 14px;
  }
  .booking-text {
    font-size: 11px !important;
  }

  .booking-all-list-single-cont-od {
    margin-bottom: 25px;
  }

  /* .booking-all-list-single-img img {
    width: 190px;
    height: 145px;
  } */

  .booking-list-single-confirm-info .booking-list-single-info-single img {
    width: 20px;
    height: 20px;
  }

  .booking-list-tabs-single {
    font-size: 14px;
  }

  .booking-list {
    padding-bottom: 40px;
  }
}

@media only screen and (max-width: 1200px) {
  .booking-list-hrader-filters {
    padding: 30px 0 25px;
  }

  .booking-list-tabs {
    padding: 0px 15px;
    width: 100%;
    justify-content: center;
    margin-bottom: 20px;
  }

  .visit-scdule-mod {
    width: 70%;
  }
}

@media only screen and (max-width: 991px) {
  .booking-list-hrader-filters {
    padding: 30px 0 20px;
  }

  .booking-list-tabs {
    padding: 0px 20px;
    border-radius: 10px;
    width: 100%;
    justify-content: center;
    margin-bottom: 15px;
  }

  .booking-list-filter {
    width: 100%;
  }

  .booking-list-filter-single {
    /* padding: 12px 15px;    */
    margin-left: 10px;
    width: calc(50% - 5px);
  }

  .booking-list-filter-single:first-child {
    margin-left: 0;
  }

  .booking-all-list-single {
    flex-direction: column;
  }

  .booking-all-list-single-btn {
    margin-top: 20px;
    width: 100%;
  }

  .booking-all-list-single-img-cont {
    width: 100%;
  }
}

@media only screen and (max-width: 767px) {
  .booking-all-list-single-img-cont {
    flex-direction: column;
    align-items: flex-start;
  }

  .booking-all-list-single-cont {
    margin-left: 0;
    margin-top: 10px;
  }

  .booking-list-filter {
    flex-direction: column;
  }

  .booking-list-filter mat-form-field {
    width: 100%;
    margin-bottom: 15px;
  }

  .booking-list-filter-single {
    margin-left: 0;
    width: 100%;
  }

  .favourite-workspace-c .booking-all-list-single-cont {
    width: 100% !important;
  }

  .enq-mod-c {
    width: 100%;
    max-width: 100% !important;
  }

  .enq-mod-c .mat-dialog-container {
    padding: 10px !important;
  }

  .enq-mod-c .info-head,
  .enq-mod-c .info-sub-head {
    margin-bottom: 15px;
  }

  .visit-scdule-mod {
    width: 90%;
    max-width: 100% !important;
  }

  .sdle-vist-bdy {
    padding: 15px;
  }

  .sdle-vist-all {
    height: 90vh;
  }
  .booking-popup .booking-popup-only-input {
    height: 100% !important;
  }
  #basic-addon3 {
    width: 36% !important;
  }
}

@media only screen and (max-width: 567px) {
  /* .amountClass{
    width: 100%;
  } */
  .booking-list-tabs {
    padding: 0px;
  }

  .booking-list-tabs {
    flex-direction: column;
  }

  .booking-list-tabs-single {
    width: 100%;
    text-align: center;
  }

  .booking-all-list-single-img {
    width: 100%;
  }

  .booking-all-list-single-img img {
    width: 100%;
  }

  .booking-all-list-single-cont-od {
    margin-bottom: 15px;
  }

  .booking-all-list-single {
    padding: 10px;
    margin-bottom: 15px;
  }

  .booking-list-single-about-info .booking-list-single-info-single {
    margin-right: 15px;
  }

  .booking-all-list-single-cont-od .booking-list-single-info-single:after {
    display: none;
  }

  .booking-list {
    padding-bottom: 25px;
  }

  .login-register .login-register-input .login-register-inutp-other .login-register-input-other-grp .outline-btn {
    margin-left: 10px;
  }
}

@media only screen and (max-width: 1800px) {
  .login-register .login-register-input .login-register-inutp-other .login-register-input-other-grp {
    margin-top: 10px !important;
  }

  .custom-btn-grp .outline-btn {
    font-size: 16px !important;
  }

  .multi-step-header {
    padding: 5px 20px !important;
  }

  .multi-step-header p {
    font-size: 14px;
  }

  /* .stp-2-cont {
      padding: 10px 20px !important;     
    } */

  .step-1 h2 {
    margin: 0 !important;
  }

  .stp-2-cont .multi-step-inputs {
    width: 65%;
  }

  .multi-step-inputs {
    margin: 0 auto 20px !important;
  }

  .visit-scdule-mod .header-div {
    padding: 10px 20px;
  }
}

@media only screen and (max-width: 1500px) {
  .booking-popup .booking-popup-right .booking-popup-plot .booking-popup-plot-img img {
    width: 180px !important;
    height: 105px !important;
  }

  .booking-popup .booking-popup-right .booking-popup-payment-info .booking-popup-payment-info-details {
    min-height: 55px !important;
  }

  .booking-popup .booking-popup-half .booking-popup-heading {
    padding-bottom: 10px !important;
    margin-bottom: 15px !important;
  }

  .booking-popup .booking-popup-right .booking-popup-plot {
    margin-bottom: 10px !important;
  }

  .custom-btn-grp .outline-btn {
    margin-right: 15px;
  }

  .custum-btn {
    font-size: 16px;
  }
}

@media only screen and (max-width: 1400px) {
  p,
  a {
    font-size: 14px;
  }

  .single-listing-title h1 {
    font-size: 18px;
  }

  h5 {
    font-size: 16px;
  }

  h4 {
    font-size: 18px;
  }

  .booking-popup .booking-popup-half .booking-popup-all-input {
    margin-bottom: 10px !important;
  }

  .booking-popup .booking-popup-right .booking-popup-payment-info .booking-popup-payment-info-date {
    margin-bottom: 5px !important;
  }

  .booking-popup-only-input {
    margin-bottom: 0 !important;
  }

  .dialogClass-l {
    width: 700px;
  }

  .dialogClass-c {
    max-width: 1000px !important;
  }

  .list-details-tab .nav-item {
    padding: 20px 0;
  }

  .list-details-section {
    padding-top: 40px;
  }

  .rating-info .product-rating-c li i {
    font-size: 18px;
  }

  .rating-info .rating-info-single {
    margin-left: 25px;
  }

  .rating-info .rating-info-single:after {
    background: #f76900;
    width: 5px;
    height: 5px;
    left: -14px;
  }

  .list-details-tab .nav-item {
    padding: 10px 0;
  }

  .list-details-tab .nav-item a {
    font-size: 14px;
  }

  .profile-management-c .booking-list-cont {
    width: 55%;
  }

  .profile-pic-img img {
    width: 125px;
    height: 125px;
  }

  .custum-btn {
    font-size: 14px;
  }

  .stp-2-cont .multi-step-inputs {
    width: 80%;
  }

  .custom-btn-grp .outline-btn {
    font-size: 14px !important;
  }

  .stp-2-cont h6,
  .stp-2-cont h5 {
    font-size: 14px;
  }

  .multi-step-inputs-looking {
    margin-bottom: 10px !important;
  }

  .radio-buttons.radio-buttons-big .people-qty-content {
    width: 80px !important;
    height: 80px !important;
  }

  .vst-sdle-cont {
    width: 70%;
  }
}

@media only screen and (max-width: 1199px) {
  .has-no-children a {
    font-size: 14px;
  }

  .site-navbar-wrap.v2 .custum-btn {
    padding: 10px 15px;
  }

  .rating-like {
    padding: 15px 0 !important;
    align-items: flex-start !important;
  }

  .rating-info {
    flex-wrap: wrap;
  }

  .rating-info {
    width: 75%;
  }

  .rating-info .rateing-c {
    width: 100%;
    margin-bottom: 10px;
  }

  .like-dislike-c {
    width: 25%;
  }

  .booking-popup .booking-popup-right .booking-popup-plot .booking-popup-plot-img img {
    width: 120px !important;
    height: 80px !important;
  }

  .booking-popup .booking-popup-right .booking-popup-payment-info {
    margin-bottom: 15px !important;
  }

  .dialogClass-c {
    max-width: 900px !important;
  }

  .co-work-vs-s1 {
    width: 100% !important;
  }

  .profile-management-c .custum-btn {
    margin-top: 20px;
  }

  .stp-2-cont .multi-step-inputs {
    width: 100%;
  }
}

@media only screen and (max-width: 991px) {
  .booking-popup .booking-popup-half .booking-popup-all-input .booking-popup-all-input-group {
    flex-direction: column;
  }

  .booking-popup
    .booking-popup-half
    .booking-popup-all-input
    .booking-popup-all-input-group
    .booking-popup-input-single {
    width: 100% !important;
    margin-right: 0 !important;
  }

  .booking-popup .booking-popup-half {
    padding: 15px !important;
  }

  /* .dialogClass-c .mat-form-field {
    width: 100%;
  } */

  .booking-popup-only-input {
    height: 60vh;
  }

  .booking-popup .booking-popup-right .booking-popup-plot,
  .booking-popup .booking-popup-right .booking-popup-payment-info {
    padding: 10px !important;
    border-radius: 5px !important;
  }

  .booking-popup .booking-popup-right .booking-popup-payment-info {
    margin-bottom: 10px !important;
  }

  .booking-popup .booking-popup-right .booking-popup-plot {
    flex-direction: column;
  }

  .booking-popup-plot-img {
    width: 100%;
  }

  .booking-popup .booking-popup-right .booking-popup-plot .booking-popup-plot-img img {
    width: 100% !important;
    margin-right: 0 !important;
    margin-bottom: 10px;
  }

  .dialogClass-c {
    max-width: 80% !important;
  }

  .footer-wrapper .footer-content.nav {
    display: inline-block;
    width: 100%;
  }

  .footer-wrapper .mat-form-field {
    width: 100% !important;
  }

  .footer-wrapper .large-btn {
    width: 100% !important;
    min-width: auto !important;
  }

  .multi-step-inputs {
    margin: 0 auto 20px !important;
  }

  .vs-modal .modal-content {
    width: 70%;
  }

  /* .custum-btn {     
    font-size: 16px;    
  } */

  .vs-modal .custum-btn {
    width: 100%;
  }

  .booking-list-filter mat-form-field {
    width: 50%;
  }

  .profile-management-c .booking-list-cont {
    width: 100%;
  }
}

.iti__country-list {
  height: 400px;
  overflow: scroll;
}

.iti__selected-flag {
  position: absolute;
  display: flex;
  align-items: center;
  padding: 0.375rem 0.75rem;
  font-size: 0.875rem;
  line-height: 1.6;
}
.booking-icon {
  padding-right: 3px;
}

@media only screen and (min-width: 320px) and (max-width: 768px) {
  h3 {
    font-size: 20px;
  }
  .mobile_btm_bar {
    display: block !important;
  }
  .call_icon {
    display: none;
  }
  .whatsapp_icons {
    display: none;
  }
  .zsiq_floatmain {
    display: none !important;
  }
  .footer-wrapper {
    padding-bottom: 80px !important;
  }
  .hero_sec .hero-title {
    font-size: 37px;
    justify-content: center;
    width: 100%;
    align-items: center;
    text-align: center;
  }
  .hero_sec .animateIMG.text {
    font-size: 33px;
  }
  .hero_sec {
    height: calc(100dvh - 50px) !important;
  }
  .animateIMG.text {
    display: flex !important;
    justify-content: center !important;
    align-items: baseline !important;
  }

  .mb_input_box {
    flex: 0 0 auto;
  }

  .border-l-r {
    border-right: none !important;
  }

  .hero_sec .hero_input_box {
    border-radius: 0;
  }

  .mb_hero {
    height: 100%;
  }
  .mb_row {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    height: 100%;
    padding-block: 16px;
  }
  .mb_text_content {
    flex: 1 1 auto;
    display: flex;
  }

  .hero__form-input {
    margin-bottom: 5px;
  }
  .cities_sec .city-img-box img {
    height: 100% !important;
  }
  .Spaces_box .space_img_box .space_txt {
    width: auto !important;
  }
  .Spaces_box .space_img_box .space_txt h5 {
    font-size: 13px;
  }
  .Spaces_box .space_img_box img {
    height: 150px !important;
  }
  .city_carousel .slick-next {
    right: 8px !important;
  }
  .city_carousel .slick-prev {
    left: -13px !important;
  }
  .work-space-option .slick-next {
    right: 8px !important;
  }
  .work-space-option .slick-prev {
    left: -13px !important;
  }
  .cowrking_brands .slick-next {
    right: 8px !important;
  }
  .cowrking_brands .slick-prev {
    left: -13px !important;
  }
  .work-process .slick-next {
    right: 29px !important;
  }
  .work-process .slick-prev {
    left: 3px !important;
  }
  .workspace_steps .space_list .list_items .space_count {
    position: static !important;
    margin-bottom: 10px;
  }
  .choose_share .share_content {
    margin-left: 0 !important;
  }
  .workspace_steps .workspace_img img {
    height: 100% !important;
  }
  .count_sec .count_box h2 {
    font-size: 23px !important;
  }
  .flex_btn .btn {
    font-size: 14px !important;
  }
  .our_team .team_box .team_icon {
    position: static !important;
    transform: none !important;
    margin-bottom: 6px;
  }
  .inventory-label p {
    font-size: 13px;
  }
  .price-value {
    font-size: 14px !important;
  }
  .rs_icon {
    height: 11px !important;
    width: 12px;
  }
  /* .other-block{
    padding-top: 0;
  } */
  .hero__form.v2 .hero__form-input {
    width: 100% !important;
  }
  .col_count {
    column-count: 2 !important;
  }
  .cdk-overlay-pane {
    max-width: 100% !important;
  }
  .list-details-tab-item {
    justify-content: flex-start !important;
  }
  .product-rating li i {
    font-size: 15px;
  }
  .rate_txt {
    font-size: 12px;
  }
  .resource-types-container {
    height: 100% !important;
  }
  .image-container {
    width: 100% !important;
  }
  .earn_sec {
    background-size: cover;
    aspect-ratio: 0;
  }
  .become_host {
    background-size: cover !important;
    aspect-ratio: 0 !important;
  }
  .succes_story_sec .success_box .success_content .success_txt {
    font-size: 15px !important;
  }
  .contact_txt {
    font-size: 22px !important;
  }

  .bg_face {
    background: #fff;
    padding: 15px;
    border-radius: 15px;
  }

  .bg_face .ng-select .ng-select-container {
    border-radius: 15px;
    border: 1px solid #000 !important;
    min-height: 52px;
    height: 52px;
  }
  .bg_face .hero__form-input {
    border: 1px solid #000 !important;
    border-radius: 15px;
    height: 52px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1200px) {
  .fixed-header.list_menu {
    padding: 0 13px;
  }
  .image-container {
    width: 100% !important;
  }
  .desktop-button-fixed-container {
    width: 100% !important;
  }
}

@media only screen and (max-width: 767px) {
  .dialogClass-l {
    width: 90% !important;
    max-width: 90% !important;
  }

  .dialogClass-l .mat-form-field-appearance-outline .mat-form-field-wrapper {
    margin: 0 !important;
    padding: 0 !important;
  }

  .logo-top {
    width: 100px;
  }

  .shortlist-share-container {
    top: 60px !important;
    padding: 15px !important;
  }

  .rating-like {
    flex-wrap: wrap;
  }

  .rating-like {
    flex-direction: column;
  }

  .rating-info {
    width: 100%;
    margin-bottom: 15px;
  }

  .like-dislike-c {
    width: auto;
    margin-left: 0 !important;
  }

  .over-view-drop {
    padding: 20px 0 !important;
  }

  .list-details-tab-item {
    flex-wrap: wrap;
  }

  .list-details-section {
    padding-bottom: 30px !important;
  }

  .list-details-section {
    padding-top: 30px !important;
  }

  .add-ons .add-ons-all .add-ons-single {
    width: 100% !important;
    margin-right: 0 !important;
    margin-bottom: 20px !important;
  }

  .booking-popup {
    flex-direction: column;
    position: relative;
  }

  .booking-popup .booking-popup-half {
    width: 100% !important;
  }

  .dialogClass-c {
    width: 100% !important;
    max-width: 90% !important;
    height: calc(100vh - 115px);
  }

  .booking-popup-only-input {
    height: auto;
  }

  .booking-popup .booking-popup-half .booking-popup-heading h4 {
    margin: 0;
    font-size: 16px;
    padding-right: 40px;
  }

  .booking-popup .booking-popup-half .booking-popup-heading {
    margin-bottom: 10px !important;
  }

  .dialogClass-c .booking-popup-all-input .custum-btn {
    width: 100%;
  }

  .booking-popup .booking-popup-right .booking-popup-heading .btn-close {
    position: absolute;
    right: 10px;
    top: 15px;
  }

  .review-section .review-section-single {
    flex-direction: column;
  }

  .review-section .review-section-single .review-section-single-cont {
    width: 100%;
    margin-top: 10px;
  }

  h2 {
    font-size: 22px;
  }

  .review-section-heading {
    flex-direction: column;
    align-items: flex-start !important;
  }

  .review-section-heading .outline-orng-btn {
    margin-left: 0 !important;
    margin-top: 15px;
  }

  .book-again-star {
    align-items: self-start !important;
    flex-direction: column;
  }

  .book-again {
    margin-left: 0 !important;
  }

  .book-again li {
    font-size: 14px !important;
  }

  .book-again li i {
    font-size: 16px !important;
  }

  .review-section .review-section-single .review-section-single-cont {
    width: 100% !important;
  }

  .review-section .review-section-single .review-section-single-img {
    margin-right: 0 I !important;
    width: 100%;
  }

  .work-space-review .review-section .review-section-single .review-section-single-img img {
    width: 100% !important;
    height: 145px !important;
  }

  .vs-modal .modal-custom-header {
    padding: 10px 15px;
  }

  h4 {
    font-size: 16px;
  }

  .custum-btn {
    font-size: 14px;
    padding: 10px 20px;
  }

  .co-vs-mod .mat-form-field-appearance-legacy .mat-form-field-suffix .mat-datepicker-toggle-default-icon {
    width: 16px !important;
  }

  .vs-modal .modal-content {
    width: 90%;
  }

  .step-1 h2 {
    font-size: 20px;
  }

  .step.step-1 {
    padding: 10px !important;
  }

  .multi-step-header {
    padding: 10px !important;
  }

  .date-picker-input {
    margin-bottom: 10px !important;
  }

  .time-picker-input input {
    padding: 10px !important;
  }

  .vs-modal .custom-btn-grp .outline-btn {
    padding: 10px 15px !important;
    font-size: 14px !important;
    width: 50%;
  }

  .vs-modal .custom-btn-grp .custum-btn {
    width: 50%;
  }

  .list-details-tab-item {
    justify-content: flex-start;
  }

  .list-details-tab .nav-item {
    padding: 10px 15px;
  }

  .profile-pic {
    margin: 10px auto 30px;
  }

  .profile-pic-img img {
    width: 100px;
    height: 100px;
  }

  .basic-info {
    margin-bottom: 20px;
    padding-bottom: 5px;
  }

  /* button {    
    width: 100%;
    margin: 5px 0 !important;
  } */

  .f-w-mdl img {
    width: 75px;
    height: 75px;
    margin: 10px auto 15px;
  }

  .f-w-mdl .btn-grp-c {
    margin-top: 15px;
  }

  .gstbtn-grp-c {
    flex-direction: column !important;
  }

  .gstbtn-grp-c button {
    width: 100% !important;
    margin: 5px 0 !important;
  }

  .multi-step-inputs-looking .people-qty {
    width: calc(50% - 10px);
    margin: 5px !important;
  }

  .looking-cont {
    padding: 10px !important;
  }

  .vst-sdle-cont {
    width: 100%;
  }
}

.iti__country-list {
  height: 250px;
  overflow: scroll;
}

.iti__selected-flag {
  position: absolute;
  display: flex;
  align-items: center;
  padding: 0.375rem 0.75rem;
  font-size: 0.875rem;
  line-height: 1.6;
  height: 100%;
}

.separate-dial-code {
  & .show {
    & .country-dropdown {
      display: inline-table;
      width: 100% !important;
      height: 250px;
      top: -6px !important;
      /* Uncomment these lines if needed */
      /* overflow-y: hidden; */
      /* overflow-x: hidden; */
    }
  }
}

.custom {
  width: 100% !important;
  height: 45px;
  margin-top: 4px;
  border: 1px solid #e0e0e0;
  border-radius: 6px;
}

.custom {
  width: 100% !important;
  height: 45px;
  margin-top: 4px;
  border: 1px solid #e0e0e0;
  border-radius: 6px;
}

.mat-chip-list-wrapper {
  /* height: 50px; */
  overflow-y: auto;
}

.mat-chip {
  font-size: 12px !important;
}

.mat-standard-chip .mat-chip-remove.mat-icon,
.mat-standard-chip .mat-chip-remove .mat-icon {
  height: 16px !important;
  width: 16px !important;
}

.mat-standard-chip.mat-chip-with-trailing-icon {
  padding-top: 5px !important;
  padding-bottom: 5px !important;
  padding-right: 7px !important;
  padding-left: 8px !important;
}

.ribbon-label {
  position: absolute;
  padding: 8px 10px;
  font-weight: 500;
  color: white;
  border-radius: 4px;
  top: 0;
  right: 0;
  z-index: 900;
  translate: 5% -40%;
}

.ribbon-label-details {
  /* position: absolute; */
  padding: 8px 10px;
  font-weight: 500;
  color: white;
  border-radius: 4px;
  top: 25px;
  left: 25px;
  z-index: 1000;
  /* translate: 0% -40%; */
  margin-right: 10px;
}

.cdk-global-scrollblock {
  top: auto !important;
}

.fs-20 {
  font-size: 20px;
}

.fs-16 {
  font-size: 16px;
}

.fs-17 {
  font-size: 17px;
}
.fs-18 {
  font-size: 18px;
}

.cursor-pointer {
  cursor: pointer;
}

/* .gmnoprint {
  top: 150px !important;
} */
.city_carousel .slick-prev:before {
  content: "\f053";
  font-family: "FONTAWESOME";
}
.city_carousel .slick-next:before {
  content: "\f054";
  font-family: "FONTAWESOME";
}
.work-process .slick-prev:before {
  content: "\f053";
  font-family: "FONTAWESOME";
}
.work-process .slick-next:before {
  content: "\f054";
  font-family: "FONTAWESOME";
}
.space_list_sec .slick-prev:before {
  content: "\f053";
  font-family: "FONTAWESOME";
}

.space_list_sec .slick-next:before {
  content: "\f054";
  font-family: "FONTAWESOME";
}
.space_list_sec .slick-next {
  right: 0;
}
.work-process .slick-next {
  right: 0;
}
.work-space-option .slick-prev:before {
  content: "\f053";
  font-family: "FONTAWESOME";
}
.work-space-option .slick-next:before {
  content: "\f054";
  font-family: "FONTAWESOME";
}
.work-space-option .slick-next {
  right: 0;
}
.work-space-option .slick-prev {
  left: -22px;
}
.cowrking_brands .slick-prev:before {
  content: "\f053";
  font-family: "FONTAWESOME";
}
.cowrking_brands .slick-next:before {
  content: "\f054";
  font-family: "FONTAWESOME";
}
.cowrking_brands .slick-next {
  right: 0;
}

.slick-prev::before,
.slick-next::before {
  font-size: 20px !important;
  line-height: 1;
  color: #d0c2c2 !important;
  opacity: 1 !important;
  border: 2px solid #d0c2c2;
  border-radius: 50%;
  padding: 8px;
  background: #fff;
  transition: all 500ms;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.slick-prev:hover:before,
.slick-next:hover:before {
  border: 2px solid #f76900;
  color: #f76900 !important;
}

.city_carousel .slick-prev {
  left: -22px;
}
.city_carousel .slick-next {
  right: 0px;
}

.slick-prev,
.slick-next {
  transform: translate(0, -112%) !important;
  z-index: 99;
}

@media (min-width: 1400px) {
  .container {
    max-width: 1280px !important;
  }
}
@media (min-width: 1200px) {
  .container {
    max-width: 1140px;
  }
}
